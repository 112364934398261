import { lazy, Suspense } from 'react';
import { Outlet, RouteObject } from 'react-router-dom';
import Loader from 'src/components/helpers/loader/loader';
import { UserType } from 'src/providers/auth/types';
import NewOrderMonth from 'src/pages/parentPanel/NewOrder/Month';
import { PersonsProvider } from 'src/providers/children';
import { MenuProvider } from 'src/providers/menu';
import Summary from 'src/pages/parentPanel/NewOrder/Month/Summary';
import ChildrenMenu from 'src/pages/parentPanel/ChildrenMenu';
import NewOrder from 'src/pages/parentPanel/NewOrder';
import BalancePage from 'src/pages/parentPanel/Balance';
import ParentPanelLayout from 'src/layouts/parentPanel';
import { ProtectedRoute } from '../components';
import Chat from '../components/Chat';

export const ParentPanelPage = lazy(() => import('src/pages/parentPanel'));
export const NewOrderPage = lazy(
  () => import('src/pages/parentPanel/NewOrder/Month'),
);
export const OrdersPage = lazy(() => import('src/pages/parentPanel/Orders'));
export const OrderPage = lazy(
  () => import('src/pages/parentPanel/Orders/Order'),
);
export const OrderPayPage = lazy(
  () => import('src/pages/parentPanel/Orders/Order/Pay'),
);
export const ChildrenPage = lazy(
  () => import('src/pages/parentPanel/Children'),
);
export const SettingsPage = lazy(
  () => import('src/pages/parentPanel/Settings'),
);
export const ContactPage = lazy(() => import('src/pages/parentPanel/Contact'));
export const PickupLocation = lazy(
  () => import('src/pages/parentPanel/NewOrder/Month/PickupLocation'),
);

export const customerRoutes: RouteObject[] = [
  {
    path: '/customer',
    element: (
      <ProtectedRoute allowType={UserType.CUSTOMER}>
        <ParentPanelLayout>
          <Suspense fallback={<Loader />}>
            <Outlet />
            <Chat />
          </Suspense>
        </ParentPanelLayout>
      </ProtectedRoute>
    ),
    children: [
      {
        element: <ParentPanelPage />,
        index: true,
      },
      {
        path: 'new-order',
        element: (
          <MenuProvider>
            <PersonsProvider>
              <Outlet />
            </PersonsProvider>
          </MenuProvider>
        ),
        children: [
          { element: <NewOrder />, index: true },
          {
            path: ':month',
            element: <Outlet />,
            children: [
              { index: true, element: <NewOrderMonth /> },
              { path: 'pickup', element: <PickupLocation /> },
              { path: 'summary', element: <Summary /> },
            ],
          },
        ],
      },
      {
        path: 'orders',
        element: <Outlet />,
        children: [
          {
            element: <OrdersPage />,
            index: true,
          },
          {
            path: ':orderId',
            element: <Outlet />,
            children: [
              { element: <OrderPage />, index: true },
              {
                path: 'pay',
                element: <OrderPayPage />,
              },
            ],
          },
        ],
      },
      {
        path: 'children-menu',
        element: <ChildrenMenu />,
      },
      { path: 'balance', element: <BalancePage /> },
      { path: 'children', element: <ChildrenPage /> },
      { path: 'settings', element: <SettingsPage /> },
      { path: 'contact', element: <ContactPage /> },
    ],
  },
];
