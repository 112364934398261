import { format } from 'date-fns';
import { useMemo } from 'react';
import { useAuthContext } from 'src/providers/auth';
import { ChildMenuAvailability } from 'src/providers/children/types';
import {
  ChildMenuAvailabilityWithType,
  CustomerMenuAvailability,
  CustomerMenuAvailabilityWithType,
  PersonMenuAvailability,
} from 'src/providers/menu/types';
import { ApiResponse } from 'src/types/common';
import { endpoints } from 'src/utils/axios';
import { hasInstitutionType } from 'src/utils/user';
import useSWR from 'swr';

type UseMenuAvailabilityResult = {
  data: PersonMenuAvailability[];
  isLoading: boolean;
};

const useMenuAvailability = (date: Date): UseMenuAvailabilityResult => {
  const { user } = useAuthContext();
  const monthFormatted = useMemo(() => {
    return format(date, 'yyyy-MM');
  }, [date]);

  const { data: childData, isLoading: childIsLoading } = useSWR<
    ApiResponse<ChildMenuAvailability[]>
  >(
    user && hasInstitutionType(user, 'school')
      ? endpoints.customer.getChildrenMenuAvailability(monthFormatted)
      : null,
    { revalidateOnMount: true },
  );
  const { data: customerData, isLoading: customerIsLoading } = useSWR<
    ApiResponse<CustomerMenuAvailability>
  >(
    user && hasInstitutionType(user, 'company')
      ? endpoints.customer.getCustomerMenuAvailability(monthFormatted)
      : null,
    { revalidateOnMount: true },
  );

  const childDataFormatted: ChildMenuAvailabilityWithType[] = useMemo(() => {
    return (
      childData?.data?.map((child) => ({
        ...child,
        type: 'child',
      })) || []
    );
  }, [childData]);

  const customerDataFormatted: CustomerMenuAvailabilityWithType[] =
    useMemo(() => {
      return customerData?.data
        ? [{ ...customerData.data, type: 'customer' }]
        : [];
    }, [customerData]);

  const menuAvailability = useMemo(
    () => [...childDataFormatted, ...customerDataFormatted],
    [childDataFormatted, customerDataFormatted],
  );

  return {
    data: menuAvailability,
    isLoading: childIsLoading || customerIsLoading,
  };
};

export default useMenuAvailability;
