import { useCallback } from 'react';
import { useAuthContext } from 'src/providers/auth';
import {
  PermissionsGroups,
  RestaurantPermissionLevels,
  RestaurantPermissions,
} from 'src/types/restaurant/permissions';
import { PERMISSIONS_PER_GROUP } from 'src/utils/permission';

const usePermission = () => {
  const { user } = useAuthContext();

  /**
   * @name hasPermission
   * @description Check if user has permission
   * @param permission RestaurantPermissions
   * @returns boolean
   */
  const hasPermission = useCallback(
    (permission: RestaurantPermissions, level: RestaurantPermissionLevels) => {
      return !!user?.role?.permissions.find(
        (p) => p.permission.name === permission && p.permission.level === level,
      );
    },
    [user?.role?.permissions],
  );

  const hasAnyPermission = useCallback(
    (
      permissions: {
        permission: RestaurantPermissions;
        level: RestaurantPermissionLevels;
      }[],
    ) => {
      return permissions.some((permission) =>
        user?.role?.permissions.find(
          (p) =>
            p.permission.name === permission.permission &&
            p.permission.level === permission.level,
        ),
      );
    },
    [user?.role?.permissions],
  );

  const hasAllPermissions = useCallback(
    (
      permissions: {
        permission: RestaurantPermissions;
        level: RestaurantPermissionLevels;
      }[],
    ) => {
      return permissions.every((permission) =>
        user?.role?.permissions.find(
          (p) =>
            p.permission.name === permission.permission &&
            p.permission.level === permission.level,
        ),
      );
    },
    [user?.role?.permissions],
  );

  const hasAnyFromGroup = useCallback(
    (
      group: PermissionsGroups,
      levelPerPermission: [
        RestaurantPermissions,
        RestaurantPermissionLevels,
      ][] = [],
    ) => {
      return user?.role?.permissions.some((p) => {
        const permissionLevel =
          levelPerPermission.find((lp) => lp[0] === p.permission.name)?.[1] ||
          RestaurantPermissionLevels.READ;
        return (
          PERMISSIONS_PER_GROUP[group].includes(p.permission.name) &&
          p.permission.level === permissionLevel
        );
      });
    },
    [user?.role?.permissions],
  );

  const getInstitutionsFromPermission = useCallback(
    (
      permission: RestaurantPermissions,
      permissionLevel: RestaurantPermissionLevels,
    ): string[] | null => {
      if (!user?.role) return null;
      const permissionDetails = user?.role?.permissions.find(
        (p) =>
          p.permission.name === permission &&
          p.permission.level === permissionLevel,
      );

      return permissionDetails?.institution_uuids || null;
    },
    [user?.role],
  );

  return {
    hasPermission,
    hasAnyPermission,
    hasAllPermissions,
    hasAnyFromGroup,
    getInstitutionsFromPermission,
  };
};

export default usePermission;
