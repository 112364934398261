import { Helmet } from 'react-helmet-async';
import MaintenanceView from 'src/views/Maintenance';

export default function MaintenancePage() {
  return (
    <>
      <Helmet>
        <title>Przerwa techniczna</title>
      </Helmet>

      <MaintenanceView />
    </>
  );
}
