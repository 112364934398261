import { NavListProps } from 'src/components/helpers/nav-section';
import Iconify from 'src/components/helpers/iconify';
import { paths } from 'src/routes/paths';
import usePermission from 'src/hooks/usePermission';
import {
  PermissionsGroups,
  RestaurantPermissionLevels,
  RestaurantPermissions,
} from 'src/types/restaurant/permissions';
import { useAuthContext } from 'src/providers/auth';

export const useRestaurantMenu: () => {
  subheader?: string;
  items: NavListProps[];
}[] = () => {
  const { user } = useAuthContext();
  const { hasPermission, hasAnyPermission, hasAnyFromGroup } = usePermission();

  const menu = [
    {
      items: [
        // {
        //   title: 'Start',
        //   path: paths.restaurantPanel.main,
        //   icon: <Iconify icon="material-symbols:home" />,
        // },
        {
          title: 'Menu',
          path: paths.restaurantPanel.mainMenu,
          icon: <Iconify icon="material-symbols:food-bank" />,
          disabled: !hasAnyFromGroup(PermissionsGroups.MENU),
          children: [
            {
              title: 'Menu miesięczne',
              path: paths.restaurantPanel.menu,
              icon: <Iconify icon="material-symbols:menu-book-outline" />,
              disabled: !hasAnyPermission([
                {
                  permission: RestaurantPermissions.MENU,
                  level: RestaurantPermissionLevels.READ,
                },
                {
                  permission: RestaurantPermissions.DRAFT_MENU,
                  level: RestaurantPermissionLevels.READ,
                },
              ]),
            },
            {
              title: 'Zestawy',
              path: paths.restaurantPanel.mealBundles,
              icon: <Iconify icon="mdi:food" />,
              disabled: !hasPermission(
                RestaurantPermissions.MEAL_BUNDLE,
                RestaurantPermissionLevels.READ,
              ),
            },
            {
              title: 'Posiłki',
              path: paths.restaurantPanel.meals,
              icon: <Iconify icon="mdi:spaghetti" />,
              disabled: !hasPermission(
                RestaurantPermissions.MEAL,
                RestaurantPermissionLevels.READ,
              ),
            },
            {
              title: 'Kategorie posiłków',
              path: paths.restaurantPanel.mealsCategories,
              icon: <Iconify icon="tabler:soup" />,
              disabled: !hasPermission(
                RestaurantPermissions.MEAL_CATEGORY,
                RestaurantPermissionLevels.READ,
              ),
            },
            {
              title: 'Składniki',
              path: paths.restaurantPanel.ingredients,
              icon: <Iconify icon="ph:carrot" />,
              disabled: !hasPermission(
                RestaurantPermissions.INGREDIENT,
                RestaurantPermissionLevels.READ,
              ),
            },
            {
              title: 'Alergeny',
              path: paths.restaurantPanel.allergens,
              icon: <Iconify icon="mdi:wheat" />,
              disabled: !hasPermission(
                RestaurantPermissions.ALLERGEN,
                RestaurantPermissionLevels.READ,
              ),
            },
          ],
        },
        {
          title: 'Raporty',
          path: paths.restaurantPanel.reports.main,
          icon: <Iconify icon="material-symbols:list-alt-outline" />,
          disabled: !hasAnyFromGroup(PermissionsGroups.REPORTS),
        },
        {
          title: 'Instytucje',
          path: paths.restaurantPanel.institutions,
          icon: <Iconify icon="ri:school-line" />,
          disabled: !hasPermission(
            RestaurantPermissions.INSTITUTION,
            RestaurantPermissionLevels.READ,
          ),
        },
        {
          title: 'Klienci',
          path: hasPermission(
            RestaurantPermissions.CUSTOMER,
            RestaurantPermissionLevels.READ,
          )
            ? paths.restaurantPanel.customers
            : '',
          icon: <Iconify icon="ic:baseline-people" />,
          disabled: !hasAnyFromGroup(PermissionsGroups.CUSTOMERS),
          children: [
            {
              title: 'Lista klientów',
              path: paths.restaurantPanel.customers,
              icon: <Iconify icon="ic:baseline-people" />,
              disabled: !hasPermission(
                RestaurantPermissions.CUSTOMER,
                RestaurantPermissionLevels.READ,
              ),
            },
            {
              title: 'Zamówienia',
              path: paths.restaurantPanel.orders,
              icon: <Iconify icon="material-symbols:order-play" />,
              disabled: !hasPermission(
                RestaurantPermissions.ORDER,
                RestaurantPermissionLevels.READ,
              ),
            },
            {
              title: 'Odwołania posiłków',
              path: paths.restaurantPanel.cancellations,
              icon: <Iconify icon="mdi:food-off" />,
              disabled: !hasPermission(
                RestaurantPermissions.CANCELLATION,
                RestaurantPermissionLevels.READ,
              ),
            },
          ],
        },
        {
          title: 'Pracownicy',
          path: paths.restaurantPanel.staff,
          icon: <Iconify icon="mdi:customer-service" />,
          disabled: !hasPermission(
            RestaurantPermissions.STAFF,
            RestaurantPermissionLevels.READ,
          ),
        },
        {
          title: 'Dziennik aktywności',
          path: paths.restaurantPanel.activityLog,
          icon: <Iconify icon="mdi:book-open-variant" />,
          disabled: !hasPermission(
            RestaurantPermissions.ACTIVITY,
            RestaurantPermissionLevels.READ,
          ),
        },
        {
          title: 'Ustawienia',
          path: paths.restaurantPanel.settings,
          icon: <Iconify icon="material-symbols:settings" />,
          disabled: !hasPermission(
            RestaurantPermissions.RESTAURANT,
            RestaurantPermissionLevels.READ,
          ),
        },
      ],
    },
  ];

  if (user?.tawk_to?.property_id) {
    menu[0].items.push({
      title: 'Chat',
      path: 'https://dashboard.tawk.to/',
      icon: <Iconify icon="mdi:chat" />,
      disabled: false,
    });
  }

  return menu;
};
