import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/material';
import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import FormProvider from 'src/components/helpers/hook-form/form-provider';
import { IncrementerButton } from 'src/components/incrementer-button';
import { useCartContext } from 'src/providers/cart';
import { CardItem } from 'src/providers/cart/types';
import { useMenuContext } from 'src/providers/menu';
import Iconify from 'src/theme/iconify/iconify';

export type CarouselMealAddToCartProps = {
  mealUuid: string;
  itemInCart: CardItem | null | undefined;
};

const AVAILABLE = 50;

export default function CarouselMealAddToCart({
  mealUuid,
  itemInCart,
}: CarouselMealAddToCartProps) {
  const {
    state: {
      current: { person },
    },
  } = useMenuContext();
  const { addToCart, updateCartItem, removeFromCart, month } = useCartContext();
  const [removeLoading, setRemoveLoading] = useState(false);

  const defaultValues = {
    quantity: itemInCart?.quantity || 1,
    email: '',
  };

  const methods = useForm({
    defaultValues,
  });

  const {
    watch,
    setValue,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  const onSubmit = handleSubmit(async () => {
    // nothing changed, no need to update
    if (itemInCart && itemInCart.quantity === values.quantity) {
      return;
    }
    if (itemInCart) {
      await updateCartItem(itemInCart.uuid, {
        quantity: values.quantity,
      });
      return;
    }
    if (!person) return;
    await addToCart({
      child_uuid: person.type === 'child' ? person.uuid : null,
      quantity: values.quantity,
      date: month,
      menu_meal_uuid: mealUuid,
    });
  });

  const handleRemoveFromCart = async () => {
    if (!itemInCart) return;
    setRemoveLoading(true);
    await removeFromCart(itemInCart.uuid);
    setRemoveLoading(false);
    reset({ quantity: 1, email: '' });
  };

  const buttonLabel = useMemo(() => {
    if (!itemInCart) {
      return 'Dodaj do koszyka';
    }
    if (values.quantity === itemInCart.quantity) {
      return 'Dodano';
    }
    if (values.quantity === 0) {
      return 'Usuń z koszyka';
    }
    return 'Aktualizuj';
  }, [itemInCart, values.quantity]);

  return (
    <Box>
      <FormProvider key={2} methods={methods} onSubmit={onSubmit}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
          }}
        >
          <IncrementerButton
            name="quantity"
            quantity={values.quantity}
            disabledDecrease={values.quantity <= 1}
            disabledIncrease={values.quantity >= AVAILABLE}
            onIncrease={() => setValue('quantity', values.quantity + 1)}
            onDecrease={() => setValue('quantity', values.quantity - 1)}
          />
          <Box sx={{ display: 'flex', gap: 1 }}>
            <LoadingButton
              fullWidth
              size="medium"
              type="submit"
              variant="contained"
              color={itemInCart ? 'primary' : undefined}
              loading={isSubmitting}
              startIcon={<Iconify icon="eva:shopping-cart-outline" />}
            >
              {buttonLabel}
            </LoadingButton>
            {itemInCart && (
              <LoadingButton
                aria-label="delete"
                size="medium"
                type="button"
                variant="contained"
                color="error"
                loading={removeLoading}
                onClick={handleRemoveFromCart}
                sx={{
                  minWidth: '40px',
                }}
              >
                <Iconify icon="eva:trash-outline" />
              </LoadingButton>
            )}
          </Box>
        </Box>
      </FormProvider>
    </Box>
  );
}
