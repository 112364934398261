import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { setLocale } from 'yup';
import App from './App';

// Customise Yup's default error messages:
// https://github.com/jquense/yup#error-message-customization
setLocale({
  string: { email: 'Niepoprawny email' },
  number: {
    min: ({ min }) => `Wartość minimalna pola wynosi ${min}`,
    max: ({ max }) => `Wartość maksymalna pola wynosi ${max}`,
  },
  mixed: {
    required: ({ path }) => `Pole ${path} jest wymagane`,
  },
});
//

// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <HelmetProvider>
    <BrowserRouter>
      <Suspense>
        <App />
      </Suspense>
    </BrowserRouter>
  </HelmetProvider>,
);
