import {
  PermissionsCategories,
  PermissionsGroups,
  RestaurantPermissionLevels,
  RestaurantPermissions,
} from 'src/types/restaurant/permissions';

const PERMISSION_LEVELS: Record<RestaurantPermissionLevels, string> = {
  [RestaurantPermissionLevels.READ]: 'Przeglądanie',
  [RestaurantPermissionLevels.WRITE]: 'Edycja',
};

const PERMISSION_NAMES: Record<RestaurantPermissions, string> = {
  [RestaurantPermissions.ACTIVITY]: 'Aktywność',
  [RestaurantPermissions.ALLERGEN]: 'Alergeny',
  [RestaurantPermissions.BALANCE_HISTORY]: 'Historia salda',
  [RestaurantPermissions.BALANCE]: 'Saldo',
  [RestaurantPermissions.CANCELLATION]: 'Anulowania posiłków',
  [RestaurantPermissions.CART_ITEM]: 'Pozycje w koszyku',
  [RestaurantPermissions.CART]: 'Koszyk',
  [RestaurantPermissions.CHILD]: 'Dzieci',
  [RestaurantPermissions.DRAFT_MENU]: 'Szablony menu',
  [RestaurantPermissions.FAILED_JOB]: 'Nieudane zadania',
  [RestaurantPermissions.INGREDIENT]: 'Składniki',
  [RestaurantPermissions.MEAL]: 'Posiłki',
  [RestaurantPermissions.MEAL_BUNDLE]: 'Zestawy posiłków',
  [RestaurantPermissions.MEAL_CATEGORY]: 'Kategorie posiłków',
  [RestaurantPermissions.MENU]: 'Menu',
  [RestaurantPermissions.MENU_MEALABLE]: 'Posiłki w menu',
  [RestaurantPermissions.ORDER]: 'Zamówienia',
  [RestaurantPermissions.ORDER_ITEM]: 'Pozycja zamówień',
  [RestaurantPermissions.PAYMENT]: 'Płatności',
  [RestaurantPermissions.PROMOTION]: 'Promocje',
  // REPORTS
  [RestaurantPermissions.REPORT_MEALS_TO_COOK]:
    'Raporty - Posiłki do przygotowania',
  [RestaurantPermissions.REPORT_INGREDIENTS_TO_COLLECT]:
    'Raporty - Składniki do kupienia',
  [RestaurantPermissions.REPORT_RESTAURANT_REVENUE]: 'Raporty - Przychody',
  [RestaurantPermissions.REPORT_RESTAURANT_COMMISSION]: 'Raporty - Opłaty',
  [RestaurantPermissions.REPORT_CHILDREN_MEALS_BY_institution_place]:
    'Raporty - Lista odbioru posiłków',
  [RestaurantPermissions.REPORT_MEALS_PACKAGE_LABELS]:
    'Raporty - Etykiety paczek posiłków',
  [RestaurantPermissions.REPORT_INSTITUTION_END_OF_MONTH_CUSTOMERS_FINANCING]:
    'Raporty - Opłacone posiłki klientów',

  [RestaurantPermissions.RESTAURANT]: 'Restauracja',
  [RestaurantPermissions.ROLE]: 'Role pracowników',
  [RestaurantPermissions.RULE_CONDITION]: 'Warunki reguł',
  [RestaurantPermissions.INSTITUTION]: 'Instytucje',
  [RestaurantPermissions.institution_place]: 'Placówki',
  [RestaurantPermissions.CUSTOMER]: 'Klienci',
  [RestaurantPermissions.STAFF]: 'Pracownicy',
  [RestaurantPermissions.USERS_GROUP]: 'Grupy użytkowników',
  [RestaurantPermissions.WORKING_DAY]: 'Dni pracy instytucji',
};

export const PERMISSIONS_PER_GROUP: Record<
  PermissionsGroups,
  RestaurantPermissions[]
> = {
  [PermissionsGroups.MENU]: [
    RestaurantPermissions.MENU,
    // RestaurantPermissions.MENU_MEALABLE, // not used
    RestaurantPermissions.DRAFT_MENU,
    RestaurantPermissions.MEAL,
    RestaurantPermissions.MEAL_BUNDLE,
    RestaurantPermissions.MEAL_CATEGORY,
    RestaurantPermissions.INGREDIENT,
    RestaurantPermissions.ALLERGEN,
  ],
  [PermissionsGroups.INSTITUTION]: [
    RestaurantPermissions.INSTITUTION,
    RestaurantPermissions.institution_place,
    RestaurantPermissions.WORKING_DAY,
  ],
  [PermissionsGroups.REPORTS]: [
    RestaurantPermissions.REPORT_MEALS_TO_COOK,
    RestaurantPermissions.REPORT_INGREDIENTS_TO_COLLECT,
    RestaurantPermissions.REPORT_RESTAURANT_REVENUE,
    RestaurantPermissions.REPORT_RESTAURANT_COMMISSION,
    RestaurantPermissions.REPORT_CHILDREN_MEALS_BY_institution_place,
    RestaurantPermissions.REPORT_MEALS_PACKAGE_LABELS,
    RestaurantPermissions.REPORT_INSTITUTION_END_OF_MONTH_CUSTOMERS_FINANCING,
  ],
  [PermissionsGroups.RESTAURANT_SETTINGS]: [RestaurantPermissions.RESTAURANT],
  [PermissionsGroups.CUSTOMERS]: [
    RestaurantPermissions.CUSTOMER,
    RestaurantPermissions.USERS_GROUP,
    RestaurantPermissions.BALANCE_HISTORY,
    RestaurantPermissions.BALANCE,
    RestaurantPermissions.ORDER,
    RestaurantPermissions.CHILD,
    RestaurantPermissions.CANCELLATION,
  ],
  [PermissionsGroups.STAFF]: [
    RestaurantPermissions.STAFF,
    RestaurantPermissions.ROLE,
  ],
  [PermissionsGroups.OTHER]: [
    RestaurantPermissions.ACTIVITY,
    // RestaurantPermissions.CART_ITEM, // not used
    // RestaurantPermissions.CART, // not used
    // RestaurantPermissions.RULE_CONDITION, // not used
    // RestaurantPermissions.FAILED_JOB, //not used
    // RestaurantPermissions.PROMOTION, // not used
    // RestaurantPermissions.PAYMENT, // not used
  ],
};

export const PERMISSIONS_PER_CATEGORY: Record<
  PermissionsCategories,
  RestaurantPermissions[]
> = {
  [PermissionsCategories.CUSTOMERS]: [
    RestaurantPermissions.CUSTOMER,
    RestaurantPermissions.INSTITUTION,
    RestaurantPermissions.institution_place,
    RestaurantPermissions.WORKING_DAY,
    RestaurantPermissions.CHILD,
    RestaurantPermissions.USERS_GROUP,
    RestaurantPermissions.BALANCE,
    RestaurantPermissions.CANCELLATION,
    RestaurantPermissions.ORDER,
  ],
  [PermissionsCategories.MENU]: [
    RestaurantPermissions.ALLERGEN,
    RestaurantPermissions.INGREDIENT,
    RestaurantPermissions.MEAL,
    RestaurantPermissions.MEAL_BUNDLE,
    RestaurantPermissions.MEAL_CATEGORY,
    RestaurantPermissions.MENU,
    RestaurantPermissions.DRAFT_MENU,
  ],
  [PermissionsCategories.REPORTS]: [
    RestaurantPermissions.REPORT_MEALS_TO_COOK,
    RestaurantPermissions.REPORT_INGREDIENTS_TO_COLLECT,
    RestaurantPermissions.REPORT_RESTAURANT_REVENUE,
    RestaurantPermissions.REPORT_RESTAURANT_COMMISSION,
    RestaurantPermissions.REPORT_CHILDREN_MEALS_BY_institution_place,
    RestaurantPermissions.REPORT_MEALS_PACKAGE_LABELS,
    RestaurantPermissions.REPORT_INSTITUTION_END_OF_MONTH_CUSTOMERS_FINANCING,
  ],
  [PermissionsCategories.RESTAURANT_SETTINGS]: [
    RestaurantPermissions.RESTAURANT,
  ],
  [PermissionsCategories.STAFF]: [
    RestaurantPermissions.STAFF,
    RestaurantPermissions.ROLE,
  ],
  [PermissionsCategories.ACTIVITY]: [RestaurantPermissions.ACTIVITY],
};

export const REQUIRED_PERMISSIONS_PER_CATEGORY: Record<
  PermissionsCategories,
  RestaurantPermissions[]
> = {
  [PermissionsCategories.CUSTOMERS]: [
    RestaurantPermissions.CUSTOMER,
    RestaurantPermissions.INSTITUTION,
    RestaurantPermissions.institution_place,
  ],
  [PermissionsCategories.MENU]: [
    RestaurantPermissions.ALLERGEN,
    RestaurantPermissions.INGREDIENT,
    RestaurantPermissions.MEAL,
    RestaurantPermissions.MEAL_BUNDLE,
    RestaurantPermissions.MEAL_CATEGORY,
  ],
  [PermissionsCategories.REPORTS]: [],
  [PermissionsCategories.RESTAURANT_SETTINGS]: [],
  [PermissionsCategories.STAFF]: [RestaurantPermissions.STAFF],
  [PermissionsCategories.ACTIVITY]: [RestaurantPermissions.ACTIVITY],
};

export const CATEGORIES_NAMES: Record<PermissionsCategories, string> = {
  [PermissionsCategories.MENU]: 'Menu',
  [PermissionsCategories.CUSTOMERS]: 'Klienci',
  [PermissionsCategories.REPORTS]: 'Raporty',
  [PermissionsCategories.STAFF]: 'Pracownicy',
  [PermissionsCategories.ACTIVITY]: 'Aktywność',
  [PermissionsCategories.RESTAURANT_SETTINGS]: 'Ustawienia restauracji',
};

export const getPermissionNameLabel = (
  permissionName: RestaurantPermissions,
): string => {
  return PERMISSION_NAMES[permissionName] || permissionName;
};

export const getPermissionLevelLabel = (
  permissionLevel: RestaurantPermissionLevels,
): string => {
  return PERMISSION_LEVELS[permissionLevel] || permissionLevel;
};
