import { lazy, Suspense } from 'react';
import { Outlet, RouteObject } from 'react-router-dom';
import Loader from 'src/components/helpers/loader/loader';

import { UserType } from 'src/providers/auth/types';
import RestaurantPanelLayout from 'src/layouts/restaurantPanel';
import { ProtectedRoute } from '../components';

export const RestaurantDashboardPage = lazy(
  () => import('src/pages/restaurantPanel'),
);
export const StaffPage = lazy(() => import('src/pages/restaurantPanel/Staff'));
export const CustomersPage = lazy(
  () => import('src/pages/restaurantPanel/Customers'),
);
export const CustomerPage = lazy(
  () => import('src/pages/restaurantPanel/Customer'),
);
export const InstitutionsPage = lazy(
  () => import('src/pages/restaurantPanel/Institutions'),
);
export const InstitutionPage = lazy(
  () => import('src/pages/restaurantPanel/Institution'),
);
export const ReportsPage = lazy(
  () => import('src/pages/restaurantPanel/Reports'),
);
export const MealsToCookPage = lazy(
  () => import('src/pages/restaurantPanel/Reports/MealsToCook'),
);
export const MealCollectionListPage = lazy(
  () => import('src/pages/restaurantPanel/Reports/MealCollectionList'),
);
export const IngredientsToCollectPage = lazy(
  () => import('src/pages/restaurantPanel/Reports/IngredientsToCollect'),
);
export const RestaurantRevenuePage = lazy(
  () => import('src/pages/restaurantPanel/Reports/RestaurantRevenue'),
);
export const RestaurantCommissionPage = lazy(
  () => import('src/pages/restaurantPanel/Reports/RestaurantCommission'),
);
export const PaidMealsPage = lazy(
  () => import('src/pages/restaurantPanel/Reports/PaidMeals'),
);
export const AllergensPage = lazy(
  () => import('src/pages/restaurantPanel/Allergens'),
);
export const IngredientsPage = lazy(
  () => import('src/pages/restaurantPanel/Ingredients'),
);
export const MealCategoriesPage = lazy(
  () => import('src/pages/restaurantPanel/MealCategories'),
);
export const MealsPage = lazy(() => import('src/pages/restaurantPanel/Meals'));
export const MealBundlesPage = lazy(
  () => import('src/pages/restaurantPanel/MealBundles'),
);
export const MenusPage = lazy(() => import('src/pages/restaurantPanel/Menus'));
export const DraftMenuPage = lazy(
  () => import('src/pages/restaurantPanel/Menus/Draft'),
);
export const InstitutionMenuPage = lazy(
  () => import('src/pages/restaurantPanel/Menus/InstitutionMenu'),
);
export const MenuPage = lazy(() => import('src/pages/restaurantPanel/Menu'));
export const ActivityLog = lazy(
  () => import('src/pages/restaurantPanel/ActivityLog'),
);
export const ActivityLogDetails = lazy(
  () => import('src/pages/restaurantPanel/ActivityLogDetails'),
);
export const SettingsPage = lazy(
  () => import('src/pages/restaurantPanel/Settings'),
);
export const OrdersPage = lazy(
  () => import('src/pages/restaurantPanel/Orders'),
);
export const OrderPage = lazy(() => import('src/pages/restaurantPanel/Order'));
export const CancellationsPage = lazy(
  () => import('src/pages/restaurantPanel/Cancellations'),
);
export const CancellationPage = lazy(
  () => import('src/pages/restaurantPanel/Cancellation'),
);
export const HomePage = lazy(() => import('src/pages/restaurantPanel/Home'));

export const restauratorRoutes: RouteObject[] = [
  {
    path: '/restaurant',
    element: (
      <ProtectedRoute allowType={UserType.RESTAURANT_STAFF}>
        <RestaurantPanelLayout>
          <Suspense fallback={<Loader />}>
            <Outlet />
          </Suspense>
        </RestaurantPanelLayout>
      </ProtectedRoute>
    ),
    children: [
      {
        element: <HomePage />,
        index: true,
      },
      {
        path: 'menu',
        children: [
          { index: true, element: <MenuPage /> },
          { path: 'menu/draft/:uuid', element: <DraftMenuPage /> },
          {
            path: 'menu/institution-menu/:uuid',
            element: <InstitutionMenuPage />,
          },
          { path: 'menu/:tab', element: <MenusPage /> },
          { path: 'menu', element: <MenusPage /> },
          { path: 'meal-bundles', element: <MealBundlesPage /> },
          { path: 'meals', element: <MealsPage /> },
          { path: 'meals-categories', element: <MealCategoriesPage /> },
          { path: 'ingredients', element: <IngredientsPage /> },
          { path: 'allergens', element: <AllergensPage /> },
        ],
      },
      {
        path: 'institutions',
        element: <Outlet />,
        children: [
          {
            element: <InstitutionsPage />,
            index: true,
          },
          { path: ':institutionId/:tab', element: <InstitutionPage /> },
          { path: ':institutionId', element: <InstitutionPage /> },
        ],
      },
      {
        path: 'reports',
        element: <Outlet />,
        children: [
          {
            element: <ReportsPage />,
            index: true,
          },
          { path: 'meals-to-cook', element: <MealsToCookPage /> },
          {
            path: 'ingredients-to-collect',
            element: <IngredientsToCollectPage />,
          },
          {
            path: 'restaurant-revenue',
            element: <RestaurantRevenuePage />,
          },
          {
            path: 'restaurant-commission',
            element: <RestaurantCommissionPage />,
          },
          {
            path: 'meal-collection-list',
            element: <MealCollectionListPage />,
          },
          {
            path: 'paid-meals',
            element: <PaidMealsPage />,
          },
        ],
      },
      {
        path: 'customers',
        element: <Outlet />,
        children: [
          {
            element: <CustomersPage />,
            index: true,
          },
          {
            path: 'list',
            element: <CustomersPage defaultTab="list" />,
          },
          { path: 'groups', element: <CustomersPage defaultTab="groups" /> },
          { path: ':customerId/:tab', element: <CustomerPage /> },
          { path: ':customerId', element: <CustomerPage /> },
        ],
      },
      {
        path: 'staff',
        element: <Outlet />,
        children: [
          {
            element: <StaffPage />,
            index: true,
          },
          {
            path: 'list',
            element: <StaffPage defaultTab="list" />,
          },
          { path: 'roles', element: <StaffPage defaultTab="roles" /> },
        ],
      },
      {
        path: 'orders',
        element: <Outlet />,
        children: [
          {
            element: <OrdersPage />,
            index: true,
          },
          { path: ':orderId', element: <OrderPage /> },
        ],
      },
      {
        path: 'cancellations',
        element: <Outlet />,
        children: [
          {
            element: <CancellationsPage />,
            index: true,
          },
          { path: ':cancellationId', element: <CancellationPage /> },
        ],
      },
      {
        path: 'settings',
        element: <Outlet />,
        children: [
          {
            element: <SettingsPage />,
            index: true,
          },
          {
            path: 'general',
            element: <SettingsPage defaultTab="general" />,
          },
          { path: 'reports', element: <SettingsPage defaultTab="reports" /> },
        ],
      },
      {
        path: 'activity-log',
        element: <Outlet />,
        children: [
          {
            element: <ActivityLog />,
            index: true,
          },
          {
            path: 'operations',
            element: <ActivityLog defaultTab="operations" />,
          },
          { path: 'batch', element: <ActivityLog defaultTab="batch" /> },
          { path: ':activityLogId', element: <ActivityLogDetails /> },
        ],
      },
    ],
  },
];
