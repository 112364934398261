export const paths = {
  parentPanel: {
    main: '/customer',
    orders: '/customer/orders',
    order: (uuid: string) => `/customer/orders/${uuid}`,
    newOrderChooseMonth: '/customer/new-order',
    newOrder: (date: string) => `/customer/new-order/${date}`,
    newOrderPickup: (date: string) => `/customer/new-order/${date}/pickup`,
    newOrderSummary: (date: string) => `/customer/new-order/${date}/summary`,
    orderPay: (orderId: string) => `/customer/orders/${orderId}/pay`,
    orderPayAgain: (orderId: string) =>
      `/customer/orders/${orderId}/pay?again=true`,
    childrenMenu: '/customer/children-menu',
    balance: '/customer/balance',
    children: '/customer/children',
    settings: '/customer/settings',
    contact: '/customer/contact',
  },

  restaurantPanel: {
    main: '/restaurant',
    mainMenu: '/restaurant/menu',
    draftMenu: (uuid: string) => `/restaurant/menu/menu/draft/${uuid}`,
    institutionMenu: (uuid: string) =>
      `/restaurant/menu/menu/institution-menu/${uuid}`,
    menu: '/restaurant/menu/menu',
    menuDrafts: '/restaurant/menu/menu/drafts',
    menuForInstitution: '/restaurant/menu/menu/institutionMenu',
    mealBundles: '/restaurant/menu/meal-bundles',
    meals: '/restaurant/menu/meals',
    mealsCategories: '/restaurant/menu/meals-categories',
    ingredients: '/restaurant/menu/ingredients',
    allergens: '/restaurant/menu/allergens',
    institutions: '/restaurant/institutions',
    institution: (uuid: string) => `/restaurant/institutions/${uuid}`,
    customers: '/restaurant/customers',
    customersList: '/restaurant/customers/list',
    customersGroups: '/restaurant/customers/groups',
    customer: (uuid: string) => `/restaurant/customers/${uuid}`,
    staff: '/restaurant/staff',
    staffList: '/restaurant/staff/list',
    staffGroups: '/restaurant/staff/groups',
    staffMember: (uuid: string) => `/restaurant/staff/${uuid}`,
    activityLog: '/restaurant/activity-log',
    activityLogDetails: (id: number) => `/restaurant/activity-log/${id}`,
    settings: '/restaurant/settings',
    orders: '/restaurant/orders',
    order: (uuid: string) => `/restaurant/orders/${uuid}`,
    cancellations: '/restaurant/cancellations',
    cancellation: (uuid: string) => `/restaurant/cancellations/${uuid}`,
    reports: {
      main: '/restaurant/reports',
      mealsToCook: '/restaurant/reports/meals-to-cook',
      ingredientsToCollect: '/restaurant/reports/ingredients-to-collect',
      restaurantRevenue: '/restaurant/reports/restaurant-revenue',
      restaurantCommission: '/restaurant/reports/restaurant-commission',
      mealCollectionList: '/restaurant/reports/meal-collection-list',
      paidMeals: '/restaurant/reports/paid-meals',
    },
  },

  auth: {
    login: '/login',
    newPassword: '/new-password',
    forgotPassword: '/forgot-password',
    invitation: '/invitation',
    institutionInvitation: (guid: string) => `/zaproszenie/${guid}`,
    applyInstitutionInvitation: (token: string, guid: string) =>
      `/school-invitation/${token}/${guid}`,
    impersonate: '/impersonate',
  },

  error: {
    page404: '/404',
  },
};
