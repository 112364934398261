export const formatData = (date: Date) => {
  const year = date.toLocaleString('pl-PL', { year: 'numeric' });
  const month = date.toLocaleString('pl-PL', {
    month: '2-digit',
  });
  const day = date.toLocaleString('pl-PL', { day: '2-digit' });
  return [year, month, day].join('-');
};

export const getOrderMonthFormatted = (
  date: Date,
  locale: string = 'pl',
): string => {
  const formattedDate = date.toLocaleDateString(locale, {
    month: 'long',
  });

  return formattedDate;
};

export const getOrderDateFormatted = (
  date: Date,
  locale: string = 'pl',
): string => {
  const formattedDate = date.toLocaleDateString(locale, {
    year: 'numeric',
    month: 'long',
  });

  return formattedDate;
};

export const getDayOfWeekFormatted = (
  date: Date,
  locale: string = 'pl',
): string => {
  const formattedDate = date.toLocaleDateString(locale, {
    weekday: 'long',
  });

  return formattedDate;
};
