import { Badge, Stack, Typography } from '@mui/material';

const Caption = () => {
  return (
    <Stack
      spacing={2}
      direction="row"
      flexWrap="wrap"
      alignItems="center"
      justifyContent="flex-end"
      sx={{ mr: 4 }}
    >
      <Typography variant="caption" color="GrayText">
        Oznaczenia:
      </Typography>
      <Stack
        spacing={1}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Badge badgeContent=" " variant="dot" color="secondary" />
        <Typography variant="caption" color="GrayText">
          Dzisiaj
        </Typography>
      </Stack>
      <Stack
        spacing={1}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Badge badgeContent=" " variant="dot" color="success" />
        <Typography variant="caption" color="GrayText">
          Zamówiony posiłek
        </Typography>
      </Stack>
    </Stack>
  );
};

export default Caption;
