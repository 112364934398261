import CustomHelmet from 'src/components/CustomHelmet';
import NewOrderView from 'src/views/parentPanel/NewOrder';

export default function NewOrder() {
  return (
    <>
      <CustomHelmet title="Nowe zamówienie" />
      <NewOrderView />
    </>
  );
}
