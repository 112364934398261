/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Badge,
  Box,
  CircularProgress,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { endOfDay, isPast, isToday } from 'date-fns';
import { useCallback, useEffect, useMemo } from 'react';
import { useCartContext } from 'src/providers/cart';
import { usePersonsMenuContext } from 'src/providers/children-menu';
import { useMenuContext } from 'src/providers/menu';

export const MenuTabs = () => {
  const {
    state: {
      current: { day, person, month },
    },
    setCurrentDay,
  } = useMenuContext();
  const theme = useTheme();

  const { childrenMenu } = usePersonsMenuContext();
  const { cart } = useCartContext();

  const currentChildMenu = person ? childrenMenu[person.uuid]?.[month] : null;

  const handleChangeDayTab = useCallback(
    (event: React.SyntheticEvent, newDay: string) => {
      setCurrentDay(newDay);
    },
    [setCurrentDay],
  );

  useEffect(() => {
    // day already selected leave it
    if (day) return;
    if (currentChildMenu) {
      const firstAvailableDay = currentChildMenu.menu.find(
        (dayItem) => dayItem.mealables && dayItem.mealables.length > 0,
      );
      if (firstAvailableDay) {
        setCurrentDay(firstAvailableDay.date.full);
      }
    }
  }, [day, currentChildMenu, setCurrentDay]);

  const formatUnavailabilityReason = useCallback(
    (reason: string | null): string | null => {
      if (!reason) return null;
      const map: Record<string, string> = {
        already_ordered: 'Zamówienie na dany dzień zostało już złożone',
        non_working_day: 'Dzień niepracujący',
        // custom frontend
        no_menu: 'Menu na dany dzień nie zostało opublikowane',
      };

      return map[reason] ?? null;
    },
    [],
  );

  if (!day) {
    return (
      <Box
        sx={{ display: 'flex', justifyContent: 'center', width: '100%', pb: 3 }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Tabs
      value={day}
      onChange={handleChangeDayTab}
      sx={{ maxWidth: '100%', px: { xs: 2, md: 0 }, mb: 2 }}
      TabScrollButtonProps={{
        sx: {
          '& .css-egnern-MuiSvgIcon-root': {
            height: 50,
            width: 50,
            color: theme.palette.primary.dark,
          },
        },
      }}
      TabIndicatorProps={{
        sx: {
          height: '3px',
          backgroundColor: theme.palette.primary.main,
        },
      }}
      allowScrollButtonsMobile={false}
      variant="scrollable"
      scrollButtons
    >
      {currentChildMenu?.menu.map(
        ({ mealables, unavailability_reason, date }) => {
          const canOrder = mealables && mealables.length > 0;

          // unavailability_reason or if cannot order, set to no_menu
          const unavilabilityReason =
            unavailability_reason || (!canOrder ? 'no_menu' : null);

          const formattedUnavilaibilityReason =
            formatUnavailabilityReason(unavilabilityReason);

          return (
            <Tab
              key={date.full}
              value={date.full}
              sx={{
                opacity: isPast(new Date(date.full)) ? 0.48 : 1,
                textDecoration: 'none',
                '&.Mui-disabled': {
                  opacity: isPast(endOfDay(new Date(date.full))) ? 0.2 : 0.48,
                  pointerEvents: 'auto',
                },
              }}
              disabled={!canOrder}
              label={
                <Tooltip
                  title={formattedUnavilaibilityReason || false}
                  placement="top"
                  arrow
                >
                  <Stack>
                    <Box sx={{ pt: 1 }}>
                      <Typography
                        variant="h3"
                        color={
                          day === date.full
                            ? theme.palette.primary.main
                            : theme.palette.text.primary
                        }
                      >
                        <Badge
                          badgeContent=" "
                          variant="dot"
                          color="success"
                          invisible={
                            !cart?.items?.find((cartItem) => {
                              if (!person) return false;
                              const personUuid =
                                person.type === 'child' ? person.uuid : null;
                              return (
                                cartItem.available_at === date.full &&
                                cartItem.child_uuid === personUuid
                              );
                            })
                          }
                        >
                          <Badge
                            badgeContent=" "
                            variant="dot"
                            color="secondary"
                            invisible={!isToday(new Date(date.full))}
                          >
                            {date.day}
                          </Badge>
                        </Badge>
                      </Typography>
                    </Box>
                    <Box paddingBottom={0.5}>
                      <Typography
                        variant="subtitle1"
                        color={theme.palette.text.secondary}
                      >
                        {date.day_full_name}
                      </Typography>
                    </Box>
                  </Stack>
                </Tooltip>
              }
            />
          );
        },
      )}
    </Tabs>
  );
};
