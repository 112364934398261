import { Avatar, Box, Stack, Typography } from '@mui/material';
import { PersonMenuAvailability } from 'src/providers/menu/types';
import Label from '../helpers/label';

type PersonItemProps = {
  person: PersonMenuAvailability;
};

const PersonItem = ({ person }: PersonItemProps) => {
  const details = person.type === 'child' ? person.child : person.customer;

  return (
    <Box display="flex" alignItems="center">
      <Avatar
        sx={{
          width: 24,
          height: 24,
          mr: 2,
          bgcolor: 'primary.dark',
        }}
      >
        <Typography sx={{ fontSize: '14px' }}>
          {details.first_name?.[0]}
        </Typography>
      </Avatar>

      <Stack>
        <Box display="flex" gap={1}>
          <Typography variant="subtitle2">
            {/* use more generic option here to have unified data */}
            {details.first_name} {details.last_name}{' '}
          </Typography>
          {!person.has_orders && <Label color="warning">Brak zamówienia</Label>}
        </Box>
      </Stack>
    </Box>
  );
};

export default PersonItem;
