import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import Label from 'src/components/helpers/label/label';
import MealDetailsFetcher from 'src/components/parents/MealDetails/MealDetailsFetcher';
import MealBundleDetails from 'src/components/parents/MealBundleDetails';
import DialogHeader from 'src/components/dialog/DialogHeader';

export type MealDetailsModalProps = {
  opened: boolean;
  onClose: () => void;
  categoryName: string;
  mealable: any;
};

const MealDetailsModal = ({
  opened,
  onClose,
  categoryName,
  mealable,
}: MealDetailsModalProps) => {
  return (
    <Dialog
      open={opened}
      maxWidth={mealable.type === 'bundle' ? 'md' : 'xs'}
      onClose={onClose}
    >
      <DialogTitle>
        <DialogHeader onClose={onClose}>
          {mealable.name}
          <Label sx={{ flexShrink: 0, ml: 1, verticalAlign: 'middle' }}>
            {categoryName}
          </Label>
        </DialogHeader>
      </DialogTitle>
      <DialogContent sx={{ pb: 3 }}>
        {mealable.type === 'bundle' ? (
          <MealBundleDetails mealBundleId={mealable.uuid} />
        ) : (
          <MealDetailsFetcher mealId={mealable.uuid} />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default MealDetailsModal;
