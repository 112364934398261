import { Alert, Button, Stack } from '@mui/material';
import { Link } from 'react-router-dom';
import { varFade } from 'src/assets/animate';
import { paths } from 'src/routes/paths';
import { ApiResponse } from 'src/types/common';
import { endpoints } from 'src/utils/axios';
import useSWR from 'swr';
import { m } from 'framer-motion';
import { Child } from 'src/providers/children/types';
import { useAuthContext } from 'src/providers/auth';
import { hasInstitutionType } from 'src/utils/user';

const NoChildMessage = () => {
  const { user } = useAuthContext();

  const { data, isLoading } = useSWR<ApiResponse<Child[]>>(
    user && hasInstitutionType(user, 'school')
      ? endpoints.customer.getChildren
      : null,
  );

  const children = data?.data ?? [];

  if (isLoading) return null;
  // check only if user have company institution type
  if (!user) return null;
  if (
    user &&
    hasInstitutionType(user, 'company') &&
    !hasInstitutionType(user, 'school')
  )
    return null;

  if (children.length > 0) return null;

  return (
    <m.div variants={varFade().inLeft}>
      <Alert severity="error">
        <Stack
          direction="row"
          spacing={0.5}
          alignItems="center"
          flexWrap="wrap"
        >
          Do Twojego konta nie ma przypisanego żadnego dziecka. Aby móc zamawiać
          posiłki
          <Link to={paths.parentPanel.children}>
            <Button variant="soft" color="error" size="small">
              Dodaj dziecko
            </Button>
          </Link>
        </Stack>
      </Alert>
    </m.div>
  );
};

export default NoChildMessage;
