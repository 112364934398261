import {
  Link as MuiLink,
  Button,
  Card,
  Container,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import CustomBreadcrumbs from 'src/components/helpers/custom-breadcrumbs';
import { paths } from 'src/routes/paths';
import { useEffect, useMemo, useState } from 'react';
import { addMonths, format } from 'date-fns';
import { Link } from 'react-router-dom';
import Iconify from 'src/theme/iconify';
import { capitalizeFirstLetter } from 'src/utils/format';
import { getOrderDateFormatted } from 'src/utils/date';
import useMenuAvailability from 'src/hooks/useMenuAvailability';
import Menu from './Menu';
import MonthSelect from './MonthSelect';
import PersonSelect from './PersonSelect';

export default function ChildrenMenuView() {
  const currentMonthFormatted = useMemo(() => {
    return format(new Date(), 'yyyy-MM');
  }, []);

  const currentMonthFullDateFormatted = useMemo(() => {
    return capitalizeFirstLetter(
      getOrderDateFormatted(new Date(currentMonthFormatted)),
    );
  }, [currentMonthFormatted]);

  const nextMonthFormatted = useMemo(() => {
    return format(addMonths(new Date(), 1), 'yyyy-MM');
  }, []);

  const nextMonthFullDateFormatted = useMemo(() => {
    return capitalizeFirstLetter(
      getOrderDateFormatted(new Date(nextMonthFormatted)),
    );
  }, [nextMonthFormatted]);

  const [month, setMonth] = useState(currentMonthFormatted);
  const [currentPerson, setCurrentPerson] = useState('');

  const { data, isLoading } = useMenuAvailability(
    month ? new Date(month) : new Date(),
  );

  const currentPersonDetails = useMemo(() => {
    return data.find((person) => {
      if (person.type === 'child') {
        return person.child.uuid === currentPerson;
      }
      return person.customer.uuid === currentPerson;
    });
  }, [data, currentPerson]);

  useEffect(() => {
    setCurrentPerson('');
  }, [month]);

  return (
    <Container maxWidth="lg">
      <CustomBreadcrumbs
        heading="Zamówienia"
        subheading={
          <>
            Przeglądaj zamówione posiłki, odwołuj je gdy planujesz nieobecność.
            <br />
            Aby zamówić nowe posiłki przejdź do{' '}
            <Link to={paths.parentPanel.newOrderChooseMonth}>
              <MuiLink color="CaptionText">Nowego zamówienia</MuiLink>
            </Link>
            .
          </>
        }
        links={[
          { name: 'Panel zamawiającego', href: paths.parentPanel.main },
          { name: 'Zamówienia' },
        ]}
        sx={{ mb: 3 }}
      />
      <Stack spacing={3}>
        <Card sx={{ p: { xs: 2, md: 4 } }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={6}>
              <MonthSelect
                options={[
                  {
                    value: currentMonthFormatted,
                    label: `${currentMonthFullDateFormatted} (bieżący)`,
                  },
                  {
                    value: nextMonthFormatted,
                    label: `${nextMonthFullDateFormatted} (przyszły)`,
                  },
                ]}
                value={month}
                onChange={setMonth}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              {month && (
                <PersonSelect
                  month={month}
                  options={data}
                  isLoading={isLoading}
                  value={currentPerson}
                  onChange={setCurrentPerson}
                />
              )}
            </Grid>
          </Grid>
          {currentPersonDetails && (
            <Menu month={month} person={currentPersonDetails} />
          )}
        </Card>
        {currentPerson && (
          <Stack
            spacing={{ xs: 2, lg: 1 }}
            direction={{ xs: 'column', lg: 'row' }}
            justifyContent="flex-end"
            alignItems="center"
          >
            <Stack
              spacing={1}
              direction={{ xs: 'column', lg: 'row' }}
              alignItems="center"
            >
              <Typography variant="subtitle1">
                Chcesz zamówić dodatkowy posiłek?
              </Typography>
              <Link to={paths.parentPanel.newOrderChooseMonth}>
                <Button
                  variant="contained"
                  color="inherit"
                  startIcon={<Iconify icon="ion:cart-outline" />}
                >
                  Złóż nowe zamówienie
                </Button>
              </Link>
            </Stack>
          </Stack>
        )}
      </Stack>
    </Container>
  );
}
