// @mui
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
// theme
import { bgBlur } from 'src/theme/css';
// hooks
import { useOffSetTop } from 'src/hooks/use-off-set-top';
import { useResponsive } from 'src/hooks/use-responsive';
// components
import Logo from 'src/components/helpers/logo';
import SvgColor from 'src/components/helpers/svg-color';
// import { useSettingsContext } from 'src/components/helpers/settings';
//
import { useAuthContext } from 'src/providers/auth';
import { Button, Chip, Typography } from '@mui/material';
import Iconify from 'src/theme/iconify/iconify';
import { useRouter } from 'src/routes/hooks';
import { HEADER, NAV } from '../config-layout';
// import {
//   // AccountPopover,
//   // SettingsButton,
//   // LanguagePopover,
//   // ContactsPopover,
//   // NotificationsPopover,
// } from '../_common';

// ----------------------------------------------------------------------

type Props = {
  onOpenNav?: VoidFunction;
};

export default function Header({ onOpenNav }: Props) {
  const { user, logout } = useAuthContext();

  const router = useRouter();
  const theme = useTheme();

  const isNavHorizontal = false;

  const lgUp = useResponsive('up', 'lg');

  const offset = useOffSetTop(HEADER.H_DESKTOP);

  const offsetTop = offset && !isNavHorizontal;

  const adminData = user?.impersonating_user
    ? [
        user.impersonating_user.first_name,
        user.impersonating_user.last_name,
        user.impersonating_user.email,
      ]
        .filter(Boolean)
        .join(' ')
    : null;

  const handleLogout = async () => {
    try {
      await logout();
      router.replace('/');
    } catch (error) {
      // console.error(error);
    }
  };

  const renderContent = (
    <>
      {lgUp && <Logo sx={{ mr: 2.5 }} />}

      {!lgUp && (
        <IconButton onClick={onOpenNav}>
          <SvgColor
            src="/assets/icons/navbar/ic_menu_item.svg"
            color="primary.main"
          />
        </IconButton>
      )}
      {/* <Searchbar /> */}

      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={{ xs: 0.5, sm: 1 }}
        sx={{
          display: { xs: 'none', sm: 'flex' },
        }}
      >
        {/* <LanguagePopover />

        <NotificationsPopover />

        <ContactsPopover />

        <SettingsButton /> */}

        {/* <AccountPopover /> */}
        {adminData ? (
          <>
            <Chip
              label={
                <Stack direction="row" alignItems="center" spacing={0.5}>
                  <Typography variant="caption" fontWeight="bold">
                    Admin:
                  </Typography>
                  {adminData}
                </Stack>
              }
              color="warning"
              icon={<Iconify icon="material-symbols:shield-lock" />}
            />
            <Button
              variant="soft"
              size="small"
              color="warning"
              onClick={handleLogout}
            >
              Wyloguj
            </Button>
          </>
        ) : null}
      </Stack>
    </>
  );

  return (
    <AppBar
      sx={{
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(lgUp && {
          width: `calc(100% - ${NAV.W_MINI + 1}px)`,
          height: HEADER.H_DESKTOP,
          ...(offsetTop && {
            height: HEADER.H_DESKTOP_OFFSET,
          }),
        }),
      }}
    >
      <Toolbar
        sx={{
          height: { xs: HEADER.H_MOBILE, lg: HEADER.H_DESKTOP_OFFSET },
          minHeight: { xs: HEADER.H_MOBILE, lg: HEADER.H_DESKTOP_OFFSET },
          px: { lg: 5 },
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}
