import { paths } from 'src/routes/paths';
import { UserType } from './providers/auth/types';

export type ENV_TYPE = 'local' | 'staging' | 'production';

export const PATH_AFTER_LOGIN: Record<UserType, string> = {
  [UserType.CUSTOMER]: paths.parentPanel.main,
  [UserType.RESTAURANT_STAFF]: paths.restaurantPanel.main,
};
export const HOST_API = process.env.REACT_APP_HOST_API;
export const HJ_SITE_ID = process.env.REACT_APP_HOTJAR_SITE_ID
  ? parseInt(process.env.REACT_APP_HOTJAR_SITE_ID, 10)
  : null;
export const COOKIEBOT_ID = process.env.REACT_APP_COOKIEBOT_ID;
export const APP_ENV: ENV_TYPE =
  (process.env.REACT_APP_ENV as ENV_TYPE) || 'local';
