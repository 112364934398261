import { NavSectionProps } from 'src/components/helpers/nav-section';
import Iconify from 'src/components/helpers/iconify';
import { paths } from 'src/routes/paths';
import { useAuthContext } from 'src/providers/auth';
import { hasInstitutionType } from 'src/utils/user';

export const useParentMenu: () => NavSectionProps['data'] = () => {
  const { user } = useAuthContext();

  const items = [
    {
      title: 'Start',
      path: paths.parentPanel.main,
      icon: <Iconify icon="material-symbols:home" />,
    },
    {
      title: 'Zamówienia',
      path: paths.parentPanel.childrenMenu,
      icon: <Iconify icon="mdi:food" />,
    },
    {
      title: 'Płatności',
      path: paths.parentPanel.orders,
      icon: <Iconify icon="ic:baseline-payment" />,
    },
    {
      title: 'Saldo',
      path: paths.parentPanel.balance,
      icon: <Iconify icon="tdesign:money" />,
    },
  ];

  if (user && hasInstitutionType(user, 'school')) {
    items.push({
      title: 'Dzieci',
      path: paths.parentPanel.children,
      icon: <Iconify icon="uil:kid" />,
    });
  }

  items.push({
    title: 'Ustawienia',
    path: paths.parentPanel.settings,
    icon: <Iconify icon="material-symbols:settings" />,
  });

  items.push({
    title: 'Kontakt',
    path: paths.parentPanel.contact,
    icon: <Iconify icon="material-symbols:phone-enabled" />,
  });

  return [{ items }];
};
