import { useRestaurantMenu } from 'src/config/restaurantMenu';
import DashboardLayout from '../dashboard';

type Props = {
  children: React.ReactNode;
};

export default function RestaurantPanelLayout({ children }: Props) {
  const menu = useRestaurantMenu();
  return <DashboardLayout menuItems={menu}>{children}</DashboardLayout>;
}
