import { isArray } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useTable } from 'src/components/helpers/table';
import { ApiResponse, WithPagination } from 'src/types/common';
import useSWR, { Arguments } from 'swr';
import { useDebounce } from 'usehooks-ts';

export const keyStartsWith = (key: string) => {
  return (currentKey: Arguments) => {
    return typeof currentKey === 'string' && currentKey.startsWith(key);
  };
};

type FilterValue =
  | string
  | Record<string, string | number | null>
  | (string | number | null)[];
type Filter = Record<string, FilterValue>;
type TableSettings = {
  defaultCurrentPage?: number;
  defaultRowsPerPage?: number;
  defaultOrderBy?: string;
};

const formatFilter = (filter: Filter) => {
  const filteredFilters = Object.keys(filter).filter(
    (property) => filter[property],
  );

  if (filteredFilters.length === 0) {
    return '';
  }

  return `&${filteredFilters
    .map((property) => {
      if (isArray(filter[property])) {
        return (filter[property] as (string | number)[])
          .filter((el) => el !== null && el !== undefined)
          .map((el) => `filter[${property}][]=${encodeURIComponent(el)}`)
          .join('&');
      }

      if (typeof filter[property] === 'object' && filter[property] !== null) {
        return Object.keys(filter[property])
          .filter((key) => {
            return (
              (filter[property] as Record<string, any>)[key] !== null &&
              (filter[property] as Record<string, any>)[key] !== undefined
            );
          })
          .map((key) => {
            return `filter[${property}][${key}]=${encodeURIComponent(
              (filter[property] as Record<string, any>)[key] as string,
            )}`;
          })
          .join('&');
      }

      return `filter[${property}]=${encodeURIComponent(
        filter[property] as string,
      )}`;
    })
    .join('&')}`;
};

export const useSWRPagination = <T>(
  url: string,
  filter?: Filter,
  tableSettings?: TableSettings,
  config?: any,
) => {
  const table = useTable({
    defaultCurrentPage: tableSettings?.defaultCurrentPage ?? 1,
    defaultRowsPerPage: tableSettings?.defaultRowsPerPage ?? 15,
    defaultOrderBy: tableSettings?.defaultOrderBy ?? '',
  });

  const { data, isLoading } = useSWR<ApiResponse<WithPagination<T[]>>>(
    `${url}${url.indexOf('?') > -1 ? '&' : '?'}page=${table.page}&per_page=${
      table.rowsPerPage
    }${filter ? formatFilter(filter) : ''}${
      table.orderBy ? `&sort=${table.orderBy}` : ''
    }`,
    config,
  );

  // reset page index on filter change
  useEffect(() => {
    table.onResetPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  // update table settings when data is loaded
  useEffect(() => {
    if (!data?.data) return;

    table.setPage(data?.data.current_page);
    table.setRowsPerPage(data?.data.per_page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const pagination = data?.data ?? null;
  const results = data?.data?.results ?? [];

  return {
    table,
    results,
    pagination,
    isLoading,
  };
};

type FilterSettings = {
  debounce?: number;
};

export const useFilter = <T extends Filter>(
  defaultFilter: T,
  settings: FilterSettings = {},
) => {
  const [filter, setFilter] = useState(defaultFilter);

  const filterDebounced = useDebounce(filter, settings.debounce || 600);

  const onFilterChange = useCallback((property: string, value: FilterValue) => {
    setFilter((state) => ({
      ...state,
      [property]: value,
    }));
  }, []);

  const clearFilters = useCallback(() => {
    setFilter(defaultFilter);
  }, [defaultFilter]);

  return {
    filter,
    setFilter,
    onFilterChange,
    clearFilters,
    filterDebounced,
  };
};
