import { View, Text, Image, StyleSheet } from '@react-pdf/renderer';
import { format } from 'date-fns';

type HeaderProps = {
  month: string;
  title: string;
};

const Header = ({ month, title }: HeaderProps) => {
  const monthFormatted = () => {
    return format(new Date(month), 'LLLL (MM.yyyy)');
  };

  return (
    <View style={styles.header}>
      <View style={styles.headerLeft}>
        <Image src="/assets/images/Caterlinker_Logo.png" style={styles.logo} />
        <View style={styles.dateLabelContainer}>
          <Text style={styles.dateLabelPrefix}>Menu na miesiąc</Text>
          <Text style={styles.dateLabel}>{monthFormatted()}</Text>
        </View>
      </View>
      <Text style={styles.title}>{title}</Text>
    </View>
  );
};

export default Header;

const styles = StyleSheet.create({
  header: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 3,
  },
  headerLeft: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 5,
  },
  logo: {
    width: 100,
  },
  dateLabelContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 3,
  },
  dateLabelPrefix: {
    fontSize: 12,
  },
  dateLabel: {
    fontSize: 12,
    textTransform: 'capitalize',
  },
  title: {
    fontSize: 12,
  },
});
