import { StyleSheet, View, Text } from '@react-pdf/renderer';
import { format, isSameMonth, isWeekend } from 'date-fns';

type MealProps = {
  name: string;
};

const Meal = ({ name }: MealProps) => (
  <Text style={styles.mealName}>{name}</Text>
);

type DayProps = {
  month: string;
  day: Date;
  meals: string[];
  isLongMonth: boolean; // more than 35 days in a month view
};

const Day = ({ month, day, meals, isLongMonth }: DayProps) => {
  return (
    <View
      key={day.toISOString()}
      style={[
        styles.day,
        isLongMonth ? styles.longMonthDay : {},
        isWeekend(new Date(day)) ? styles.weekendDay : {},
      ]}
    >
      {isSameMonth(day, new Date(month)) ? (
        <>
          <Text style={styles.dayName}>{format(day, 'dd.MM')}</Text>
          <View style={styles.divider} />
          <View style={styles.mealsList}>
            {meals.length > 0 ? (
              meals.map((meal) => <Meal name={meal} />)
            ) : (
              <Text>-</Text>
            )}
          </View>
        </>
      ) : null}
    </View>
  );
};

export default Day;

const styles = StyleSheet.create({
  day: {
    width: '14%',
    height: 100,
    padding: 5,
    border: '1px solid #333333',
    overflow: 'hidden',
  },
  longMonthDay: {
    height: 82,
  },
  weekendDay: {
    backgroundColor: '#f2f2f2',
  },
  dayName: {
    fontSize: 9,
    fontWeight: 700,
  },
  mealsList: {
    display: 'flex',
    gap: 3,
  },
  divider: {
    marginVertical: 2,
    backgroundColor: '#333333',
    width: '100%',
    height: 1,
  },
  mealName: {
    fontSize: 7,
    textOverflow: 'ellipsis',
  },
});
