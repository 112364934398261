import {
  Avatar,
  Box,
  MenuItem,
  Typography,
  useTheme,
  Stack,
  Select,
  InputLabel,
  FormControl,
  SelectChangeEvent,
} from '@mui/material';
import { useEffect, useMemo } from 'react';
import Label from 'src/components/helpers/label';

import { usePersonsContext } from 'src/providers/children';
import { useMenuContext } from 'src/providers/menu';
import Iconify from 'src/theme/iconify/iconify';

export default function MenuToolbarChildSelect() {
  const { persons } = usePersonsContext();
  const theme = useTheme();

  const {
    state: {
      current: { person: currentPerson },
    },
    setCurrentPerson,
    setCurrentDay,
  } = useMenuContext();

  useEffect(() => {
    if (persons.length === 0) return;

    const activePerson = persons.find((person) => person.can_order);

    if (!activePerson) return;

    if (activePerson.type === 'child') {
      setCurrentPerson({
        uuid: activePerson.child.uuid,
        type: activePerson.type,
      });
    } else {
      setCurrentPerson({
        uuid: activePerson.customer.uuid,
        type: activePerson.type,
      });
    }
  }, [persons, setCurrentPerson]);

  const allOrdersCompleted = useMemo(() => {
    return (
      persons.filter((person) => !person.can_order && person.has_menu)
        .length === persons.length
    );
  }, [persons]);

  const handleChange = (event: SelectChangeEvent) => {
    if (!event.target.value) return;

    const person = persons.find((p) =>
      p.type === 'child'
        ? p.child.uuid === event.target.value
        : p.customer.uuid === event.target.value,
    );
    if (!person) return;
    setCurrentDay('');
    setCurrentPerson({ uuid: event.target.value, type: person.type });
  };

  if (!currentPerson) {
    return (
      <Box sx={{ pt: 3, pb: 2, px: { xs: 1, md: 4 } }}>
        {allOrdersCompleted ? (
          <Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
              <Iconify
                icon="material-symbols:check"
                width={40}
                color="primary.contrastText"
                bgcolor="primary.main"
                borderRadius="100%"
                p={0.5}
              />
            </Box>

            <Typography variant="subtitle1" sx={{ textAlign: 'center' }}>
              Posiłki dla wszystkich zostały już zamówione, dobra robota! :)
            </Typography>
          </Box>
        ) : (
          <Typography variant="subtitle1" sx={{ textAlign: 'center' }}>
            Brak możliwości zamówienia na dany miesiąc
          </Typography>
        )}
      </Box>
    );
  }

  if (persons.length === 1) {
    return (
      <Typography
        variant="subtitle2"
        fontStyle="italic"
        color="GrayText"
        sx={{ pl: 2, pb: 1, pt: 2 }}
      >
        {`Zamawiasz posiłki dla ${
          persons[0].type === 'child'
            ? `${persons[0].child.first_name} ${persons[0].child.first_name}`
            : `${persons[0].customer.first_name} ${persons[0].customer.last_name}`
        }`}
      </Typography>
    );
  }

  return (
    <Stack
      alignItems="center"
      direction={{ xs: 'column', md: 'row' }}
      sx={{ pl: 2, pb: 2, pr: { xs: 2.5, md: 1 }, pt: 3 }}
      gap={3}
    >
      <Box sx={{ flexShrink: 0, width: { xs: 1, md: '30%' } }}>
        <FormControl sx={{ minWidth: 150, width: { xs: 1, md: '100%' } }}>
          <InputLabel>Wybierz dla kogo chcesz zamówić posiłek</InputLabel>
          <Select
            size="small"
            label="Wybierz dla kogo chcesz zamówić posiłek"
            value={currentPerson.uuid}
            onChange={handleChange}
            sx={{ pt: 0.5 }}
          >
            {persons.map((person) => (
              <MenuItem
                key={
                  person.type === 'child'
                    ? person.child.uuid
                    : person.customer.uuid
                }
                value={
                  person.type === 'child'
                    ? person.child.uuid
                    : person.customer.uuid
                }
                disabled={!person.can_order}
              >
                <Box display="flex" alignItems="center">
                  <Avatar
                    sx={{
                      width: 30,
                      height: 30,
                      mr: 2,
                      bgcolor: theme.palette.primary.dark,
                    }}
                  >
                    {person.type === 'child'
                      ? person.child.first_name?.[0]
                      : person.customer.first_name?.[0]}
                  </Avatar>

                  <Stack>
                    <Box display="flex" gap={1}>
                      <Typography variant="subtitle2">
                        {person.type === 'child'
                          ? `${person.child.first_name} ${person.child.last_name}`
                          : `${person.customer.first_name} ${person.customer.last_name}`}
                      </Typography>
                      {/* <Typography variant="body2">
                          {`(${child.child.date_of_birth})`}
                        </Typography> */}
                      {person.has_menu && !person.can_order && (
                        <Label color="primary">Zamówione</Label>
                      )}
                      {!person.has_menu && (
                        <Label color="warning">Brak menu</Label>
                      )}
                    </Box>
                    {/* <Typography
                        color={theme.palette.text.secondary}
                        variant="body2"
                      >
                        {child.child.institution.name}
                      </Typography>
                      <Typography
                        color={theme.palette.text.secondary}
                        variant="body2"
                      >
                        {child.child.institution_place.name}
                      </Typography> */}
                  </Stack>
                </Box>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Typography
        variant="body2"
        color="text.secondary"
        sx={{ width: { lg: '50%' } }}
      >
        Możesz przełączać się swobodnie między dziećmi/pracownikami w trakcie
        wyboru menu dla każdego z nich, wszystkie posiłki zostaną złożone w
        ramach jednego zamówienia
      </Typography>
    </Stack>
  );
}
