import { ActionMapType } from 'src/types/common';
import { MenuStatePerson, MenuStateType } from './types';

export enum Types {
  SET_CURRENT_DAY = 'SET_CURRENT_DAY',
  SET_CURRENT_PERSON = 'SET_CURRENT_PERSON',
}

type Payload = {
  [Types.SET_CURRENT_DAY]: {
    day: string;
  };
  [Types.SET_CURRENT_PERSON]: {
    person: MenuStatePerson | null;
  };
};

export type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

export const initialState: MenuStateType = {
  current: {
    day: '',
    month: '',
    person: null,
  },
};

export const reducer = (state: MenuStateType, action: ActionsType) => {
  if (action.type === Types.SET_CURRENT_DAY) {
    return {
      ...state,
      current: {
        ...state.current,
        day: action.payload.day,
      },
    };
  }

  if (action.type === Types.SET_CURRENT_PERSON) {
    return {
      ...state,
      current: {
        ...state.current,
        person: action.payload.person,
      },
    };
  }

  return state;
};
