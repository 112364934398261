import CustomHelmet from 'src/components/CustomHelmet';
import BalanceView from 'src/views/parentPanel/Balance';

export default function BalancePage() {
  return (
    <>
      <CustomHelmet title="Balans" />
      <BalanceView />
    </>
  );
}
