import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Container } from '@mui/material';
import CustomBreadcrumbs from 'src/components/helpers/custom-breadcrumbs';
import { paths } from 'src/routes/paths';
import { useParams } from 'react-router-dom';
import { getOrderDateFormatted } from 'src/utils/date';
import { capitalizeFirstLetter } from 'src/utils/format';
import Menu from './Menu';
import Steps from './Steps';

export default function NewOrderMonthView() {
  const { month } = useParams();
  const formattedMonth = month
    ? capitalizeFirstLetter(getOrderDateFormatted(new Date(month)))
    : undefined;

  return (
    <Container maxWidth="lg">
      <CustomBreadcrumbs
        // heading="Nowe zamówienie"
        // subheading={formattedMonth}
        links={[
          { name: 'Panel zamawiającego', href: paths.parentPanel.main },
          {
            name: `Nowe zamówienie (${formattedMonth})`,
            href: paths.parentPanel.newOrderChooseMonth,
          },
        ]}
        sx={{ mb: 3 }}
      />
      <Steps step="menu" />
      <Menu />
    </Container>
  );
}
