import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

export function LeftIcon() {
  return (
    <KeyboardArrowLeftIcon
      sx={{
        height: 50,
        width: 50,
      }}
    />
  );
}

export function RightIcon() {
  return (
    <KeyboardArrowRightIcon
      sx={{
        height: 50,
        width: 50,
      }}
    />
  );
}
