import { Navigate, useParams, useRoutes } from 'react-router-dom';

import MaintenancePage from 'src/pages/Maintenance';
import { AuthCompactLayout } from 'src/layouts/auth';
import { errorRoutes } from './error';
import { customerRoutes } from './parentPanel';
import { restauratorRoutes } from './restauratorPanel';
import { authRoutes } from './auth';

function RedirectParentToCustomer() {
  const { '*': subpath } = useParams(); // Capture the entire subpath
  return <Navigate to={`/customer/${subpath}`} replace />;
}

function RedirectSchoolToInvitation() {
  const { '*': subpath } = useParams(); // Capture the entire subpath
  return <Navigate to={`/zaproszenie/${subpath}`} replace />;
}

export default function Router() {
  return useRoutes([
    // Auth routes
    ...authRoutes,

    // Parent panel routes
    ...customerRoutes,

    // Restaurator panel routes
    ...restauratorRoutes,

    // Error routes
    ...errorRoutes,

    {
      path: '/maintenance',
      element: (
        <AuthCompactLayout disabledLogoLink>
          <MaintenancePage />
        </AuthCompactLayout>
      ),
    },

    // OLD urls
    { path: '/szkola/*', element: <RedirectSchoolToInvitation /> },
    { path: '/parent/*', element: <RedirectParentToCustomer /> },

    // TODO: handle logged in user to redirect to the right panel
    { path: '/', element: <Navigate to="/customer" replace /> },
    // No match 404
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
