import { ActionMapType, AuthStateType, AuthUserType } from './types';

export enum Types {
  INITIAL = 'INITIAL',
  SET_USER = 'SET_USER',
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER',
  LOGOUT = 'LOGOUT',
}

type Payload = {
  [Types.INITIAL]: {
    user: AuthUserType | null;
  };
  [Types.SET_USER]: {
    user: AuthUserType | null;
  };
  [Types.LOGIN]: {
    user: AuthUserType | null;
  };
  [Types.REGISTER]: {
    user: AuthUserType | null;
  };
  [Types.LOGOUT]: undefined;
};

export type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

export const initialState: AuthStateType = {
  user: null,
  loading: true,
};

export const reducer = (state: AuthStateType, action: ActionsType) => {
  if (action.type === Types.INITIAL) {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === Types.SET_USER) {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGIN) {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === Types.REGISTER) {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGOUT) {
    return {
      ...state,
      user: null,
    };
  }
  return state;
};
