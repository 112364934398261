import { View, Text, StyleSheet } from '@react-pdf/renderer';
import { format } from 'date-fns';
import { useMemo } from 'react';

const WeekdaysHeader = () => {
  const weekdays = useMemo(() => {
    const tmpWeekdays = [];

    for (let i = 1; i <= 7; i += 1) {
      const tmpDate = new Date(2024, 0, i);
      const weekdayName = format(tmpDate, 'eeee');
      tmpWeekdays.push(weekdayName);
    }
    return tmpWeekdays;
  }, []);

  return (
    <View style={styles.weekdayContainer}>
      {weekdays.map((day) => (
        <View key={day} style={styles.weekday}>
          <Text style={styles.weekdayName}>{day}</Text>
        </View>
      ))}
    </View>
  );
};

export default WeekdaysHeader;

const styles = StyleSheet.create({
  weekdayContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 2,
    paddingTop: 5,
  },
  weekday: {
    width: '14%',
    padding: 5,
    border: '1px solid #333333',
    backgroundColor: '#cccccc',
  },
  weekdayName: {
    fontSize: 9,
    fontWeight: 700,
    textTransform: 'capitalize',
  },
});
