import { useEffect, useReducer, useCallback, useMemo } from 'react';
import axios, { endpoints } from 'src/utils/axios';
import { AuthContext } from './auth-context';
import { setRestaurantGuid, setSession } from './utils';
import { initialState, reducer, Types } from './reducer';
import {
  AuthUserType,
  ImpersonateData,
  LoginParams,
  RegisterParams,
  SetLoginRHFError,
  SetRegisterRHFError,
} from './types';
import { loginRequest, registerRequest } from './actions';
// import { loginRequest } from './actions';

const STORAGE_KEY = 'accessToken';

type Props = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: Props) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(async () => {
    try {
      const accessToken = localStorage.getItem(STORAGE_KEY);

      if (accessToken) {
        setSession(accessToken);

        const res = await axios.get(endpoints.auth.me);

        const { data } = res.data;

        if (data?.restaurant) {
          setRestaurantGuid(data.restaurant.guid);
        }

        dispatch({
          type: Types.INITIAL,
          payload: {
            user: data,
          },
        });
      } else {
        dispatch({
          type: Types.INITIAL,
          payload: {
            user: null,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: Types.INITIAL,
        payload: {
          user: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const refetchUser = useCallback(async () => {
    try {
      const res = await axios.get(endpoints.auth.me);

      const { data } = res.data;

      if (data?.restaurant) {
        setRestaurantGuid(data.restaurant.guid);
      }

      dispatch({
        type: Types.SET_USER,
        payload: {
          user: data,
        },
      });
      return true;
    } catch (error) {
      dispatch({
        type: Types.SET_USER,
        payload: {
          user: null,
        },
      });
      return false;
    }
  }, []);

  // LOGIN
  const login = useCallback(
    async (data: LoginParams, setRHFError: SetLoginRHFError) => {
      const res = await loginRequest(data, setRHFError);

      if (res) {
        const { accessToken, user } = res;

        setSession(accessToken);

        if (user?.restaurant) {
          setRestaurantGuid(user.restaurant.guid);
        }

        await refetchUser();
      }
    },
    [refetchUser],
  );

  // REGISTER
  const register = useCallback(
    async (
      data: RegisterParams,
      setRHFError: SetRegisterRHFError,
    ): Promise<AuthUserType | null> => {
      const res = await registerRequest(data, setRHFError);

      if (res) {
        const { accessToken, user } = res;

        setSession(accessToken);

        if (user?.restaurant) {
          setRestaurantGuid(user.restaurant.guid);
        }

        dispatch({
          type: Types.REGISTER,
          payload: { user },
        });

        return user;
      }

      return null;
    },
    [],
  );

  // LOGOUT
  const logout = useCallback(async () => {
    try {
      await axios.post(endpoints.auth.logout, null);
      setSession(null);
      setRestaurantGuid(null);
      dispatch({
        type: Types.LOGOUT,
      });
    } catch (error) {
      setSession(null);
      setRestaurantGuid(null);
      dispatch({
        type: Types.LOGOUT,
      });
    }
  }, []);

  // IMPERSONATE
  const impersonate = useCallback(
    async (data: ImpersonateData): Promise<boolean> => {
      setSession(data.accessToken);
      const success = await refetchUser();
      return success;
    },
    [refetchUser],
  );

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'jwt',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      login,
      refetchUser,
      register,
      logout,
      impersonate,
    }),
    [login, logout, register, refetchUser, impersonate, state.user, status],
  );

  return (
    <AuthContext.Provider value={memoizedValue}>
      {children}
    </AuthContext.Provider>
  );
}
