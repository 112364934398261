import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { Theme, SxProps } from '@mui/material/styles';
import Logo from 'src/components/helpers/logo';
import { Stack } from '@mui/material';
import Loader from 'src/components/helpers/loader';
import { useAuthContext } from 'src/providers/auth';
import Footer from '../_common/Footer';
// import { HeaderSimple } from '../_common';

type Props = {
  disabledLogoLink?: boolean;
  children: React.ReactNode;
  cardSx?: SxProps<Theme>;
};

export default function AuthCompactLayout({
  disabledLogoLink,
  children,
  cardSx = { maxWidth: 620 },
}: Props) {
  const { loading } = useAuthContext();

  if (loading) return <Loader />;

  return (
    <>
      {/* <HeaderSimple /> */}

      <Box
        component="main"
        sx={{
          py: 6,
          display: 'flex',
          minHeight: '100vh',
          textAlign: 'center',
          px: { xs: 2, md: 0 },
          position: 'relative',
          alignItems: 'center',
          justifyContent: 'center',
          '&:before': {
            width: 1,
            height: 1,
            zIndex: -1,
            content: "''",
            opacity: 0.24,
            position: 'absolute',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            backgroundImage: 'url(/assets/background/overlay_4.jpg)',
          },
        }}
      >
        <Stack alignItems="center" spacing={4}>
          <Logo size={200} disabledLink={disabledLogoLink} />
          <Card
            sx={{
              p: 3,
              marginTop: 3,
              ...cardSx,
            }}
          >
            {children}
          </Card>
          <Footer />
        </Stack>
      </Box>
    </>
  );
}
