import { Box } from '@mui/material';
import { MenuTabs } from './menu-tabs';
import MenuCategorySections from './menu-category-sections';
import Caption from './Caption';

export default function MenuData() {
  return (
    <Box paddingBottom={2} marginTop={1}>
      <MenuTabs />
      <Caption />
      <MenuCategorySections />
    </Box>
  );
}
