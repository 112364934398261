import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {
  Box,
  Card,
  CircularProgress,
  Container,
  Stack,
  Typography,
} from '@mui/material';
import CustomBreadcrumbs from 'src/components/helpers/custom-breadcrumbs';
import { paths } from 'src/routes/paths';
import { useMemo } from 'react';
import { addMonths } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import MonthSelect from '../ChildrenMenu/MonthSelect';
import useOrder from '../../../hooks/useOrder';
import NoChildMessage from '../ParentPanel/Alerts/NoChildMessage';

export default function NewOrderView() {
  const navigate = useNavigate();
  const today = useMemo(() => {
    return new Date();
  }, []);

  const tomorrow = useMemo(() => {
    return addMonths(new Date(), 1);
  }, []);

  const {
    monthFormatted: currentMonthFormatted,
    monthFullDateFormatted: currentMonthFullDateFormatted,
    monthOrderable: currentMonthOrderable,
    monthAllOrdersCompleted: currentMonthAllOrdersCompleted,
    isLoading: currentMonthLoading,
  } = useOrder(today);

  const {
    monthFormatted: nextMonthFormatted,
    monthFullDateFormatted: nextMonthFullDateFormatted,
    monthOrderable: nextMonthOrderable,
    monthAllOrdersCompleted: nextMonthAllOrdersCompleted,
    isLoading: nextMonthLoading,
  } = useOrder(tomorrow);

  const handleMonthChange = (month: string) => {
    navigate(paths.parentPanel.newOrder(month));
  };

  const canOrder = useMemo(() => {
    return currentMonthOrderable.length > 0 || nextMonthOrderable.length > 0;
  }, [currentMonthOrderable, nextMonthOrderable]);

  const loading = currentMonthLoading || nextMonthLoading;

  return (
    <Container maxWidth="lg">
      <CustomBreadcrumbs
        heading="Nowe zamówienie"
        links={[
          { name: 'Panel zamawiającego', href: paths.parentPanel.main },
          { name: 'Nowe zamówienie' },
        ]}
        sx={{ mb: 3 }}
      />
      <Card sx={{ p: 3 }}>
        {loading ? (
          <CircularProgress />
        ) : (
          <Box>
            {canOrder ? (
              <Box sx={{ width: { md: '50%' } }}>
                <Typography variant="subtitle1" sx={{ mb: 2 }}>
                  Wybierz na jaki miesiąc chcesz złożyć zamówienie:
                </Typography>
                <MonthSelect
                  options={[
                    {
                      value: currentMonthFormatted,
                      label: `${currentMonthFullDateFormatted} (bieżący)`,
                      disabled: currentMonthOrderable.length === 0,
                      disabledReason: currentMonthAllOrdersCompleted
                        ? 'Wszystko zamówione'
                        : 'Brak możliwości zamówienia',
                      disabledReasonColor: nextMonthAllOrdersCompleted
                        ? 'success'
                        : 'warning',
                    },
                    {
                      value: nextMonthFormatted,
                      label: `${nextMonthFullDateFormatted} (przyszły)`,
                      disabled: nextMonthOrderable.length === 0,
                      disabledReason: nextMonthAllOrdersCompleted
                        ? 'Wszystko zamówione'
                        : 'Brak możliwości zamówienia',
                      disabledReasonColor: nextMonthAllOrdersCompleted
                        ? 'success'
                        : 'warning',
                    },
                  ]}
                  value=""
                  onChange={handleMonthChange}
                />
              </Box>
            ) : (
              <Stack spacing={2} sx={{ width: '100%' }}>
                <Typography variant="h6">
                  Brak możliwości złożenia zamówienia
                </Typography>
                <NoChildMessage />
              </Stack>
            )}
          </Box>
        )}
      </Card>
    </Container>
  );
}
