import { AuthUserType } from 'src/providers/auth/types';
import { InstitutionType } from 'src/types/restaurant/institution';

/**
 * Checks if a user has a specific institution type.
 * @param user - The user object.
 * @param institutionType - The institution type to check.
 * @returns A boolean indicating whether the user has the specified institution type.
 */
export const hasInstitutionType = (
  user: AuthUserType,
  institutionType: InstitutionType,
): boolean => {
  if (!user || !user.institutions) return false;
  return user.institutions.some(
    (institution) => institution.type === institutionType,
  );
};
