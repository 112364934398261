import CustomHelmet from 'src/components/CustomHelmet';
import ChildrenMenuView from 'src/views/parentPanel/ChildrenMenu';

export default function ChildrenMenu() {
  return (
    <>
      <CustomHelmet title="Zamówione posiłki" />
      <ChildrenMenuView />
    </>
  );
}
