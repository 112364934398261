import { useContext, useCallback, useMemo, createContext } from 'react';
import Loader from 'src/components/helpers/loader/loader';
import { Container } from '@mui/material';
import useMenuAvailability from 'src/hooks/useMenuAvailability';
import { useMenuContext } from '../menu';
import { PersonsContextProps } from '../menu/types';

export const PersonsContext = createContext({} as PersonsContextProps);

type PersonsProviderProps = {
  children: React.ReactNode;
};

export function PersonsProvider({
  children: childrenNodes,
}: PersonsProviderProps) {
  const {
    state: {
      current: { month },
    },
  } = useMenuContext();

  const { data, isLoading } = useMenuAvailability(
    month ? new Date(month) : new Date(),
  );

  const getPerson = useCallback(
    (personUuid: string) => {
      const person = data.find((personItem) =>
        personItem.type === 'child'
          ? personItem.child.uuid === personUuid
          : personItem.customer.uuid === personUuid,
      );
      if (person) {
        return person;
      }
      return null;
    },
    [data],
  );

  const memoizedValue = useMemo(
    () => ({
      persons: data ?? [],
      getPerson,
    }),
    [data, getPerson],
  );

  if (isLoading) {
    return (
      <Container maxWidth="lg">
        <Loader />
      </Container>
    );
  }

  return (
    <PersonsContext.Provider value={memoizedValue}>
      {childrenNodes}
    </PersonsContext.Provider>
  );
}

export const usePersonsContext = () => {
  const context = useContext(PersonsContext);

  if (!context)
    throw new Error('usePersonsContext must be use inside PersonsProvider');

  return context;
};
