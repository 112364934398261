// @ts-ignore
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import { useEffect, useRef, useState } from 'react';
import { useAuthContext } from 'src/providers/auth';
import { compact } from 'lodash';
import { usePathname } from '../hooks';

const Chat = () => {
  const pathname = usePathname();
  const { user } = useAuthContext();

  const tawkMessengerRef = useRef<{
    maximize: () => void;
    isVisitorEngaged: () => boolean;
    minimize: () => void;
    hideWidget: () => void;
    setAttributes: (attributes: Record<string, string>) => void;
  }>();

  const [loaded, setLoaded] = useState(false);

  const onLoad = () => {
    setLoaded(true);
  };

  useEffect(() => {
    if (!loaded) return;
    if (!user) return;
    if (!user.tawk_to) return;
    if (user.impersonating_user?.uuid) return;
    try {
      // @ts-ignore
      tawkMessengerRef?.current?.setAttributes({
        'user-id': user.uuid,
        email: user.email,
        name: compact([user.first_name, user.last_name]).join(' ') || '',
        'group-uuids': user.institutions
          .map((institution) => institution.uuid)
          .join(','),
        'group-names': user.institutions
          .map((institution) => institution.name)
          .join(','),
        hash: user.tawk_to.hash,
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  }, [user, loaded]);

  useEffect(() => {
    if (!loaded) return;

    try {
      if (pathname === '/customer/contact') {
        tawkMessengerRef?.current?.maximize();
        // } else if (!tawkMessengerRef?.current?.isVisitorEngaged()) {
      } else {
        tawkMessengerRef?.current?.minimize();
        tawkMessengerRef?.current?.hideWidget();
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  }, [loaded, pathname]);

  if (!user?.tawk_to) return null;

  return (
    <TawkMessengerReact
      propertyId={user.tawk_to.property_id}
      widgetId={user.tawk_to.widget_id}
      onLoad={onLoad}
      ref={tawkMessengerRef}
    />
  );
};

export default Chat;
