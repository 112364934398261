/* eslint-disable react-hooks/exhaustive-deps */
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {
  Badge,
  Box,
  CardHeader,
  CircularProgress,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@mui/material';
import useSWR from 'swr';
import { ApiResponse } from 'src/types/common';
import { PersonMenuResponse } from 'src/providers/children/types';
import { endpoints } from 'src/utils/axios';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { cancelMeal } from 'src/providers/children/actions';
import { useAuthContext } from 'src/providers/auth';
import { endOfDay, isFuture, isPast, isToday } from 'date-fns';
import { PdfCalendarData } from 'src/types/parent/menu';
import { PersonMenuAvailability } from 'src/providers/menu/types';
import MealCards from './MealCards';
import MealCancelButton from './MealCancelButton';
import { DownloadPdfCalendar } from './DownloadPdfCalendar';

type MenuProps = {
  month: string;
  person: PersonMenuAvailability;
};

const Menu = ({ month, person }: MenuProps) => {
  const { refetchUser } = useAuthContext();
  const [day, setDay] = useState('');

  // TODO: move this to container component (need to create one)
  // TODO: and second for emploee case
  const {
    data: personMenuData,
    isLoading,
    mutate,
  } = useSWR<ApiResponse<PersonMenuResponse>>(
    // eslint-disable-next-line no-nested-ternary
    person && month
      ? person.type === 'child'
        ? endpoints.customer.getChildMenuByMonths(
            person.child.uuid,
            month,
            month,
          )
        : endpoints.customer.getCustomerMenuByMonths(month, month)
      : null,
  );

  const menu = useMemo(() => {
    return personMenuData?.data || null;
  }, [personMenuData?.data]);

  const handleChangeDayTab = useCallback(
    (event: React.SyntheticEvent, newDay: string) => {
      setDay(newDay);
    },
    [setDay],
  );

  const currentDayMenu = useMemo(() => {
    return menu?.menu.find((m) => m.date.full === day);
  }, [day, menu]);

  useEffect(() => {
    if (menu) {
      const firstAvailableDay = menu.menu.find((dayItem) => {
        const isFutureOrToday =
          isToday(new Date(dayItem.date.full)) ||
          isFuture(new Date(dayItem.date.full));
        const hasMeals = dayItem.meals && dayItem.meals.length > 0;

        return isFutureOrToday && hasMeals;
      });
      if (firstAvailableDay) {
        setDay(firstAvailableDay.date.full);
      } else {
        setDay('');
      }
    }
  }, [menu, setDay]);

  const getCurrentChildMenuPerDayForCategory = useCallback(
    (categoryName: string) =>
      currentDayMenu?.meals?.filter(
        (item) => item.meal_category.name === categoryName,
      ),
    [currentDayMenu?.meals],
  );

  const handleCancelMeals = async () => {
    const res = await cancelMeal(
      person.type === 'child' ? person.child.uuid : null,
      { date: day },
    );
    if (!res) return;
    mutate();
    refetchUser();
  };

  const canCancelDay = useMemo(() => {
    if (!currentDayMenu) return false;
    // .every on empty array return always true, so we need to check empty array case separately
    if (currentDayMenu.meals.length === 0) return false;
    return currentDayMenu.meals.every((meal) => meal.is_cancellable);
  }, [currentDayMenu]);

  const calendarData: PdfCalendarData = useMemo(() => {
    const menuData: PdfCalendarData = {};
    menu?.menu?.forEach((menuItem) => {
      menuData[menuItem.date.full] = menuItem.meals.map(
        (meal) => meal.mealable.name,
      );
    });
    return menuData;
  }, [menu?.menu]);

  return (
    <>
      <Tabs
        value={day}
        onChange={handleChangeDayTab}
        sx={{ maxWidth: '100%', px: { xs: 2, md: 0 }, pt: 3, mb: 2 }}
        TabScrollButtonProps={{
          sx: {
            '& .css-egnern-MuiSvgIcon-root': {
              height: 50,
              width: 50,
              color: 'primary.dark',
            },
          },
        }}
        TabIndicatorProps={{
          sx: {
            height: '3px',
            backgroundColor: 'primary.main',
          },
        }}
        allowScrollButtonsMobile={false}
      >
        {menu?.menu.map(({ meals, date }) => {
          const hasMeals = meals && meals.length > 0;
          const formattedUnavilaibilityReason = !hasMeals
            ? 'Brak zamówienia na wybrany dzień'
            : null;

          return (
            <Tab
              key={date.full}
              value={date.full}
              sx={{
                opacity: isPast(new Date(date.full)) ? 0.48 : 1,
                textDecoration: 'none',
                '&.Mui-disabled': {
                  opacity: isPast(endOfDay(new Date(date.full))) ? 0.2 : 0.48,
                  pointerEvents: 'auto',
                },
              }}
              disabled={!hasMeals}
              icon={
                <Tooltip
                  title={formattedUnavilaibilityReason || false}
                  placement="top"
                  arrow
                >
                  <Stack>
                    <Box sx={{ pt: 1 }}>
                      <Typography
                        variant="h3"
                        color={
                          day === date.full ? 'primary.main' : 'text.primary'
                        }
                      >
                        <Badge
                          badgeContent=" "
                          variant="dot"
                          color="secondary"
                          invisible={!isToday(new Date(date.full))}
                        >
                          {date.day}
                        </Badge>
                      </Typography>
                    </Box>
                    <Box paddingBottom={0.5}>
                      <Typography variant="subtitle1" color="text.secondary">
                        {date.day_full_name}
                      </Typography>
                    </Box>
                  </Stack>
                </Tooltip>
              }
            />
          );
        })}
      </Tabs>
      <Stack
        spacing={2}
        direction="row"
        flexWrap="wrap"
        alignItems="center"
        justifyContent="flex-end"
        sx={{ mr: 4 }}
      >
        <Typography variant="caption" color="GrayText">
          Oznaczenia:
        </Typography>
        <Stack
          spacing={1}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Badge badgeContent=" " variant="dot" color="secondary" />
          <Typography variant="caption" color="GrayText">
            Dzisiaj
          </Typography>
        </Stack>
      </Stack>
      {/* loading menu state */}
      {isLoading && (
        <Box sx={{ pt: 3, display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      )}
      {/* we automatically select future date with menu, if there is no date selected,
       display "no future meals" message */}
      {!isLoading && !day && (
        <Box sx={{ pt: 5, pb: 2, display: 'flex', justifyContent: 'center' }}>
          <Typography variant="h6">Brak nadchodzących posiłków</Typography>
        </Box>
      )}
      {/* user can cancel meals for the day, display cancel button */}
      {currentDayMenu && canCancelDay && (
        <Box sx={{ pt: { xs: 3, lg: 5 }, px: { xs: 2, lg: 5 } }}>
          <MealCancelButton onCancel={handleCancelMeals} />
        </Box>
      )}
      {/* there is a menu for current day, render menu */}
      {menu?.meal_categories_priority && currentDayMenu && (
        <Box key={currentDayMenu?.date?.full} sx={{ px: { xs: 0, md: 4 } }}>
          {Object.entries(menu?.meal_categories_priority).map(
            ([categoryId, category]) => {
              const dataForSlider =
                getCurrentChildMenuPerDayForCategory(category);

              if (dataForSlider?.length) {
                return (
                  <Box key={categoryId}>
                    <CardHeader title={category} />
                    <Box position="relative">
                      <MealCards data={dataForSlider} />
                    </Box>
                  </Box>
                );
              }
              return null;
            },
          )}
        </Box>
      )}
      <Stack flexDirection="row" justifyContent="flex-end" sx={{ pt: 2 }}>
        {Object.keys(calendarData).length > 0 && (
          <DownloadPdfCalendar
            month={month}
            person={person}
            calendarData={calendarData}
          />
        )}
      </Stack>
    </>
  );
};

export default Menu;
