import { Box, CardHeader } from '@mui/material';
import { usePersonsMenuContext } from 'src/providers/children-menu';
import { useMenuContext } from 'src/providers/menu';
import { useCallback, useMemo } from 'react';
import { CarouselMealCards } from './carousel';

export default function MenuCategorySections() {
  const {
    state: {
      current: { day, person, month },
    },
  } = useMenuContext();
  const { childrenMenu } = usePersonsMenuContext();

  const menuCategories = useMemo(
    () =>
      person
        ? childrenMenu[person.uuid]?.[month]?.meal_categories_priority
        : {},
    [person, childrenMenu, month],
  );

  const currentChildMenuPerDay = useMemo(
    () =>
      person
        ? childrenMenu[person.uuid]?.[month]?.menu.find(
            (item) => item.date.full === day,
          )
        : null,
    [person, childrenMenu, day, month],
  );

  const getCurrentChildMenuPerDayForCategory = useCallback(
    (categoryName: string) =>
      currentChildMenuPerDay?.mealables?.filter(
        (item) => item.meal_category.name === categoryName,
      ),
    [currentChildMenuPerDay?.mealables],
  );

  if (!currentChildMenuPerDay) return null;

  return (
    <Box key={currentChildMenuPerDay?.date?.full}>
      {Object.entries(menuCategories).map(([categoryId, category]) => {
        const dataForSlider = getCurrentChildMenuPerDayForCategory(category);

        if (dataForSlider?.length) {
          return (
            <Box key={categoryId} sx={{ px: { md: 4, py: 2 } }}>
              <CardHeader title={category} />
              <Box position="relative">
                <CarouselMealCards
                  date={currentChildMenuPerDay.date}
                  data={dataForSlider}
                />
              </Box>
            </Box>
          );
        }
        return null;
      })}
    </Box>
  );
}
