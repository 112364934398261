import {
  Box,
  Button,
  Card,
  CardActionArea,
  CircularProgress,
  Container,
  Stack,
  Typography,
  Link as MuiLink,
} from '@mui/material';
import { useCallback, useEffect, useMemo } from 'react';
import CustomBreadcrumbs from 'src/components/helpers/custom-breadcrumbs';
import { useCartContext } from 'src/providers/cart';
import { AvailablePaymentMethod } from 'src/providers/cart/types';
import { useMenuContext } from 'src/providers/menu';
import { capitalizeFirstLetter } from 'src/utils/format';
import { getOrderDateFormatted } from 'src/utils/date';
import { paths } from 'src/routes/paths';
import { Link } from 'react-router-dom';
import Iconify from 'src/theme/iconify';
import { LoadingButton } from '@mui/lab';
import { useRouter } from 'src/routes/hooks';
import Steps from './Steps';

export default function SummaryView() {
  const router = useRouter();

  const {
    state: {
      current: { month },
    },
  } = useMenuContext();

  const { cart, balance, loading, setPaymentMethod, orderLoading, placeOrder } =
    useCartContext();

  const activePaymentMethod = useMemo(() => {
    return cart?.payment_method?.code || null;
  }, [cart?.payment_method?.code]);

  const selectPaymentMethod = useCallback(
    async (code: AvailablePaymentMethod['code']) => {
      // same method as selected, nothing to do
      if (code === activePaymentMethod) return;
      await setPaymentMethod({ code, date: month });
    },
    [setPaymentMethod, month, activePaymentMethod],
  );

  // select first payment method by default
  useEffect(() => {
    if (!cart) return;
    // no payment methods, skip
    if (!cart.available_payment_methods) return;
    if (cart.available_payment_methods.length === 0) return;
    // payment method already set, skip
    if (cart.payment_method) return;
    if (cart.items?.length === 0) return;

    const firstPaymentMethod = cart?.available_payment_methods[0];
    selectPaymentMethod(firstPaymentMethod.code);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    cart?.available_payment_methods,
    cart?.payment_method,
    selectPaymentMethod,
  ]);

  useEffect(() => {
    if (!cart) return;
    if (cart.items.length === 0) {
      router.push(paths.parentPanel.newOrderChooseMonth);
    }
  }, [cart, router]);

  const formattedMonth = month
    ? capitalizeFirstLetter(getOrderDateFormatted(new Date(month)))
    : undefined;

  return (
    <Container maxWidth="lg">
      <CustomBreadcrumbs
        heading="Nowe zamówienie"
        subheading={formattedMonth}
        links={[
          { name: 'Panel zamawiającego', href: paths.parentPanel.main },
          {
            name: 'Nowe zamówienie',
            href: paths.parentPanel.newOrderChooseMonth,
          },
        ]}
        sx={{ mb: 3 }}
      />
      <Steps step="summary" />
      <Box sx={{ maxWidth: '500px', mx: 'auto' }}>
        <Button
          variant="outlined"
          component={Link}
          to={paths.parentPanel.newOrder(month || '')}
          sx={{ mb: 2 }}
        >
          <Iconify icon="formkit:arrowleft" /> Wróć do wyboru menu
        </Button>
        <Card>
          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', py: 2 }}>
              <CircularProgress />
            </Box>
          ) : (
            <Box sx={{ px: { xs: 2, md: 4 }, pt: 2, pb: 3 }}>
              {cart ? (
                <Box>
                  <Stack spacing={1} sx={{ mb: 2 }}>
                    <Card sx={{ p: 3 }}>
                      <Typography variant="h6" sx={{ mb: 2 }}>
                        Podsumowanie zamówienia
                      </Typography>
                      <Stack spacing={1}>
                        <Box>
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            spacing={2}
                          >
                            <Typography>
                              Liczba zamówionych posiłków:
                            </Typography>
                            <Typography>
                              {cart?.items?.reduce((sum, item) => {
                                return sum + item.quantity;
                              }, 0)}
                            </Typography>
                          </Stack>
                        </Box>
                        <Box>
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            spacing={2}
                          >
                            <Typography>Suma zamówienia:</Typography>
                            <Typography>
                              {cart?.original_price.formatted}
                            </Typography>
                          </Stack>
                        </Box>

                        {cart?.discount_amount.value > 0 && (
                          <Box>
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                              spacing={2}
                            >
                              <Typography color="primary.main">
                                Dofinansowanie:
                              </Typography>
                              <Typography color="primary.main">
                                -{cart?.discount_amount.formatted}
                              </Typography>
                            </Stack>
                          </Box>
                        )}
                        {balance &&
                          balance?.balance_amount_to_apply.value > 0 && (
                            <Box>
                              <Stack
                                direction="row"
                                justifyContent="space-between"
                                spacing={2}
                              >
                                <Typography color="primary.main">
                                  Wykorzystane saldo:
                                </Typography>
                                <Typography color="primary.main">
                                  -{balance.balance_amount_to_apply.formatted}
                                </Typography>
                              </Stack>
                            </Box>
                          )}
                      </Stack>
                    </Card>
                    {balance?.final_price.value !== 0 && (
                      <Card sx={{ p: 3 }}>
                        <Typography variant="h6" sx={{ mb: 2 }}>
                          Wybierz metodę płatności
                        </Typography>
                        <Stack spacing={1}>
                          {cart?.available_payment_methods.map((method) => (
                            <Card
                              key={method.code}
                              sx={{
                                border:
                                  method.code === activePaymentMethod
                                    ? '2px solid'
                                    : '',
                                borderColor:
                                  method.code === activePaymentMethod
                                    ? 'success.main'
                                    : 'transparent',
                              }}
                            >
                              <CardActionArea
                                onClick={() => selectPaymentMethod(method.code)}
                                sx={{ p: 2, pr: 2 }}
                              >
                                <Stack
                                  direction="row"
                                  justifyContent="space-between"
                                  alignItems="center"
                                  spacing={2}
                                >
                                  <Stack
                                    direction="row"
                                    spacing={1.5}
                                    alignItems="center"
                                  >
                                    {method.code === activePaymentMethod && (
                                      <Iconify
                                        icon="teenyicons:tick-circle-solid"
                                        color="success.main"
                                      />
                                    )}
                                    <Stack spacing={0.5}>
                                      <Stack
                                        direction="row"
                                        spacing={0.5}
                                        alignItems="center"
                                      >
                                        <Box
                                          component="img"
                                          src="/assets/paymentMethods/blik.svg"
                                          alt=""
                                          sx={{ width: '30px' }}
                                        />

                                        <Iconify icon="ic:baseline-payment" />
                                      </Stack>
                                      <Typography
                                        sx={{ fontWeight: 500, lineHeight: 1 }}
                                      >
                                        {method.name}
                                      </Typography>
                                    </Stack>
                                  </Stack>
                                  <Typography sx={{ flexShrink: 0 }}>
                                    {method.price.formatted}
                                  </Typography>
                                </Stack>
                              </CardActionArea>
                            </Card>
                          ))}
                        </Stack>
                      </Card>
                    )}
                    <Card sx={{ p: 3 }}>
                      <Box>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          spacing={2}
                        >
                          <Typography sx={{ fontWeight: 600 }}>
                            Razem do zapłaty:
                          </Typography>
                          <Typography sx={{ fontWeight: 600 }}>
                            {balance?.final_price.formatted}
                          </Typography>
                        </Stack>
                      </Box>
                    </Card>
                  </Stack>
                  <Stack spacing={1}>
                    <LoadingButton
                      loading={orderLoading}
                      size="large"
                      color="primary"
                      variant="contained"
                      disabled={!cart?.payment_method}
                      onClick={() =>
                        placeOrder(balance?.final_price.value === 0)
                      }
                      fullWidth
                    >
                      {balance?.final_price.value === 0
                        ? 'Zamawiam'
                        : 'Zamawiam z obowiązkiem zapłaty'}
                    </LoadingButton>
                    <Typography variant="caption" color="GrayText">
                      Płacąc, akceptujesz{' '}
                      <MuiLink
                        href="https://data.imoje.pl/docs/imoje_regulamin_platnosci.pdf"
                        target="_blank"
                        rel="noreferrer"
                      >
                        regulamin usługi ING imoje
                      </MuiLink>
                      .
                    </Typography>
                  </Stack>
                </Box>
              ) : (
                <CircularProgress />
              )}
            </Box>
          )}
        </Card>
      </Box>
    </Container>
  );
}
