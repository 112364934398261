import { Box, CircularProgress } from '@mui/material';
import useSWR from 'swr';
import { ApiGetMealResponse } from 'src/providers/institutions/types';
import { endpoints } from 'src/utils/axios';
import MealDetails from '.';

type MealDetailsProps = {
  mealId: string;
};

const MealDetailsFetcher = ({ mealId }: MealDetailsProps) => {
  const { data, isLoading } = useSWR<ApiGetMealResponse>(
    mealId ? endpoints.customer.getMeal(mealId) : null,
  );

  if (isLoading || !data) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }

  return <MealDetails meal={data.data} />;
};

export default MealDetailsFetcher;
