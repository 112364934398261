import { format } from 'date-fns';
import { useMemo } from 'react';
import { getOrderDateFormatted, getOrderMonthFormatted } from 'src/utils/date';
import { capitalizeFirstLetter } from 'src/utils/format';
import useMenuAvailability from './useMenuAvailability';

const useOrder = (date: Date) => {
  const monthFormatted = useMemo(() => {
    return format(date, 'yyyy-MM');
  }, [date]);

  const { data, isLoading } = useMenuAvailability(date);

  const monthOrderable = useMemo(() => {
    return data.filter((person) => person.can_order);
  }, [data]);

  const monthAllOrdersCompleted = useMemo(() => {
    return (
      data.filter((person) => !person.can_order && person.has_menu).length ===
      data.length
    );
  }, [data]);

  const monthNameFormatted = useMemo(() => {
    return capitalizeFirstLetter(
      getOrderMonthFormatted(new Date(monthFormatted)),
    );
  }, [monthFormatted]);

  const monthFullDateFormatted = useMemo(() => {
    return capitalizeFirstLetter(
      getOrderDateFormatted(new Date(monthFormatted)),
    );
  }, [monthFormatted]);

  return {
    monthFormatted,
    monthNameFormatted,
    monthFullDateFormatted,
    monthData: data,
    monthOrderable,
    monthAllOrdersCompleted,
    isLoading,
  };
};

export default useOrder;
