import {
  Container,
  Box,
  CircularProgress,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Stack,
} from '@mui/material';
import CustomBreadcrumbs from 'src/components/helpers/custom-breadcrumbs/custom-breadcrumbs';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';

import TableContainer from '@mui/material/TableContainer';

import {
  TableHeadCustom,
  TablePaginationCustom,
} from 'src/components/helpers/table';
import { endpoints } from 'src/utils/axios';
import { paths } from 'src/routes/paths';
import { BalanceHistoryEntity } from 'src/types/balance';
import Iconify from 'src/theme/iconify';
import { useAuthContext } from 'src/providers/auth';
import { useEffect, useMemo } from 'react';
import EmptyContent from 'src/components/helpers/empty-content';
import { useSWRPagination } from 'src/utils/swr';

const COLUMNS = [
  { id: 'created_at', label: 'Data' },
  { id: 'description', label: 'Opis' },
  { id: 'from_amount', label: 'Saldo przed operacją' },
  { id: 'to_amount', label: 'Saldo po operacji' },
];

export default function BalanceView() {
  const { user, refetchUser } = useAuthContext();

  const {
    table,
    pagination,
    results: balanceHistory,
    isLoading,
  } = useSWRPagination<BalanceHistoryEntity>(endpoints.customer.getBalance);

  useEffect(() => {
    refetchUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const currentBalance = useMemo(() => {
    return user?.balance.amount;
  }, [user]);

  return (
    <Container maxWidth="lg">
      <CustomBreadcrumbs
        heading="Saldo"
        subheading={
          <>
            Sprawdź stan Twojego salda oraz przeglądaj jego historię.
            <br />
            Kwota z salda zostanie wykorzystana każdorazowo na poczet kolejnego
            zamówienia.
          </>
        }
        links={[
          { name: 'Panel zamawiającego', href: paths.parentPanel.main },
          { name: 'Saldo' },
        ]}
        sx={{ mb: 3 }}
      />
      {currentBalance && (
        <Card sx={{ p: { xs: 2, md: 4 }, mb: 2 }}>
          <Stack spacing={2} direction="row" alignItems="center">
            <Iconify width={40} icon="tdesign:money" color="primary.main" />
            <Stack spacing={0}>
              <Typography
                variant="subtitle1"
                color="grey.500"
                sx={{ display: 'flex', alignItem: 'center', gap: 0.5 }}
              >
                Aktualne saldo
              </Typography>
              <Typography
                variant="h5"
                color={currentBalance.value > 0 ? 'primary.main' : undefined}
              >
                {currentBalance.value > 0 ? '+' : ''}
                {currentBalance.formatted}
              </Typography>
            </Stack>
          </Stack>
        </Card>
      )}
      <Card sx={{ p: { xs: 2, md: 4 } }}>
        <Typography variant="h5" sx={{ mb: 3 }}>
          Historia salda
        </Typography>
        <TableContainer>
          <Table size="medium">
            <TableHeadCustom
              // order={table.order}
              // orderBy={table.orderBy}
              headLabel={COLUMNS}
              // rowCount={tableDataLength}
            />

            {isLoading ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  p: 3,
                  width: '100%',
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <TableBody>
                {balanceHistory.length > 0 ? (
                  <>
                    {balanceHistory.map((row) => (
                      <TableRow
                        key={`${row.from_amount.value}${row.to_amount.value}`}
                        hover
                      >
                        <TableCell>{row.created_at.formatted}</TableCell>
                        <TableCell>{row.description}</TableCell>
                        <TableCell>{row.from_amount.formatted}</TableCell>
                        <TableCell>
                          <Typography variant="body2" sx={{ fontWeight: 600 }}>
                            {row.to_amount.formatted}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </>
                ) : (
                  <TableRow>
                    <TableCell colSpan={COLUMNS.length}>
                      <EmptyContent title="Brak wpisów w historii" />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
          <TablePaginationCustom
            count={pagination?.total ?? balanceHistory.length}
            page={table.page - 1}
            rowsPerPage={table.rowsPerPage}
            rowsPerPageOptions={[15]}
            onPageChange={table.onChangePage}
            onRowsPerPageChange={table.onChangeRowsPerPage}
            labelRowsPerPage="Rekordów na stronę"
          />
        </TableContainer>
      </Card>
    </Container>
  );
}
