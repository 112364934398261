import { Navigate } from 'react-router-dom';
import CustomHelmet from 'src/components/CustomHelmet';
import { CartProvider } from 'src/providers/cart';
import { useMenuContext } from 'src/providers/menu';
import { paths } from 'src/routes/paths';
import SummaryView from 'src/views/parentPanel/NewOrder/Month/SummaryView';

export default function Summary() {
  const {
    state: {
      current: { month },
    },
  } = useMenuContext();

  if (!month) {
    return <Navigate to={paths.parentPanel.main} />;
  }

  return (
    <>
      <CustomHelmet title="Nowe zamówienie - podsumowanie" />
      <CartProvider month={month}>
        <SummaryView />
      </CartProvider>
    </>
  );
}
