import { useEffect } from 'react';
import { useRouter } from 'src/routes/hooks';
import { useAuthContext } from 'src/providers/auth';
import { paths } from 'src/routes/paths';
import Loader from 'src/components/helpers/loader/loader';
import { UserType } from 'src/providers/auth/types';

type Props = {
  children: React.ReactNode;
  allowType: UserType;
};

export default function ProtectedRoute({ children, allowType }: Props) {
  const { authenticated, loading, user } = useAuthContext();
  const router = useRouter();
  useEffect(() => {
    if ((!authenticated || user?.type !== allowType) && !loading) {
      router.push(paths.auth.login);
    }
  }, [router, authenticated, loading, user, allowType]);

  if (loading) return <Loader />;

  return <> {children}</>;
}
