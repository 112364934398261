import axios, { endpoints } from 'src/utils/axios';
import { enqueueSnackbar } from 'notistack';
import {
  ApiLoginRequest,
  ApiLoginResponse,
  ApiRegisterRequest,
  ApiRegisterResponse,
  LoginParams,
  LoginRequestResponse,
  RegisterParams,
  RegisterRequestResponse,
  SetLoginRHFError,
  SetRegisterRHFError,
} from './types';

export const loginRequest = async (
  data: LoginParams,
  setRHFError: SetLoginRHFError,
): Promise<LoginRequestResponse | null> => {
  try {
    const res = await axios.post<ApiLoginRequest, ApiLoginResponse>(
      endpoints.auth.login,
      data,
    );

    if (res.data.success) {
      enqueueSnackbar('Pomyślne logowanie', {
        variant: 'success',
      });
    }
    const { token, ...rest } = res.data.data;

    return {
      accessToken: token,
      user: rest,
    };
  } catch (error) {
    switch (error.status) {
      case 401:
        if (error.data.error_message) {
          setRHFError('root', {
            type: 'manual',
            message: error.data.error_message,
          });
        }

        break;
      case 429:
        enqueueSnackbar(error.data.message, { variant: 'error' });
        break;
      case 422:
        if (error.data.data) {
          if (error.data.data.email) {
            setRHFError('email', {
              type: 'manual',
              message: error.data.data.email[0],
            });
          }
          if (error.data.data.password) {
            setRHFError('password', {
              type: 'manual',
              message: error.data.data.password[0],
            });
          }
        }

        break;
      default:
        enqueueSnackbar('Wystąpił błąd', { variant: 'error' });
        break;
    }
    return null;
  }
};

export const registerRequest = async (
  data: RegisterParams,
  setRHFError: SetRegisterRHFError,
): Promise<RegisterRequestResponse | null> => {
  try {
    const res = await axios.post<ApiRegisterRequest, ApiRegisterResponse>(
      endpoints.auth.register,
      data,
    );

    if (res.data.success) {
      enqueueSnackbar('Aktywacja konta przebiegła pomyślnie', {
        variant: 'success',
      });
    }
    const { token, ...rest } = res.data.data;

    return {
      accessToken: token,
      user: rest,
    };
  } catch (error) {
    switch (error.status) {
      case 401:
        enqueueSnackbar(error.data.error_message, { variant: 'error' });
        break;
      case 429:
        enqueueSnackbar(error.data.message, { variant: 'error' });
        break;
      case 422:
        if (error.data.data) {
          if (error.data.data.password) {
            setRHFError('password', {
              type: 'manual',
              message: error.data.data.password[0],
            });
          }
          if (error.data.data.password_confirmation) {
            setRHFError('password', {
              type: 'manual',
              message: error.data.data.password_confirmation[0],
            });
          }
          if (error.data.data.phone_number) {
            setRHFError('phone_number', {
              type: 'manual',
              message: error.data.data.phone_number[0],
            });
          }

          if (error.data.data.token) {
            setRHFError('root', {
              type: 'manual',
              message: error.data.data.token[0],
            });
          }
        }

        break;
      default:
        enqueueSnackbar('Wystąpił błąd', { variant: 'error' });
        break;
    }
    return null;
  }
};
