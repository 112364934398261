import {
  // CircularProgress,
  Step,
  StepLabel,
  Stepper,
} from '@mui/material';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
// import { useCartContext } from 'src/providers/cart';
import { useMenuContext } from 'src/providers/menu';
import { paths } from 'src/routes/paths';

type StepsProps = {
  step: 'menu' | 'pickup' | 'summary' | 'payment';
};

const Steps = ({ step }: StepsProps) => {
  const naviagate = useNavigate();
  // const { cart } = useCartContext();
  const {
    state: {
      current: { month },
    },
  } = useMenuContext();

  const activeStep: number = useMemo(() => {
    if (step === 'menu') return 0;
    if (step === 'pickup') return 1;
    if (step === 'summary') return 1;
    if (step === 'payment') return 2;
    return 0;
  }, [step]);

  // const isPickupLocationActive = cart?.features?.includes(
  //   'order-pickup-location',
  // );

  // if (!cart) {
  //   return <CircularProgress />;
  // }

  return (
    <Stepper
      activeStep={activeStep}
      sx={{ mb: 4, mx: 'auto', maxWidth: '700px' }}
    >
      <Step
        key={0}
        onClick={
          activeStep > 0
            ? () => naviagate(paths.parentPanel.newOrder(month))
            : undefined
        }
        sx={{
          cursor: activeStep > 0 ? 'pointer' : 'default',
        }}
      >
        <StepLabel>Wybór menu</StepLabel>
      </Step>
      {/* {isPickupLocationActive && (
        <Step key={1}>
          <StepLabel>Miejsce odbioru</StepLabel>
        </Step>
      )} */}
      <Step key={1}>
        <StepLabel>Podsumowanie</StepLabel>
      </Step>
      <Step key={2}>
        <StepLabel>Płatność</StepLabel>
      </Step>
    </Stepper>
  );
};

export default Steps;
