import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from '@mui/material';
import Label, { LabelColor } from 'src/components/helpers/label';

type MonthSelectProps = {
  options: {
    value: string;
    label: string;
    disabled?: boolean;
    disabledReason?: string;
    disabledReasonColor?: LabelColor;
  }[];
  value: string;
  onChange: (value: string) => void;
};

const MonthSelect = ({ options, value, onChange }: MonthSelectProps) => {
  return (
    <FormControl sx={{ width: '100%' }}>
      <InputLabel>Wybierz miesiąc</InputLabel>
      <Select
        name="month"
        label="Wybierz miesiąc"
        value={value}
        onChange={(event) => {
          onChange(event.target.value);
        }}
      >
        {options.map((menuMonth) => (
          <MenuItem
            key={menuMonth.value}
            value={menuMonth.value}
            disabled={menuMonth.disabled}
          >
            <Stack direction="row" spacing={1}>
              {menuMonth.label}
              {menuMonth.disabled && menuMonth.disabledReason && (
                <Label color={menuMonth.disabledReasonColor || 'default'}>
                  {menuMonth.disabledReason}
                </Label>
              )}
            </Stack>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default MonthSelect;
