import { useCallback, useMemo, useState } from 'react';
import { endpoints, fetcher } from 'src/utils/axios';
import Loader from 'src/components/helpers/loader/loader';
import useSWR, { useSWRConfig } from 'swr';
import { useLocation, useNavigate } from 'react-router-dom';
import { paths } from 'src/routes/paths';
import { Box } from '@mui/material';
import { ApiResponse } from 'src/types/common';
import { CartContext } from './cart-context';
import {
  AddToCartParams,
  ApiGetCartResponse,
  CartBalance,
  SetPaymentMethodParams,
  UpdateCartItemParams,
} from './types';
import {
  addToCartRequest,
  placeOrderRequest,
  removeFromCartRequest,
  setPaymentMethodRequest,
  updateCartItemRequest,
} from './actions';
import { usePersonsMenuContext } from '../children-menu';
import { useAuthContext } from '../auth';

type Props = {
  children: React.ReactNode;
  month: string;
};

export function CartProvider({ month, children }: Props) {
  const { mutate } = useSWRConfig();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { refetchUser } = useAuthContext();
  const { updateChildrenMenu } = usePersonsMenuContext();

  const {
    data: cartData,
    isLoading,
    mutate: mutateCart,
  } = useSWR<ApiGetCartResponse>(
    month ? endpoints.customer.cart.getCart(month) : null,
    fetcher,
    {
      revalidateOnMount: true,
      onSuccess: (data) => {
        if (data?.success) {
          mutate(endpoints.customer.cart.getPickupLocation(data.data.uuid));
        }
      },
    },
  );

  const {
    data: balanceData,
    isLoading: isBalanceLoading,
    mutate: mutateBalance,
  } = useSWR<ApiResponse<CartBalance>>(
    // run query only on summary step
    pathname === paths.parentPanel.newOrderSummary(month)
      ? endpoints.customer.cart.balance(month)
      : null,
  );

  const balance = balanceData?.data || null;

  // Add to cart
  const addToCart = useCallback(
    async (data: AddToCartParams) => {
      const res = await addToCartRequest(data);
      if (res) {
        const { cart, menu } = res;

        mutateCart({ data: cart, success: true });

        if (menu?.[0]) {
          updateChildrenMenu(menu[0]);
          mutate(endpoints.customer.cart.getPickupLocation(cart.uuid));
        }
      }
    },
    [mutateCart, updateChildrenMenu, mutate],
  );

  // Update cart item
  const updateCartItem = useCallback(
    async (itemId: string, data: UpdateCartItemParams) => {
      const res = await updateCartItemRequest(itemId, data);
      if (res) {
        const { cart, menu } = res;

        mutateCart({ data: cart, success: true });

        if (menu?.[0]) {
          updateChildrenMenu(menu[0]);
          mutate(endpoints.customer.cart.getPickupLocation(cart.uuid));
        }
      }
    },
    [mutateCart, updateChildrenMenu, mutate],
  );

  // Remove from cart
  const removeFromCart = useCallback(
    async (uuid: string) => {
      const res = await removeFromCartRequest(uuid);

      if (res) {
        const { cart, menu } = res;

        mutateCart({ data: cart, success: true });

        if (menu?.[0]) {
          updateChildrenMenu(menu[0]);
          mutate(endpoints.customer.cart.getPickupLocation(cart.uuid));
        }
      }
    },
    [mutateCart, updateChildrenMenu, mutate],
  );

  // Set payment method
  const setPaymentMethod = useCallback(
    async (data: SetPaymentMethodParams) => {
      const res = await setPaymentMethodRequest(data);
      if (res) {
        const { cart } = res;

        mutateCart({ data: cart, success: true });

        mutateBalance();
      }
    },
    [mutateCart, mutateBalance],
  );

  const [orderLoading, setOrderLoading] = useState(false);

  // Place order
  const placeOrder = useCallback(
    async (fullDiscount?: boolean) => {
      if (!cartData?.data?.uuid) return;
      setOrderLoading(true);

      const orderId = await placeOrderRequest(cartData.data.uuid);

      if (!orderId) {
        setOrderLoading(false);
        return;
      }

      // refetch user so the balance is updated
      await refetchUser();

      if (fullDiscount) {
        navigate(paths.parentPanel.order(orderId));
        return;
      }

      navigate(paths.parentPanel.orderPay(orderId));
    },
    [navigate, refetchUser, cartData?.data?.uuid, setOrderLoading],
  );

  const memoizedValue = useMemo(
    () => ({
      cart: cartData?.data ?? null,
      balance,
      removeFromCart,
      addToCart,
      updateCartItem,
      setPaymentMethod,
      placeOrder,
      month,
      orderLoading,
      loading: isBalanceLoading,
    }),
    [
      addToCart,
      balance,
      cartData?.data,
      removeFromCart,
      updateCartItem,
      setPaymentMethod,
      placeOrder,
      month,
      orderLoading,
      isBalanceLoading,
    ],
  );

  if (isLoading) {
    return (
      <Box sx={{ pb: 3 }}>
        <Loader />
      </Box>
    );
  }

  // if (!cartData?.data) {
  //   return null;
  // }

  return (
    <CartContext.Provider value={memoizedValue}>
      {children}
    </CartContext.Provider>
  );
}
