import { Button } from '@mui/material';
import { useCallback, useState } from 'react';
import { ConfirmDialog } from 'src/components/helpers/custom-dialog';

type MealCancelButtonProps = {
  onCancel: () => Promise<void>;
};

const MealCancelButton = ({ onCancel }: MealCancelButtonProps) => {
  const [open, setOpen] = useState(false);

  const handleClick = useCallback(() => setOpen((state) => !state), [setOpen]);

  const handleCancel = async () => {
    setOpen(false);
    await onCancel();
  };

  return (
    <>
      <Button variant="outlined" color="error" onClick={handleClick}>
        Odwołaj posiłki z tego dnia
      </Button>
      <ConfirmDialog
        title="Czy na pewno chcesz odwołać posiłki?"
        content="Kwota za odwołane posiłki zostanie naliczona na poczet przyszłego zamówienia."
        open={open}
        onClose={handleClick}
        action={
          <Button variant="contained" color="error" onClick={handleCancel}>
            Tak, odwołaj
          </Button>
        }
      />
    </>
  );
};

export default MealCancelButton;
