import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import Loader from 'src/components/helpers/loader/loader';
import { AuthCompactLayout, AuthLayout } from 'src/layouts/auth';

export const LoginPage = lazy(() => import('src/pages/auth/login'));
export const ImpersonatePage = lazy(() => import('src/pages/auth/Impersonate'));

const InvitationPage = lazy(() => import('src/pages/auth/invitation'));
const InstitutionInvitation = lazy(
  () => import('src/pages/auth/InstitutionInvitation'),
);
const ApplyInstitutionInvitation = lazy(
  () => import('src/pages/auth/ApplyInstitutionInvitation'),
);
const NewPasswordPage = lazy(() => import('src/pages/auth/new-password'));
const ForgotPasswordPage = lazy(() => import('src/pages/auth/forgot-password'));
const ActivateUserPage = lazy(() => import('src/pages/auth/ActivateUser'));
const VerifyEmailPage = lazy(() => import('src/pages/auth/VerifyEmail'));

export const authRoutes = [
  {
    element: (
      <AuthCompactLayout>
        <Suspense fallback={<Loader />}>
          <Outlet />
        </Suspense>
      </AuthCompactLayout>
    ),
    children: [
      { path: 'new-password/:id', element: <NewPasswordPage /> },
      { path: 'forgot-password', element: <ForgotPasswordPage /> },
    ],
  },
  {
    element: (
      <AuthCompactLayout>
        <Suspense fallback={<Loader />}>
          <Outlet />
        </Suspense>
      </AuthCompactLayout>
    ),
    children: [
      { path: 'activate-user', element: <ActivateUserPage /> },
      { path: 'verify-email', element: <VerifyEmailPage /> },
    ],
  },
  {
    element: (
      <AuthCompactLayout>
        <Suspense fallback={<Loader />}>
          <Outlet />
        </Suspense>
      </AuthCompactLayout>
    ),
    children: [{ path: 'invitation/:id', element: <InvitationPage /> }],
  },
  {
    element: (
      <AuthCompactLayout>
        <Suspense fallback={<Loader />}>
          <Outlet />
        </Suspense>
      </AuthCompactLayout>
    ),
    children: [
      {
        path: 'school-invitation/:token/:institutionGuid',
        element: <ApplyInstitutionInvitation />,
      },
    ],
  },
  {
    element: (
      <AuthCompactLayout>
        <Suspense fallback={<Loader />}>
          <Outlet />
        </Suspense>
      </AuthCompactLayout>
    ),
    children: [
      {
        path: 'zaproszenie/:institutionGuid',
        element: <InstitutionInvitation />,
      },
    ],
  },
  {
    element: (
      <AuthLayout>
        <Suspense fallback={<Loader />}>
          <Outlet />
        </Suspense>
      </AuthLayout>
    ),
    children: [{ path: 'login', element: <LoginPage /> }],
  },
  {
    element: (
      <AuthCompactLayout>
        <Suspense fallback={<Loader />}>
          <Outlet />
        </Suspense>
      </AuthCompactLayout>
    ),
    children: [{ path: 'impersonate', element: <ImpersonatePage /> }],
  },
];
