import CustomHelmet from 'src/components/CustomHelmet';
import NewOrderMonthView from 'src/views/parentPanel/NewOrder/Month';

export default function NewOrderMonth() {
  return (
    <>
      <CustomHelmet title="Nowe zamówienie" />
      <NewOrderMonthView />
    </>
  );
}
