import axios, { endpoints } from 'src/utils/axios';
import { enqueueSnackbar } from 'notistack';
import { ApiResponse, AxiosApiResponse } from 'src/types/common';
import { CancelMealParams, CancelMealResponse } from './types';

export const cancelMeal = async (
  childUuid: string | null,
  data: CancelMealParams,
): Promise<ApiResponse<CancelMealResponse> | null> => {
  try {
    const res = await axios.post<
      CancelMealParams,
      AxiosApiResponse<CancelMealResponse>
    >(
      childUuid
        ? endpoints.customer.cancelMeal(childUuid)
        : endpoints.customer.cancelCustomerMeal(),
      data,
    );

    if (res.data.success) {
      enqueueSnackbar('Odwołano posiłek', {
        variant: 'success',
      });
    }

    return res.data;
  } catch (error) {
    switch (error.status) {
      default:
        enqueueSnackbar('Wystąpił błąd', { variant: 'error' });
        break;
    }
    return null;
  }
};
