import { Box, Button, Card, Typography, useTheme } from '@mui/material';
import TextMaxLine from 'src/components/helpers/text-max-line/text-max-line';
import InfoIcon from '@mui/icons-material/Info';
import { useState } from 'react';
import { PersonMenuMeal } from 'src/providers/children/types';
import MealDetailsModal from 'src/components/parents/MealDetailsModal';
import {
  FF_SHOW_ALLERGENS,
  FF_SHOW_NUTRITION_VALUES,
} from 'src/config/featureFlags';
import { formatNutritionValues } from 'src/utils/format';

export type MealCardItemProps = {
  item: PersonMenuMeal;
};

export default function MealCardItem({
  item: {
    mealable,
    meal_category: { name: category },
  },
}: MealCardItemProps) {
  const theme = useTheme();
  const [showDetails, setShowDetails] = useState(false);

  const { calories, carbohydrates_gram, fats_gram, proteins_gram } =
    mealable.values;

  const handleCloseDetails = () => {
    setShowDetails(false);
  };

  const showDetailsButton =
    FF_SHOW_ALLERGENS || FF_SHOW_NUTRITION_VALUES || mealable.type === 'bundle';

  return (
    <Box sx={{ padding: '20px', position: 'relative', height: '100%' }}>
      <Card
        sx={{
          width: '100%',
          border: '1px solid',
          borderColor: 'grey.300',
          paddingX: 2,
          paddingY: 2,
          position: 'initial',
        }}
      >
        <Box
          sx={{
            background: theme.palette.grey[300],
            paddingY: 0.25,
            paddingX: 0.8,
            borderRadius: '4px',
            width: 'fit-content',
            mb: 1,
          }}
        >
          <Typography variant="subtitle2" color={theme.palette.grey[700]}>
            {category}
          </Typography>
        </Box>
        <Box>
          <TextMaxLine variant="subtitle1" sx={{ mb: 1 }}>
            {mealable.name}
          </TextMaxLine>
        </Box>
        {FF_SHOW_NUTRITION_VALUES && (
          <Box display="flex" alignItems="center" gap={0.5} marginBottom={0.5}>
            <InfoIcon />
            <TextMaxLine variant="caption" line={1}>
              {formatNutritionValues(
                calories,
                proteins_gram,
                fats_gram,
                carbohydrates_gram,
              )}
            </TextMaxLine>
          </Box>
        )}
        {showDetailsButton && (
          <Box>
            <Button
              variant="text"
              size="small"
              onClick={() => setShowDetails(true)}
              sx={{ mb: 1 }}
            >
              Zobacz szczegóły
            </Button>
          </Box>
        )}
      </Card>
      <MealDetailsModal
        categoryName={category}
        mealable={mealable}
        opened={showDetails}
        onClose={handleCloseDetails}
      />
    </Box>
  );
}
