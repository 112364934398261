import { UseFormSetError } from 'react-hook-form';
import { AmountType } from 'src/types/common';
import { InstitutionType } from 'src/types/restaurant/institution';
import { RoleDetails } from 'src/types/restaurant/roles';
import { InstitutionPlace } from '../institutions/types';

type Nullable<T> = T | null;

export type ActionMapType<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export enum UserType {
  CUSTOMER = 'customer',
  RESTAURANT_STAFF = 'restaurant_staff',
}
type PhoneTypes = 'primary';

export type InstitutionDetails = {
  uuid: string;
  name: string;
  type: InstitutionType;
  institution_places: string[];
};

export type AuthUserType = {
  uuid: string;
  first_name: string;
  last_name: string;
  email: string;
  type: UserType;
  phone_number: {
    type: PhoneTypes;
    number: string;
    country: string;
    normalized: string;
  };
  restaurant: {
    uuid: string;
    name: string;
    guid: string;
  };
  institution_place: InstitutionPlace;
  institutions: InstitutionDetails[];
  balance: {
    amount: AmountType;
  };
  impersonating_user?: {
    uuid: string;
    first_name: string;
    last_name: string;
    email: string;
  };
  tawk_to?: {
    hash: string;
    property_id: string;
    widget_id: string;
  };
  role?: RoleDetails;
};

export type AuthStateType = {
  status?: string;
  loading: boolean;
  user: Nullable<AuthUserType>;
};

export type LoginParams = {
  email: string;
  password: string;
};
export type SetLoginRHFError = UseFormSetError<LoginParams>;

export type RegisterParams = {
  token: string;
  first_name?: string;
  last_name?: string;
  phone_number: string;
  password: string;
  password_confirmation: string;
};
export type SetRegisterRHFError = UseFormSetError<
  Omit<RegisterParams, 'token'>
>;

export type JWTContextType = {
  user: Nullable<AuthUserType>;
  method: string;
  loading: boolean;
  authenticated: boolean;
  unauthenticated: boolean;
  login: (params: LoginParams, setError: SetLoginRHFError) => Promise<void>;
  impersonate: (params: ImpersonateData) => Promise<boolean>;
  refetchUser: () => Promise<boolean>;
  register: (
    params: RegisterParams,
    setError: SetRegisterRHFError,
  ) => Promise<AuthUserType | null>;
  logout: () => Promise<void>;
};

// ------ API

// Login
export type ApiLoginResponse = {
  data: {
    data: {
      token: string;
    } & AuthUserType;
    success: boolean;
  };
};
export type ApiLoginRequest = LoginParams;

export type LoginRequestResponse = {
  user: AuthUserType;
  accessToken: string;
};

export type ImpersonateRequestParams = {
  uuid: string;
  token: string;
};

export type ImpersonateRequestResponse = {
  // user: AuthUserType;
  token: string;
};

export type ImpersonateData = {
  // user: AuthUserType;
  accessToken: string;
};

// Register
export type ApiRegisterResponse = {
  data: {
    data: {
      token: string;
    } & AuthUserType;
    success: boolean;
  };
};
export type ApiRegisterRequest = RegisterParams;

export type RegisterRequestResponse = {
  user: AuthUserType;
  accessToken: string;
};
