export enum RestaurantPermissionLevels {
  READ = 'read',
  WRITE = 'write',
}

/** keep permission names in sync with backend */
export enum RestaurantPermissions {
  ACTIVITY = 'activity',
  ALLERGEN = 'allergen',
  BALANCE_HISTORY = 'balance_history',
  BALANCE = 'balance',
  CANCELLATION = 'cancellation',
  CART_ITEM = 'cart_item',
  CART = 'cart',
  CHILD = 'child',
  DRAFT_MENU = 'draft_menu',
  FAILED_JOB = 'failed_job',
  INGREDIENT = 'ingredient',
  MEAL = 'meal',
  MEAL_BUNDLE = 'meal_bundle',
  MEAL_CATEGORY = 'meal_category',
  MENU = 'menu',
  MENU_MEALABLE = 'menu_mealable',
  ORDER = 'order',
  ORDER_ITEM = 'order_item',
  PAYMENT = 'payment',
  PROMOTION = 'promotion',
  // REPORTS
  REPORT_MEALS_TO_COOK = 'report_meals_to_cook',
  REPORT_INGREDIENTS_TO_COLLECT = 'report_ingredients_to_collect',
  REPORT_RESTAURANT_REVENUE = 'report_restaurant_revenue',
  REPORT_RESTAURANT_COMMISSION = 'report_restaurant_commission',
  REPORT_CHILDREN_MEALS_BY_institution_place = 'report_children_meals_by_institution_place',
  REPORT_MEALS_PACKAGE_LABELS = 'report_meals_package_labels',
  REPORT_INSTITUTION_END_OF_MONTH_CUSTOMERS_FINANCING = 'report_institution_end_of_month_customers_financing',
  RESTAURANT = 'restaurant',
  ROLE = 'role',
  RULE_CONDITION = 'rule_condition',
  INSTITUTION = 'institution',
  institution_place = 'institution_place',
  CUSTOMER = 'customer',
  STAFF = 'staff',
  USERS_GROUP = 'users_group',
  WORKING_DAY = 'working_day',
}

export enum PermissionsGroups {
  MENU = 'menu',
  INSTITUTION = 'institution',
  REPORTS = 'reports',
  RESTAURANT_SETTINGS = 'restaurant_settings',
  CUSTOMERS = 'customers',
  STAFF = 'staff',
  OTHER = 'other',
}

export enum PermissionsCategories {
  MENU = 'menu',
  CUSTOMERS = 'customers',
  REPORTS = 'reports',
  STAFF = 'staff',
  ACTIVITY = 'activity',
  RESTAURANT_SETTINGS = 'restaurant_settings',
}

export type Permission = {
  id: number;
  name: RestaurantPermissions;
  level: RestaurantPermissionLevels;
  handles_institutions: boolean;
};

export type InstitutionsSelectOption =
  | 'all'
  | 'allPermissions'
  | 'perPermission';
