// routes
import axios from 'src/utils/axios';

export const setSession = (accessToken: string | null) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);

    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');

    delete axios.defaults.headers.common.Authorization;
  }
};

export const setRestaurantGuid = (restaurantGuid: string | null) => {
  if (restaurantGuid) {
    localStorage.setItem('restaurantGuid', restaurantGuid);
  } else {
    localStorage.removeItem('restaurantGuid');
  }
};
