import { Document, Page, View, StyleSheet, Font } from '@react-pdf/renderer';
import {
  eachDayOfInterval,
  endOfMonth,
  endOfWeek,
  format,
  startOfMonth,
  startOfWeek,
} from 'date-fns';
import { useMemo } from 'react';
import { Meal } from 'src/types/restaurant/meal';
import Header from './Header';
import WeekdaysHeader from './WeekdaysHeader';
import Day from './Day';

Font.register({
  family: 'Roboto',
  src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf',
});

type MealsCalendarPdfProps = {
  month: string;
  title: string;
  mealsPerDay: Record<string, Meal['name'][]>;
};

const MealsCalendarPdf = ({
  month,
  title,
  mealsPerDay,
}: MealsCalendarPdfProps) => {
  // get all days of the month view
  // (including days from previous and next month to fill the grid)
  const calendarDays = useMemo(() => {
    const currentDate = new Date(month);

    // Monday of the first week
    const startDate = startOfWeek(startOfMonth(currentDate), {
      weekStartsOn: 1,
    });
    // Sunday of the last week
    const endDate = endOfWeek(endOfMonth(currentDate), { weekStartsOn: 1 });

    const days = eachDayOfInterval({ start: startDate, end: endDate });
    return days;
  }, [month]);

  return (
    <Document>
      <Page size="A4" orientation="landscape" style={styles.page}>
        <View style={styles.calendarContainer}>
          <Header month={month} title={title} />
          <WeekdaysHeader />
          <View style={styles.dayContainer}>
            {calendarDays.map((day) => (
              <Day
                key={day.toISOString()}
                month={month}
                day={day}
                meals={mealsPerDay?.[format(day, 'yyyy-MM-dd')] ?? []}
                isLongMonth={calendarDays.length > 35}
              />
            ))}
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default MealsCalendarPdf;

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#ffffff',
    padding: 20,
    fontFamily: 'Roboto',
  },
  calendarContainer: {
    width: '100%',
    textAlign: 'center',
  },
  dayContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 2,
    paddingTop: 2,
  },
});
