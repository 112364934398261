import { Helmet } from 'react-helmet-async';

type CustomHelmetProps = {
  title: string;
  children?: React.ReactNode;
};

const CustomHelmet = ({ title, children }: CustomHelmetProps) => {
  return (
    <Helmet>
      <title>{title} | Caterlinker</title>
      {children}
    </Helmet>
  );
};

export default CustomHelmet;
