import { Box, Stack } from '@mui/material';
import { paths } from 'src/routes/paths';

export const capitalizeFirstLetter = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1);

export const formatValidationMessage = (data: Record<string, string[]>) => {
  return (
    <Stack spacing={1}>
      {Object.values(data).map((field) => (
        <Box>{field?.[0]}</Box>
      ))}
    </Stack>
  );
};

export const formatNutritionValues = (
  calories: string,
  proteins: string,
  fats: string,
  carbohydrates: string,
) => {
  return `${calories} · B: ${proteins} · W: ${carbohydrates} · T: ${fats}`;
};

export const formatInstitutionInvitationUrl = (guid: string) => {
  return window.location.origin + paths.auth.institutionInvitation(guid);
};
