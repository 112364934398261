import { Box, IconButton } from '@mui/material';
import React from 'react';
import Iconify from 'src/theme/iconify/iconify';

type DialogHeaderProps = {
  onClose: () => void;
  children: React.ReactNode;
};

const DialogHeader = ({ onClose, children }: DialogHeaderProps) => {
  return (
    <Box sx={{ pr: 3 }}>
      {children}
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
        }}
      >
        <Iconify icon="material-symbols:close" />
      </IconButton>
    </Box>
  );
};

export default DialogHeader;
