import { useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { MealData } from 'src/providers/institutions/types';
import {
  FF_SHOW_ALLERGENS,
  FF_SHOW_INGREDIENTS,
  FF_SHOW_NUTRITION_VALUES,
} from 'src/config/featureFlags';
import NutritionValueItem from '../NutritionValueItem';

type MealDetailsProps = {
  meal: MealData;
};

const MealDetails = ({ meal }: MealDetailsProps) => {
  const mealIngredients = useMemo(() => {
    if (!meal) return null;
    return meal.meal_ingredients
      .map((ingredient) => {
        return ingredient.ingredient.name;
      })
      .join(', ');
  }, [meal]);

  const mealAlergens = useMemo(() => {
    if (!meal) return null;
    return meal.meal_allergens
      .map((allergen) => {
        return allergen.name;
      })
      .join(', ');
  }, [meal]);

  return (
    <Box>
      {FF_SHOW_NUTRITION_VALUES && (
        <Box sx={{ mb: 2 }}>
          <Typography fontWeight="600" sx={{ mb: 1 }}>
            Wartości odżywcze:
          </Typography>
          <Box sx={{ maxWidth: 380 }}>
            <NutritionValueItem
              label="Kalorie"
              value={meal.meal_values.calories.formatted}
              backdrop
            />
            <NutritionValueItem
              label="Waga"
              value={meal.meal_values.weight_gram.formatted}
            />
            <NutritionValueItem
              label="Białko"
              value={meal.meal_values.proteins_gram.formatted}
              backdrop
            />
            <NutritionValueItem
              label="Tłuszcz"
              value={meal.meal_values.fats_gram.formatted}
            />
            <NutritionValueItem
              label="Węglowodany"
              value={meal.meal_values.carbohydrates_gram.formatted}
              backdrop
            />
          </Box>
        </Box>
      )}
      {FF_SHOW_INGREDIENTS && mealIngredients && (
        <Box sx={{ mb: 2 }}>
          <Typography fontWeight="600">Składniki:</Typography>
          <Typography variant="body2" color="GrayText">
            {mealIngredients}
          </Typography>
        </Box>
      )}
      {FF_SHOW_ALLERGENS && (
        <Box>
          <Typography fontWeight="600">Alergeny:</Typography>
          <Typography variant="body2" color="GrayText">
            {mealAlergens || 'Brak'}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default MealDetails;
