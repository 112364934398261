import {
  useCallback,
  useMemo,
  useReducer,
  createContext,
  useContext,
} from 'react';

import { useParams } from 'src/routes/hooks';
import { Types, initialState, reducer } from './reducer';
import { MenuContextType, MenuStatePerson } from './types';

export const MenuContext = createContext({} as MenuContextType);

type MenuProviderProps = {
  children: React.ReactNode;
};

export function MenuProvider({ children: childrenNodes }: MenuProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { month } = useParams();

  const setCurrentPerson = useCallback((person: MenuStatePerson) => {
    dispatch({
      type: Types.SET_CURRENT_PERSON,
      payload: { person },
    });
  }, []);

  const setCurrentDay = useCallback((currentDay: string) => {
    dispatch({
      type: Types.SET_CURRENT_DAY,
      payload: { day: currentDay },
    });
  }, []);

  const memoizedValue = useMemo(
    () => ({
      state: { ...state, current: { ...state.current, month: month || '' } },
      setCurrentPerson,
      setCurrentDay,
    }),
    [state, setCurrentPerson, setCurrentDay, month],
  );

  return (
    <MenuContext.Provider value={memoizedValue}>
      {childrenNodes}
    </MenuContext.Provider>
  );
}

export const useMenuContext = () => {
  const context = useContext(MenuContext);

  if (!context)
    throw new Error('useMenuContext must be use inside MenuProvider');

  return context;
};
