import { Box, Typography } from '@mui/material';

type NutritionValueItemProps = {
  label: string;
  value: string;
  backdrop?: boolean;
};

const NutritionValueItem = ({
  label,
  value,
  backdrop,
}: NutritionValueItemProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 3,
        px: 1,
        py: 1,
        borderRadius: '8px',
        backgroundColor: backdrop ? 'primary.lighter' : null,
      }}
    >
      <Typography variant="body2" sx={{ flexShrink: 0, width: '50%' }}>
        {label}
      </Typography>
      <Typography variant="body2" sx={{ flexShrink: 0, width: '50%' }}>
        {value}
      </Typography>
    </Box>
  );
};

export default NutritionValueItem;
