import { forwardRef } from 'react';
// @mui
import Link from '@mui/material/Link';
import Box, { BoxProps } from '@mui/material/Box';
// routes
import { RouterLink } from 'src/routes/components';
// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
  size?: number | string;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ sx, size = 130, disabledLink = false }) => {
    const logo = (
      <Box sx={sx}>
        <Box
          component="img"
          src="/assets/images/caterlinker-logo.svg"
          sx={{ width: size }}
        />
      </Box>
    );
    // <Box
    //   ref={ref}
    //   component="div"
    //   {...other}
    //   display="flex"
    //   justifyContent="center"
    //   alignItems="center"
    // >
    //   <DeliveryDiningIcon sx={{ fontSize: 40, marginRight: 0.5 }} />

    //   <Typography variant="h4" color="textPrimary">
    //     Cater
    //     <Typography
    //       component="span"
    //       color="textPrimary"
    //       sx={{
    //         display: 'inline',
    //         textDecoration: 'underline',
    //         textDecorationStyle: 'wavy',
    //         textDecorationColor: theme.palette.primary.main,
    //       }}
    //       variant="h4"
    //     >
    //       linker
    //     </Typography>
    //   </Typography>
    // </Box>

    if (disabledLink) {
      return logo;
    }

    return (
      <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
        {logo}
      </Link>
    );
  },
);

export default Logo;
