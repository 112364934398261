import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';
import { useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { paths } from 'src/routes/paths';

import { PersonMenuAvailability } from 'src/providers/menu/types';
import PersonItem from 'src/components/PersonSelect/PersonItem';

type PersonSelectProps = {
  month: string;
  value: string;
  options: PersonMenuAvailability[];
  isLoading: boolean;
  onChange: (value: string) => void;
};

// TODO: rename to something more generic as we will have company employee here as well
const PersonSelect = ({
  month,
  value,
  options,
  isLoading,
  onChange,
}: PersonSelectProps) => {
  // TODO: move those flags above to the parent component
  const hasOrders = useMemo(() => {
    return options.some((person) => person.has_orders);
  }, [options]);
  // TODO: move those flags above to the parent component
  const canOrder = useMemo(() => {
    return options.some((person) => person.can_order);
  }, [options]);

  // TODO: move this to the parent component
  useEffect(() => {
    if (options.length === 0) return;
    const firstPersonWithOrder = options.find((person) => person.has_orders);
    if (!firstPersonWithOrder) return;
    if (firstPersonWithOrder.type === 'child') {
      onChange(firstPersonWithOrder.child.uuid);
      return;
    }
    onChange(firstPersonWithOrder.customer.uuid);
  }, [options, onChange]);

  if (isLoading) {
    return <CircularProgress />;
  }

  if (!hasOrders) {
    return (
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        spacing={1}
        alignItems="center"
        flexWrap="wrap"
      >
        {canOrder && (
          <>
            <Link to={paths.parentPanel.newOrder(month)}>
              <Button variant="contained">Złóż zamówienie</Button>
            </Link>
            <Typography>lub</Typography>
          </>
        )}
        <Link to={paths.parentPanel.orders}>
          <Button variant="contained">Sprawdź status zamówień</Button>
        </Link>
      </Stack>
    );
  }

  return (
    <FormControl sx={{ minWidth: 150, width: { xs: 1, md: '100%' } }}>
      <InputLabel>Wybierz zamawiającego</InputLabel>
      <Select
        label="Wybierz zamawiającego"
        value={value}
        onChange={(event: SelectChangeEvent) => {
          onChange(event.target.value);
        }}
      >
        {options.map((person) => (
          <MenuItem
            key={
              person.type === 'child' ? person.child.uuid : person.customer.uuid
            }
            value={
              person.type === 'child' ? person.child.uuid : person.customer.uuid
            }
            disabled={!person.has_orders}
          >
            <PersonItem person={person} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default PersonSelect;
