import { Button, Container, Paper, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useCartContext } from 'src/providers/cart';
import { useMenuContext } from 'src/providers/menu';
import { paths } from 'src/routes/paths';

const Cart = () => {
  const navigate = useNavigate();

  const { cart } = useCartContext();
  const {
    state: {
      current: { month },
    },
  } = useMenuContext();

  const isPickupLocationActive = cart?.features?.includes(
    'order-pickup-location',
  );

  return (
    <Paper
      elevation={0}
      variant="outlined"
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        backgroundColor: 'background.neutral',
        zIndex: 10,
      }}
    >
      <Container maxWidth="lg">
        <Stack
          spacing={2}
          direction={{ xs: 'column', md: 'row' }}
          sx={{
            justifyContent: 'flex-end',
            alignItems: 'center',
            py: 3,
            px: 1,
          }}
        >
          <Typography variant="body1">
            Suma zamówienia:{' '}
            <strong>
              {cart?.final_price_without_payment_price.formatted ?? ''}
            </strong>
          </Typography>
          <Button
            variant="contained"
            color="primary"
            disabled={!cart?.items?.length}
            onClick={() =>
              navigate(
                isPickupLocationActive
                  ? paths.parentPanel.newOrderPickup(month)
                  : paths.parentPanel.newOrderSummary(month),
              )
            }
          >
            Przejdź do podsumowania
          </Button>
        </Stack>
      </Container>
    </Paper>
  );
};

export default Cart;
