/* eslint-disable jsx-a11y/anchor-is-valid */
import { Box, Link, Stack, Typography } from '@mui/material';
import { endpoints, openFile } from 'src/utils/axios';
import useSWRMutation from 'swr/mutation';

const Footer = () => {
  const { trigger } = useSWRMutation(endpoints.guest.terms.get, openFile);

  const showTerms = (e: any) => {
    e.preventDefault();
    trigger();
  };

  return (
    <Box sx={{ py: 2.3 }}>
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        justifyContent="flex-start"
      >
        <Link href="#" onClick={showTerms}>
          <Typography variant="caption" fontWeight={600} color="grey.600">
            Regulamin
          </Typography>
        </Link>
        <Link
          href="https://caterlinker.com/polityka_prywatnosci.pdf"
          target="_blank"
          rel="noreferrer"
        >
          <Typography variant="caption" fontWeight={600} color="grey.600">
            Polityka prywatności
          </Typography>
        </Link>
      </Stack>
    </Box>
  );
};

export default Footer;
