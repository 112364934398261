import { useAuthContext } from 'src/providers/auth';
import { InstitutionDetails } from 'src/providers/auth/types';
import { InstitutionType } from 'src/types/restaurant/institution';

type useInstitutionsResult = {
  institution: InstitutionDetails[] | undefined;
  getInstitutionsByType: (
    institutionType: InstitutionType,
  ) => InstitutionDetails[];
  getInstitutionByUuid: (uuid: string) => InstitutionDetails | undefined;
};

const useInstitutions = (): useInstitutionsResult => {
  const { user } = useAuthContext();

  const getInstitutionsByType = (institutionType: InstitutionType) => {
    return (
      user?.institutions.filter(
        (institution) => institution.type === institutionType,
      ) || []
    );
  };

  const getInstitutionByUuid = (uuid: string) => {
    return user?.institutions.find((institution) => institution.uuid === uuid);
  };

  return {
    institution: user?.institutions,
    getInstitutionsByType,
    getInstitutionByUuid,
  };
};

export default useInstitutions;
