// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
// theme
import { hideScroll } from 'src/theme/css';
// components

import {
  NavSectionMini,
  NavSectionProps,
} from 'src/components/helpers/nav-section';
//
import LogoIcon from 'src/components/helpers/logo/logo-icon';
import { Button, Typography } from '@mui/material';
import { useAuthContext } from 'src/providers/auth';
import { useRouter } from 'src/routes/hooks';
import { NAV } from '../config-layout';

// import { NavToggleButton } from '../_common';

// ----------------------------------------------------------------------

type NavMiniProps = {
  menuItems: NavSectionProps['data'];
};

export default function NavMini({ menuItems }: NavMiniProps) {
  const router = useRouter();
  const { logout } = useAuthContext();

  const handleLogout = async () => {
    try {
      await logout();
      router.replace('/');
    } catch (error) {
      // console.error(error);
    }
  };

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_MINI },
      }}
    >
      {/* <NavToggleButton
        sx={{
          top: 22,
          left: NAV.W_MINI - 12,
        }}
      /> */}

      <Stack
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        sx={{
          pb: 2,
          height: 1,
          position: 'fixed',
          width: NAV.W_MINI,
          borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          ...hideScroll.x,
        }}
      >
        <Box>
          <LogoIcon sx={{ mx: 'auto', my: 2 }} />

          <NavSectionMini
            data={menuItems}
            // config={{
            //   currentRole: user?.type || 'admin',
            // }}
          />
        </Box>

        <Button variant="soft" size="small" onClick={handleLogout}>
          <Typography variant="caption">Wyloguj</Typography>
        </Button>
      </Stack>
    </Box>
  );
}
