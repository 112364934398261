import Box from '@mui/material/Box';

import Carousel, {
  CarouselArrows,
  useCarousel,
} from 'src/components/helpers/carousel';
import { styled } from '@mui/material';
import { useWidth } from 'src/hooks/use-responsive';
import { Date, Mealable } from 'src/providers/children-menu/types';
import CarouselMealCardItem from './carousel-meal-card-item';

const StyledRoot = styled(Box)(() => ({
  '& .slick-track': {
    margin: 0,
  },
}));

type CarouselMealCardsProps = {
  date: Date;
  data: Mealable[];
};

export default function CarouselMealCards({
  date,
  data,
}: CarouselMealCardsProps) {
  const carousel = useCarousel({
    slidesToShow: 3,
    swipeToSlide: true,
    infinite: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: { slidesToShow: 2 },
      },
      {
        breakpoint: 900,
        settings: { slidesToShow: 1, centerPadding: '0' },
      },
    ],
  });

  const breakpoints = useWidth();

  if (
    (breakpoints === 'xl' && data.length > 3) ||
    (breakpoints === 'lg' && data.length > 3) ||
    (breakpoints === 'md' && data.length > 2) ||
    (breakpoints === 'sm' && data.length > 1) ||
    (breakpoints === 'xs' && data.length > 1)
  ) {
    return (
      <StyledRoot>
        <CarouselArrows
          icon="uiw:right"
          onNext={carousel.onNext}
          onPrev={carousel.onPrev}
        >
          <Carousel ref={carousel.carouselRef} {...carousel.carouselSettings}>
            {data.map((item) => (
              <Box key={item.uuid} sx={{ px: 1 }}>
                <CarouselMealCardItem date={date} item={item} />
              </Box>
            ))}
          </Carousel>
        </CarouselArrows>
      </StyledRoot>
    );
  }

  return (
    <StyledRoot>
      <Carousel ref={carousel.carouselRef} {...carousel.carouselSettings}>
        {data.map((item) => (
          <Box key={item.uuid} sx={{ px: 1 }}>
            <CarouselMealCardItem date={date} item={item} />
          </Box>
        ))}
      </Carousel>
    </StyledRoot>
  );
}
