import { useContext } from 'react';
import { PersonsMenuContext } from './children-menu-context';

export const usePersonsMenuContext = () => {
  const context = useContext(PersonsMenuContext);

  if (!context)
    throw new Error(
      'usePersonsMenuContext must be use inside ChildrenMenuProvider',
    );

  return context;
};
