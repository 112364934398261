import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import Loader from 'src/components/helpers/loader/loader';
import CompactLayout from 'src/layouts/compact';

export const LoginPage = lazy(() => import('src/pages/auth/login'));

const Page404 = lazy(() => import('src/pages/error/404'));

export const errorRoutes = [
  {
    element: (
      <CompactLayout>
        <Suspense fallback={<Loader />}>
          <Outlet />
        </Suspense>
      </CompactLayout>
    ),
    children: [{ path: '404', element: <Page404 /> }],
  },
];
