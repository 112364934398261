import { Box, Card, CircularProgress, Stack, Typography } from '@mui/material';
import useSWR from 'swr';
import { ApiGetMealBundleResponse } from 'src/providers/institutions/types';
import { endpoints, fetcher } from 'src/utils/axios';
import { FF_SHOW_NUTRITION_VALUES } from 'src/config/featureFlags';
import NutritionValueItem from '../NutritionValueItem';
import MealDetails from '../MealDetails';

type MealBundleDetailsProps = {
  mealBundleId: string;
};

const MealBundleDetails = ({ mealBundleId }: MealBundleDetailsProps) => {
  const { data, isLoading } = useSWR<ApiGetMealBundleResponse>(
    mealBundleId ? endpoints.customer.getMealBundle(mealBundleId) : null,
    fetcher,
  );

  if (isLoading || !data) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      {FF_SHOW_NUTRITION_VALUES && (
        <Card sx={{ p: 2, mb: 3, maxWidth: 300 }}>
          <Typography fontWeight="600" sx={{ mb: 1 }}>
            Wartości odżywcze:
          </Typography>
          <Box>
            <NutritionValueItem
              label="Kalorie"
              value={data.data.meal_values.calories.formatted}
              backdrop
            />
            <NutritionValueItem
              label="Waga"
              value={data.data.meal_values.weight_gram.formatted}
            />
            <NutritionValueItem
              label="Białko"
              value={data.data.meal_values.proteins_gram.formatted}
              backdrop
            />
            <NutritionValueItem
              label="Tłuszcz"
              value={data.data.meal_values.fats_gram.formatted}
            />
            <NutritionValueItem
              label="Węglowodany"
              value={data.data.meal_values.carbohydrates_gram.formatted}
              backdrop
            />
          </Box>
        </Card>
      )}
      <Typography variant="h5" sx={{ mb: 2 }}>
        Posiłki:
      </Typography>
      <Stack spacing={1} direction={{ xs: 'column' }}>
        {data.data.meals.map((meal) => (
          <Card key={meal.uuid} variant="outlined" sx={{ p: 2 }}>
            <Stack spacing={1}>
              <Typography variant="h6">{meal.name}</Typography>
              <MealDetails meal={meal} />
            </Stack>
          </Card>
        ))}
      </Stack>
    </>
  );
};

export default MealBundleDetails;
