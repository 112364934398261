import Box from '@mui/material/Box';
import { useResponsive } from 'src/hooks/use-responsive';
import { useBoolean } from 'src/hooks/use-boolean';
import { NavSectionProps } from 'src/components/helpers/nav-section';
import Header from './header';
import Main from './main';
import NavMini from './nav-mini';
import NavVertical from './nav-vertical';
import Footer from '../_common/Footer';

type Props = {
  menuItems: NavSectionProps['data'];
  children: React.ReactNode;
};

export default function DashboardLayout({ menuItems, children }: Props) {
  const nav = useBoolean();

  const lgUp = useResponsive('up', 'lg');

  return (
    <>
      <Header onOpenNav={nav.onToggle} />
      <Box
        sx={{
          minHeight: 1,
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
        }}
      >
        {lgUp ? (
          <NavMini menuItems={menuItems} />
        ) : (
          <NavVertical
            openNav={nav.value}
            onCloseNav={nav.onFalse}
            menuItems={menuItems}
          />
        )}
        <Main>
          <Box>{children}</Box>
          <Footer />
        </Main>
      </Box>
    </>
  );
}
