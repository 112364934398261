import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import { useResponsive } from 'src/hooks/use-responsive';

import Logo from 'src/components/helpers/logo';
import { LoginBackground } from 'src/assets/illustrations/login-background';
import { useTheme } from '@mui/material';
import { useAuthContext } from 'src/providers/auth';
import { useRouter } from 'src/routes/hooks';
import { useEffect } from 'react';
import { PATH_AFTER_LOGIN } from 'src/config-global';
import Loader from 'src/components/helpers/loader/loader';
import Footer from '../_common/Footer';

type Props = {
  children: React.ReactNode;
};

export default function AuthLayout({ children }: Props) {
  const { user, authenticated, loading } = useAuthContext();
  const upMd = useResponsive('up', 'md');
  const theme = useTheme();
  const router = useRouter();

  useEffect(() => {
    if (authenticated && user) {
      if (PATH_AFTER_LOGIN[user.type]) {
        router.push(PATH_AFTER_LOGIN[user.type]);
      }
    }
  }, [router, user, authenticated]);

  if (loading) return <Loader />;

  return (
    <Stack
      component="main"
      direction="row"
      sx={{
        minHeight: '100vh',
        position: 'relative',
        '&:before': {
          width: 1,
          height: 1,
          zIndex: -1,
          content: "''",
          position: 'absolute',
          backgroundSize: 'cover',
          opacity: { xs: 0.24, md: 0 },
          backgroundColor: theme.palette.background.neutral,
        },
      }}
    >
      <Stack
        sx={{
          width: 1,
          mx: 'auto',
          maxWidth: 580,
          px: { xs: 2, md: 8 },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: 5,
            mb: { xs: 5, md: 5 },
          }}
        >
          <Logo size={200} />
        </Box>
        <Stack sx={{ height: { md: '100%' } }} justifyContent="space-between">
          <Card
            sx={{
              py: { xs: 5, md: 0 },
              px: { xs: 3, md: 0 },
              boxShadow: { md: 'none' },
              overflow: { md: 'unset' },
              bgcolor: { md: 'background.default' },
            }}
          >
            {children}
          </Card>
          <Footer />
        </Stack>
      </Stack>

      {upMd && (
        <Stack flexGrow={1} sx={{ position: 'relative' }}>
          <Box
            sx={{
              backgroundColor: theme.palette.background.neutral,
              top: 16,
              left: 16,
              objectFit: 'cover',
              position: 'absolute',
              width: 'calc(100% - 32px)',
              height: 'calc(100% - 32px)',
              zIndex: -1,
              borderRadius: 2,
            }}
          />
          <LoginBackground
            sx={{
              display: 'flex',
              height: '100vh',
              width: '100%',
              margin: '0 auto',
              padding: { xs: '25px', md: '30px', lg: '50px' },
            }}
          />
        </Stack>
      )}
    </Stack>
  );
}
