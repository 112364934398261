import { CartProvider } from 'src/providers/cart';
import { useMenuContext } from 'src/providers/menu';
import { PersonsMenuProvider } from 'src/providers/children-menu';
import { Card, Stack } from '@mui/material';
import MenuToolbar from '../MenuToolbar';
import MenuData from './menu-data';
import Cart from '../Cart';

export default function Menu() {
  const {
    state: {
      current: { month },
    },
  } = useMenuContext();

  return (
    <Card>
      <MenuToolbar />
      <PersonsMenuProvider>
        <CartProvider month={month}>
          <Stack spacing={2} sx={{ mb: { xs: 8, lg: 6 } }}>
            <MenuData />
            <Cart />
          </Stack>
        </CartProvider>
      </PersonsMenuProvider>
    </Card>
  );
}
