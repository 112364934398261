// TODO: use utils/axios request functions instead of custom here
import axios, { endpoints } from 'src/utils/axios';
import { enqueueSnackbar } from 'notistack';
import { AxiosApiResponse } from 'src/types/common';
import { formatValidationMessage } from 'src/utils/format';
import {
  AddToCartParams,
  AddToCartResponse,
  ApiAddToCartRequest,
  RemoveFromCartResponse,
  UpdateCartItemParams,
  UpdateCartItemResponse,
  ApiUpdateCartItemRequest,
  SetPaymentMethodParams,
  SetPaymentMethodResponse,
  ApiSetPaymentMethodRequest,
  ApiSetPaymentMethodResponse,
  ApiPlaceOrderRequest,
  PlaceOrderResponse,
  CartType,
} from './types';
import { MenuPerDay } from '../children-menu/types';

export const addToCartRequest = async (
  data: AddToCartParams,
): Promise<AddToCartResponse | null> => {
  try {
    const res = await axios.post<
      ApiAddToCartRequest,
      AxiosApiResponse<{ cart: CartType; menu: MenuPerDay[] }>
    >(endpoints.customer.cart.addToCart, data);

    if (res.data.success) {
      enqueueSnackbar('Pomyślne dodano produkt', {
        variant: 'success',
      });
    }

    return res.data.data;
  } catch (error) {
    switch (error.status) {
      case 422:
        enqueueSnackbar(
          error.data?.data
            ? formatValidationMessage(error.data.data)
            : 'Błąd walidacji',
          { variant: 'error' },
        );
        break;
      default:
        enqueueSnackbar(error.data?.error_message || 'Wystąpił błąd', {
          variant: 'error',
        });
        break;
    }
    return null;
  }
};

export const updateCartItemRequest = async (
  uuid: string,
  data: UpdateCartItemParams,
): Promise<UpdateCartItemResponse | null> => {
  try {
    const res = await axios.patch<
      ApiUpdateCartItemRequest,
      AxiosApiResponse<{ cart: CartType; menu: MenuPerDay[] }>
    >(endpoints.customer.cart.updateCartItem(uuid), data);

    if (res.data.success) {
      enqueueSnackbar('Pomyślne zaktualizowano produkt', {
        variant: 'success',
      });
    }

    return res.data.data;
  } catch (error) {
    switch (error.status) {
      case 422:
        enqueueSnackbar(
          error.data?.data
            ? formatValidationMessage(error.data.data)
            : 'Błąd walidacji',
          { variant: 'error' },
        );
        break;
      default:
        enqueueSnackbar(error.data?.error_message || 'Wystąpił błąd', {
          variant: 'error',
        });
        break;
    }
    return null;
  }
};

export const removeFromCartRequest = async (
  itemId: string,
): Promise<RemoveFromCartResponse | null> => {
  try {
    const res = await axios.delete<
      null,
      AxiosApiResponse<{ cart: CartType; menu: MenuPerDay[] }>
    >(endpoints.customer.cart.removeFromCart(itemId));

    if (res.data.success) {
      enqueueSnackbar('Pomyślne usunięto produkt', {
        variant: 'success',
      });
    }

    return res.data.data;
  } catch (error) {
    switch (error.status) {
      case 422:
        enqueueSnackbar(
          error.data?.data
            ? formatValidationMessage(error.data.data)
            : 'Błąd walidacji',
          { variant: 'error' },
        );
        break;
      default:
        enqueueSnackbar(error.data?.error_message || 'Wystąpił błąd', {
          variant: 'error',
        });
        break;
    }
    return null;
  }
};

export const setPaymentMethodRequest = async (
  data: SetPaymentMethodParams,
): Promise<SetPaymentMethodResponse | null> => {
  try {
    const res = await axios.post<
      ApiSetPaymentMethodRequest,
      ApiSetPaymentMethodResponse
    >(endpoints.customer.cart.setPaymentMethod, data);

    // payment method is being set automatically
    // so don't display snackbar
    // if (res.data.success) {
    //   enqueueSnackbar('Ustawiono metodę płatności', {
    //     variant: 'success',
    //   });
    // }

    const { ...rest } = res.data.data;

    return {
      cart: rest,
    };
  } catch (error) {
    switch (error.status) {
      case 422:
        enqueueSnackbar(
          error.data?.data
            ? formatValidationMessage(error.data.data)
            : 'Błąd walidacji',
          { variant: 'error' },
        );
        break;
      default:
        enqueueSnackbar(error.data?.error_message || 'Wystąpił błąd', {
          variant: 'error',
        });
        break;
    }
    return null;
  }
};

export const placeOrderRequest = async (
  uuid: string,
): Promise<string | null> => {
  try {
    const res = await axios.post<
      ApiPlaceOrderRequest,
      AxiosApiResponse<PlaceOrderResponse>
    >(endpoints.customer.cart.placeOrder(uuid));

    if (res.data.success) {
      enqueueSnackbar('Zamówienie zostało złożone', {
        variant: 'success',
      });
    }

    const { uuid: orderId } = res.data.data;

    return orderId;
  } catch (error) {
    switch (error.status) {
      case 422:
        enqueueSnackbar(
          error.data?.data
            ? formatValidationMessage(error.data.data)
            : 'Błąd walidacji',
          { variant: 'error' },
        );
        break;
      default:
        enqueueSnackbar(error.data?.error_message || 'Wystąpił błąd', {
          variant: 'error',
        });
        break;
    }
    return null;
  }
};
