import { Button } from '@mui/material';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { compact, replace } from 'lodash';
import { useMemo } from 'react';
import MealsCalendarPdf from 'src/components/MealsCalendarPdf';
import { PersonMenuAvailability } from 'src/providers/menu/types';
import Iconify from 'src/theme/iconify';
import { PdfCalendarData } from 'src/types/parent/menu';

type DownloadPdfCalendarProps = {
  month: string;
  person: PersonMenuAvailability;
  calendarData: PdfCalendarData;
};

export const DownloadPdfCalendar = ({
  month,
  person,
  calendarData,
}: DownloadPdfCalendarProps) => {
  const childName = useMemo(() => {
    if (person.type === 'child') {
      return (
        compact([person.child.first_name, person.child.last_name]).join(' ') ||
        ''
      );
    }

    return (
      compact([person.customer.first_name, person.customer.last_name]).join(
        ' ',
      ) || ''
    );
  }, [person]);

  const fileName = useMemo(() => {
    return `menu-${month}-${replace(childName, /\s+/g, '_').toLowerCase()}`;
  }, [month, childName]);

  return (
    <PDFDownloadLink
      document={
        <MealsCalendarPdf
          month={month}
          title={childName}
          mealsPerDay={calendarData}
        />
      }
      fileName={fileName}
    >
      {({ loading }) => (
        <Button
          variant="contained"
          color="inherit"
          disabled={loading}
          startIcon={<Iconify icon="material-symbols:print" />}
        >
          Wydrukuj menu
        </Button>
      )}
    </PDFDownloadLink>
  );
};
