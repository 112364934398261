import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import MaintenanceIllustration from 'src/assets/illustrations/maintenance-illustration';
import useSWR from 'swr';
import { endpoints } from 'src/utils/axios';
import { useQuery } from 'src/utils/router';

export default function MaintenanceView() {
  const query = useQuery();
  const refreshTime = query.get('refresh')
    ? parseInt(query.get('refresh') as string, 10)
    : 10000;

  useSWR(endpoints.auth.me, {
    refreshInterval: refreshTime,
    errorRetryInterval: refreshTime,
    onSuccess: () => {
      window.location.href = query.get('back') || '/';
    },
    onErrorRetry(err, key, config, revalidate, revalidateOps) {
      setTimeout(revalidate, config.errorRetryInterval, revalidateOps);
    },
  });

  return (
    <Stack sx={{ alignItems: 'center' }}>
      <Typography variant="h3" sx={{ mb: 2 }}>
        Przerwa techniczna
        <br />
        Wrócimy niebawem!
      </Typography>

      <Typography variant="subtitle1" sx={{ color: 'text.secondary' }}>
        Szanowni Użytkownicy,
        <br />
        Aktualnie trwa planowana przerwa techniczna w celu przeprowadzenia
        konserwacji i ulepszeń, przepraszamy za niedogodności.
        <br />
        Nasz zespół pracuje nad przywróceniem pełnej funkcjonalności jak
        najszybciej. Prosimy o cierpliwość i zapewniamy, że wrócimy wkrótce.
        <br />
        Dziękujemy za zrozumienie.
      </Typography>

      <MaintenanceIllustration sx={{ my: 5, height: 240 }} />
    </Stack>
  );
}
