import { useCallback, useMemo, useState } from 'react';
import useSWR from 'swr';
import { endpoints, fetcher } from 'src/utils/axios';
import { enqueueSnackbar } from 'notistack';
import Loader from 'src/components/helpers/loader/loader';
import { Box } from '@mui/material';
import useInstitutions from 'src/hooks/useInstitutionDetails';
import { ApiChildrenMenuResponse, ChildrenMenu, MenuPerDay } from './types';
import { PersonsMenuContext } from './children-menu-context';
import { useMenuContext } from '../menu';

type PersonsMenuProviderProps = {
  children: React.ReactNode;
};

export function PersonsMenuProvider({
  children: childrenNodes,
}: PersonsMenuProviderProps) {
  const {
    state: {
      current: { person, month },
    },
  } = useMenuContext();
  const { getInstitutionsByType } = useInstitutions();
  const companyInstitution = getInstitutionsByType('company')?.[0];

  const [childrenMenu, setChildrenMenu] = useState<ChildrenMenu>({});

  const { isLoading } = useSWR<ApiChildrenMenuResponse>(
    // eslint-disable-next-line no-nested-ternary
    person && !childrenMenu[person.uuid]?.[month] && month
      ? person.type === 'child'
        ? endpoints.customer.getMenuForChild(person.uuid, month)
        : endpoints.customer.getMenuForInstitution(
            companyInstitution?.uuid,
            month,
          )
      : null,
    fetcher,
    {
      revalidateOnMount: true,
      onSuccess: (data) => {
        if (!data?.success) {
          enqueueSnackbar('Wystąpił błąd', { variant: 'error' });
          return;
        }
        if (!person) return;
        setChildrenMenu((prev) => ({
          ...prev,
          [person.uuid]: {
            ...prev[person.uuid],
            [month]: data.data,
          },
        }));
      },
    },
  );

  const updateChildrenMenu = useCallback(
    (menuPerDay: MenuPerDay) => {
      if (!person) return;
      setChildrenMenu((prev) => ({
        ...prev,
        [person.uuid]: {
          ...prev[person.uuid],
          [month]: {
            ...prev[person.uuid][month],
            menu: prev[person.uuid][month].menu.map((m) => {
              if (m.date.full === menuPerDay.date.full) {
                return menuPerDay;
              }
              return m;
            }),
          },
        },
      }));
    },
    [person, month],
  );

  const memoizedValue = useMemo(
    () => ({
      childrenMenu,
      updateChildrenMenu,
      isLoading,
    }),
    [childrenMenu, updateChildrenMenu, isLoading],
  );

  if (!person) {
    return null;
  }

  if (isLoading) {
    return (
      <Box sx={{ pb: 3 }}>
        <Loader />
      </Box>
    );
  }

  return (
    <PersonsMenuContext.Provider value={memoizedValue}>
      {childrenNodes}
    </PersonsMenuContext.Provider>
  );
}
