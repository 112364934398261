import { Box, Button, Card, Chip, Typography, useTheme } from '@mui/material';
import TextMaxLine from 'src/components/helpers/text-max-line/text-max-line';
import { Date, Mealable } from 'src/providers/children-menu/types';
import InfoIcon from '@mui/icons-material/Info';
import { useMemo, useState } from 'react';
import { useCartContext } from 'src/providers/cart';
import { useMenuContext } from 'src/providers/menu';
import MealDetailsModal from 'src/components/parents/MealDetailsModal';
import {
  FF_SHOW_ALLERGENS,
  FF_SHOW_NUTRITION_VALUES,
} from 'src/config/featureFlags';
import { formatNutritionValues } from 'src/utils/format';
import CarouselMealAddToCart from './carousel-meal-card-add-to-cart';

export type CarouselMealCardItemProps = {
  date: Date;
  item: Mealable;
};

export default function CarouselMealCardItem({
  date,
  item: {
    // original_price,
    // discount_amount,
    final_price,
    uuid,
    mealable,
    meal_category: { name: category },
  },
}: CarouselMealCardItemProps) {
  const theme = useTheme();
  const [showDetails, setShowDetails] = useState(false);
  const {
    state: {
      current: { person },
    },
  } = useMenuContext();
  const { cart } = useCartContext();

  const { calories, carbohydrates_gram, fats_gram, proteins_gram } =
    mealable.values;

  const handleCloseDetails = () => {
    setShowDetails(false);
  };

  const itemInCart = useMemo(() => {
    if (!person) return null;
    const personUuid = person.type === 'child' ? person.uuid : null;
    return cart?.items?.find(
      (item) =>
        item.mealable.uuid === mealable.uuid &&
        date.full === item.available_at &&
        item.child_uuid === personUuid,
    );
  }, [cart?.items, date.full, mealable.uuid, person]);

  const showDetailsButton =
    FF_SHOW_ALLERGENS || FF_SHOW_NUTRITION_VALUES || mealable.type === 'bundle';

  return (
    <Box sx={{ padding: '20px', position: 'relative', height: '100%' }}>
      <Card
        sx={{
          width: '100%',
          border: '1px solid',
          borderColor: itemInCart ? 'primary.main' : 'grey.300',
          paddingX: 2,
          paddingY: 2,
          position: 'initial',
        }}
      >
        <Chip
          color="primary"
          sx={{
            position: 'absolute',
            right: '5px',
            transform: 'rotate(7deg)',
            top: '16px',
            fontSize: '0.9rem',
            fontWeight: 600,
          }}
          label={final_price.formatted}
        />

        <Box
          sx={{
            background: theme.palette.grey[300],
            paddingY: 0.25,
            paddingX: 0.8,
            borderRadius: '4px',
            width: 'fit-content',
            mb: 1,
          }}
        >
          <Typography variant="subtitle2" color={theme.palette.grey[700]}>
            {category}
          </Typography>
        </Box>
        <Box>
          <TextMaxLine variant="subtitle1" sx={{ mb: 1 }}>
            {mealable.name}
          </TextMaxLine>
        </Box>
        {FF_SHOW_NUTRITION_VALUES && (
          <Box display="flex" alignItems="center" gap={0.5} marginBottom={0.5}>
            <InfoIcon />
            <TextMaxLine variant="caption" line={1}>
              {formatNutritionValues(
                calories.formatted,
                proteins_gram,
                fats_gram,
                carbohydrates_gram,
              )}
            </TextMaxLine>
          </Box>
        )}
        <Box>
          {showDetailsButton && (
            <Button
              variant="text"
              size="small"
              onClick={() => setShowDetails(true)}
              sx={{ mb: 1 }}
            >
              Zobacz szczegóły
            </Button>
          )}
          <CarouselMealAddToCart mealUuid={uuid} itemInCart={itemInCart} />
        </Box>
      </Card>
      <MealDetailsModal
        categoryName={category}
        mealable={mealable}
        opened={showDetails}
        onClose={handleCloseDetails}
      />
    </Box>
  );
}
