import axios, { AxiosRequestConfig } from 'axios';
import { enqueueSnackbar } from 'notistack';
// config
import { HOST_API } from 'src/config-global';
import { setRestaurantGuid, setSession } from 'src/providers/auth/utils';
import { AxiosApiResponse } from 'src/types/common';
import {
  ReportCollectionStrategy,
  ReportPresentable,
} from 'src/types/restaurant/reports';
import { formatValidationMessage } from './format';

// ----------------------------------------------------------------------

export const publicAxiosInstance = axios.create({ baseURL: HOST_API });
const axiosInstance = axios.create({ baseURL: HOST_API });

axiosInstance.interceptors.request.use((config) => {
  const restaurantGuid = localStorage.getItem('restaurantGuid');

  if (restaurantGuid) {
    // eslint-disable-next-line no-param-reassign
    config.url = config.url
      ? config.url.replace('{restaurantGuid}', restaurantGuid)
      : config.url;
  }

  return config;
});

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => {
    if (error.response.status === 401) {
      // TODO redirect to login page without logiin page !!!
      // localStorage.removeItem('accessToken');
      setSession(null);
      setRestaurantGuid(null);

      if (!window.location.pathname.includes('login')) {
        window.location.href = '/login';
      }
    }
    if (error.response.status === 503) {
      const refresh =
        error.response.headers?.Refresh || error.response.headers?.refresh;
      if (!window.location.pathname.includes('maintenance')) {
        window.location.href = `/maintenance?back=${window.location.pathname}${
          refresh ? `&refresh=${refresh * 1000}` : ''
        }`;
      }
    }
    const reason = {
      status: error.response.status,
      data: error.response.data,
    };

    return Promise.reject(reason);
    // return Promise.reject(
    //   (error.response && error.response.data) || 'Something went wrong',
    // );
  },
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

export const patcher = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.patch(url, {}, { ...config });

  return res.data;
};

export const downloadFile = async (
  args: string | [string, AxiosRequestConfig],
) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  try {
    const res = await axiosInstance.get(url, {
      responseType: 'arraybuffer',
      ...config,
    });
    if (!res.data) return null;

    const fileUrl = window.URL.createObjectURL(
      new Blob([res.data], { type: res.headers['content-type'] }),
    );
    const contentDisposition = res.headers['content-disposition'];
    let fileName = `qrcode.pdf`;
    if (contentDisposition) {
      const fileNameMatch = contentDisposition.match(/filename=(.+)/);
      if (fileNameMatch.length === 2) {
        // eslint-disable-next-line prefer-destructuring
        fileName = fileNameMatch[1].replace(/"/g, '');
      }
    }
    const link = document.createElement('a');
    link.href = fileUrl;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();

    return res.data;
  } catch (error) {
    enqueueSnackbar(error.data?.error_message || 'Wystąpił błąd', {
      variant: 'error',
    });
    return null;
  }
};

export const openFile = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  try {
    const res = await publicAxiosInstance.get(url, {
      responseType: 'arraybuffer',
      ...config,
    });
    if (!res.data) return null;

    const fileUrl = window.URL.createObjectURL(
      new Blob([res.data], { type: res.headers['content-type'] }),
    );
    const contentDisposition = res.headers['content-disposition'];
    let fileName = `terms.pdf`;
    if (contentDisposition) {
      const fileNameMatch = contentDisposition.match(/filename=(.+)/);
      if (fileNameMatch.length === 2) {
        // eslint-disable-next-line prefer-destructuring
        fileName = fileNameMatch[1].replace(/"/g, '');
      }
    }
    const link = document.createElement('a');
    link.href = fileUrl;
    link.target = '_blank';
    link.title = fileName;
    document.body.appendChild(link);
    link.click();

    return res.data;
  } catch (error) {
    enqueueSnackbar(error.data?.error_message || 'Wystąpił błąd', {
      variant: 'error',
    });
    return null;
  }
};

export const postRequest = async <T>(url: string, { arg }: { arg: T }) => {
  try {
    const res = await axiosInstance.post<{}, AxiosApiResponse<T | null>>(
      url,
      arg,
    );

    return res.data.data;
  } catch (error) {
    switch (error.status) {
      case 422:
        enqueueSnackbar(
          error.data?.data
            ? formatValidationMessage(error.data.data)
            : 'Błąd walidacji',
          { variant: 'error' },
        );
        break;
      default:
        enqueueSnackbar(error.data?.error_message || 'Wystąpił błąd', {
          variant: 'error',
        });
        break;
    }
    return null;
  }
};

export const patchRequest = async <T>(url: string, { arg }: { arg: T }) => {
  try {
    const res = await axiosInstance.patch<{}, AxiosApiResponse<T | null>>(
      url,
      arg,
    );

    return res.data.data;
  } catch (error) {
    switch (error.status) {
      case 422:
        enqueueSnackbar(
          error.data?.data
            ? formatValidationMessage(error.data.data)
            : 'Błąd walidacji',
          { variant: 'error' },
        );
        break;
      default:
        enqueueSnackbar(error.data?.error_message || 'Wystąpił błąd', {
          variant: 'error',
        });
        break;
    }
    return null;
  }
};

export const putRequest = async <T>(url: string, { arg }: { arg: T }) => {
  try {
    const res = await axiosInstance.put<{}, AxiosApiResponse<T | null>>(
      url,
      arg,
    );

    return res.data.data;
  } catch (error) {
    switch (error.status) {
      case 422:
        enqueueSnackbar(
          error.data?.data
            ? formatValidationMessage(error.data.data)
            : 'Błąd walidacji',
          { variant: 'error' },
        );
        break;
      default:
        enqueueSnackbar(error.data?.error_message || 'Wystąpił błąd', {
          variant: 'error',
        });
        break;
    }
    return null;
  }
};

export const deleteRequest = async <T>(url: string, { arg }: { arg?: T }) => {
  try {
    const res = await axiosInstance.delete<{}, AxiosApiResponse<T | null>>(
      url,
      { data: arg },
    );

    return res.data.data;
  } catch (error) {
    switch (error.status) {
      default:
        enqueueSnackbar(error.data?.error_message || 'Wystąpił błąd', {
          variant: 'error',
        });
        break;
    }
    return null;
  }
};

// ----------------------------------------------------------------------

export const endpoints = {
  auth: {
    me: '/api/v1/user',
    login: '/api/v1/auth/login',
    register: '/api/v1/auth/activate',
    logout: '/api/v1/auth/logout',
    passwordResetEmail: '/api/v1/auth/password-reset',
    passwordReset: (token: string) => `/api/v1/auth/password-reset/${token}`,
    onboardParent: (guid: string) => `/api/v1/auth/onboard/parent/${guid}`,
    applyInstitutionInvitation: (guid: string) =>
      `/api/v1/auth/institution/${guid}`,
    impersonate: '/api/v1/impersonate',
    activateUser: (token: string) => `/api/v1/auth/activate-user/${token}`,
    verifyEmail: (token: string) => `/api/v1/auth/verify-email/${token}`,
    cancelEmailVerification: (token: string) =>
      `/api/v1/auth/token/${token}/cancel-email-verification`,
  },
  guest: {
    institution: {
      get: (guid: string) => `api/v1/guest/institution/${guid}`,
    },
    terms: {
      get: 'terms',
    },
  },
  customer: {
    getChildren: '/api/v1/child',
    getChildrenMenuAvailability: (date: string) => `/api/v1/child?date=${date}`,
    getCustomerMenuAvailability: (date: string) =>
      `/api/v1/customer?date=${date}`,
    deleteChild: (uuid: string) => `/api/v1/child/${uuid}`,
    updateChild: (uuid: string) => `/api/v1/child/${uuid}`,
    getChildMenuByMonths: (uuid: string, monthFrom: string, monthTo: string) =>
      `/api/v1/child/${uuid}/menu?from_month=${monthFrom}&to_month=${monthTo}`,
    getChildMenuByDays: (uuid: string, dayFrom: string, dayTo: string) =>
      `/api/v1/child/${uuid}/menu?from_day=${dayFrom}&to_day=${dayTo}`,
    getCustomerMenuByMonths: (monthFrom: string, monthTo: string) =>
      `/api/v1/customer/menu?from_month=${monthFrom}&to_month=${monthTo}`,
    getCustomerMenuByDays: (dayFrom: string, dayTo: string) =>
      `/api/v1/customer/menu?from_day=${dayFrom}&to_day=${dayTo}`,
    cancelMeal: (uuid: string) => `/api/v1/child/${uuid}/cancel-meals`,
    cancelCustomerMeal: () => `/api/v1/customer/cancel-meals`,
    addChild: '/api/v1/child',
    getInstitutions: '/api/v1/customer/institution',
    updateUser: '/api/v1/user',
    getMenuForChild: (childUuid: string, date: string) =>
      `/api/v1/customer/menu-by-child?child_uuid=${childUuid}&date=${date}`,
    getMenuForInstitution: (institutionUuid: string, date: string) =>
      `/api/v1/customer/menu-by-institution?institution_uuid=${institutionUuid}&date=${date}`,
    getMeal: (mealId: string) => `/api/v1/customer/meal/${mealId}`,
    getMealBundle: (mealBundleId: string) =>
      `/api/v1/customer/meal-bundle/${mealBundleId}`,
    getBalance: '/api/v1/balance',
    getRestaurant: '/api/v1/customer/restaurant',

    cart: {
      addToCart: '/api/v1/customer/cart',
      updateCartItem: (uuid: string) => `/api/v1/customer/cart-item/${uuid}`,
      getCart: (month: string) => `/api/v1/customer/cart?date=${month}`,
      removeFromCart: (uuid: string) => `/api/v1/customer/cart-item/${uuid}`,
      setPaymentMethod: '/api/v1/customer/cart/payment-method',
      placeOrder: (uuid: string) => `/api/v1/customer/cart/${uuid}`,
      balance: (month: string) =>
        `/api/v1/customer/cart/user-balance?date=${month}`,
      getPickupLocation: (cartUuid: string) =>
        `/api/v1/order-pickup-location/${cartUuid}`,
      updatePickupLocation: (cartUuid: string) =>
        `/api/v1/order-pickup-location/${cartUuid}`,
    },
    order: {
      getAll: 'api/v1/order',
      get: (uuid: string) => `api/v1/order/${uuid}`,
      pay: (uuid: string) => `api/v1/order/${uuid}/pay`,
      payAgain: (uuid: string) => `api/v1/order/${uuid}/pay/again`,
      reorder: (uuid: string) => `api/v1/order/${uuid}/reorder`,
    },
  },
  restaurant: {
    staff: {
      getAll: '/api/v1/{restaurantGuid}/staff',
      get: (uuid: string) => `/api/v1/{restaurantGuid}/staff/${uuid}`,
      add: '/api/v1/{restaurantGuid}/staff',
      update: (uuid: string) => `/api/v1/{restaurantGuid}/staff/${uuid}`,
      delete: (uuid: string) => `/api/v1/{restaurantGuid}/staff/${uuid}`,
    },
    role: {
      getAll: '/api/v1/{restaurantGuid}/role',
      get: (uuid: string) => `/api/v1/{restaurantGuid}/role/${uuid}`,
      add: '/api/v1/{restaurantGuid}/role',
      update: (uuid: string) => `/api/v1/{restaurantGuid}/role/${uuid}`,
    },
    permission: {
      getAll: '/api/v1/{restaurantGuid}/permission',
    },
    customer: {
      getAll: '/api/v1/{restaurantGuid}/customer',
      get: (uuid: string) => `/api/v1/{restaurantGuid}/customer/${uuid}`,
      add: '/api/v1/{restaurantGuid}/customer',
      update: (uuid: string) => `/api/v1/{restaurantGuid}/customer/${uuid}`,
      getBalance: (uuid: string) =>
        `/api/v1/{restaurantGuid}/customer/${uuid}/balance`,
      addBalance: (uuid: string) =>
        `/api/v1/{restaurantGuid}/customer/${uuid}/balance`,
      subtractBalance: (uuid: string) =>
        `/api/v1/{restaurantGuid}/customer/${uuid}/balance`,
      children: (uuid: string) =>
        `/api/v1/{restaurantGuid}/customer/${uuid}/child`,
    },
    customerGroup: {
      getAll: '/api/v1/{restaurantGuid}/users-group',
      get: (uuid: string) => `/api/v1/{restaurantGuid}/users-group/${uuid}`,
      add: '/api/v1/{restaurantGuid}/users-group',
      update: (uuid: string) => `/api/v1/{restaurantGuid}/users-group/${uuid}`,
      delete: (uuid: string) => `/api/v1/{restaurantGuid}/users-group/${uuid}`,
      addBalance: (uuid: string) =>
        `/api/v1/{restaurantGuid}/users-group/${uuid}/balance`,
      subtractBalance: (uuid: string) =>
        `/api/v1/{restaurantGuid}/users-group/${uuid}/balance`,
    },
    institutions: {
      getAll: '/api/v1/{restaurantGuid}/institution',
      get: (uuid: string) => `/api/v1/{restaurantGuid}/institution/${uuid}`,
      add: '/api/v1/{restaurantGuid}/institution',
      update: (uuid: string) => `/api/v1/{restaurantGuid}/institution/${uuid}`,
      getInvitation: (uuid: string) =>
        `/api/v1/{restaurantGuid}/institution/${uuid}/invitation`,
      place: {
        getAll: (institutionId: string) =>
          `/api/v1/{restaurantGuid}/institution-place?filter[institution_uuid]=${institutionId}`,
        add: '/api/v1/{restaurantGuid}/institution-place',
        update: (uuid: string) =>
          `/api/v1/{restaurantGuid}/institution-place/${uuid}`,
        delete: (uuid: string) =>
          `/api/v1/{restaurantGuid}/institution-place/${uuid}`,
      },
      workingDay: {
        getAll: (institutionId: string) =>
          `/api/v1/{restaurantGuid}/institution/${institutionId}/working-day`,
        add: (institutionId: string) =>
          `/api/v1/{restaurantGuid}/institution/${institutionId}/working-day`,
        update: (institutionId: string, uuid: string) =>
          `/api/v1/{restaurantGuid}/institution/${institutionId}/working-day/${uuid}`,
        delete: (institutionId: string) =>
          `/api/v1/{restaurantGuid}/institution/${institutionId}/working-day`,
      },
    },
    allergen: {
      getAll: '/api/v1/{restaurantGuid}/allergen',
      add: '/api/v1/{restaurantGuid}/allergen',
      update: (uuid: string) => `/api/v1/{restaurantGuid}/allergen/${uuid}`,
      delete: (uuid: string) => `/api/v1/{restaurantGuid}/allergen/${uuid}`,
    },
    ingredient: {
      getAll: '/api/v1/{restaurantGuid}/ingredient',
      add: '/api/v1/{restaurantGuid}/ingredient',
      update: (uuid: string) => `/api/v1/{restaurantGuid}/ingredient/${uuid}`,
      delete: (uuid: string) => `/api/v1/{restaurantGuid}/ingredient/${uuid}`,
    },
    mealCategory: {
      getAll: '/api/v1/{restaurantGuid}/meal-category',
      add: '/api/v1/{restaurantGuid}/meal-category',
      update: (uuid: string) =>
        `/api/v1/{restaurantGuid}/meal-category/${uuid}`,
      delete: (uuid: string) =>
        `/api/v1/{restaurantGuid}/meal-category/${uuid}`,
    },
    meal: {
      getAll: '/api/v1/{restaurantGuid}/meal',
      add: '/api/v1/{restaurantGuid}/meal',
      update: (uuid: string) => `/api/v1/{restaurantGuid}/meal/${uuid}`,
      delete: (uuid: string) => `/api/v1/{restaurantGuid}/meal/${uuid}`,
    },
    mealBundle: {
      getAll: '/api/v1/{restaurantGuid}/meal-bundle',
      add: '/api/v1/{restaurantGuid}/meal-bundle',
      update: (uuid: string) => `/api/v1/{restaurantGuid}/meal-bundle/${uuid}`,
      delete: (uuid: string) => `/api/v1/{restaurantGuid}/meal-bundle/${uuid}`,
    },
    // mixed meals and bundles
    mealables: {
      getAll: '/api/v1/{restaurantGuid}/mealables',
    },
    draftMenu: {
      getAll: '/api/v1/{restaurantGuid}/draft-menu',
      get: (uuid: string) => `/api/v1/{restaurantGuid}/draft-menu/${uuid}`,
      create: '/api/v1/{restaurantGuid}/draft-menu',
      update: (uuid: string) => `/api/v1/{restaurantGuid}/draft-menu/${uuid}`,
      apply: (uuid: string) =>
        `/api/v1/{restaurantGuid}/draft-menu/${uuid}/apply`,
    },
    menu: {
      getAll: '/api/v1/{restaurantGuid}/menu',
      get: (uuid: string) => `/api/v1/{restaurantGuid}/menu/${uuid}`,
      publish: (uuid: string) =>
        `/api/v1/{restaurantGuid}/menu/${uuid}/publish`,
      update: (uuid: string) => `/api/v1/{restaurantGuid}/menu/${uuid}`,
    },
    reports: {
      mealsToCook: `/api/v1/{restaurantGuid}/report/meals-to-cook`,
      mealsToCookGenerate: (
        dateFrom: string,
        dateTo: string,
        presentable: ReportPresentable,
        institutionUuid?: string,
      ) =>
        `/api/v1/{restaurantGuid}/report/meals-to-cook/generate?date_from=${dateFrom}&date_to=${dateTo}&presentable=${presentable}${
          institutionUuid ? `&institution_uuid=${institutionUuid}` : ''
        }`,
      ingredientsToCollect: (
        dateFrom: string,
        dateTo: string,
        presentable: ReportPresentable,
      ) =>
        `/api/v1/{restaurantGuid}/report/ingredients-to-collect?date_from=${dateFrom}&date_to=${dateTo}&presentable=${presentable}`,
      mealCollectionList: `/api/v1/{restaurantGuid}/report/meal-collection-list`,
      restaurantRevenue: (
        dateFrom: string,
        dateTo: string,
        presentable: ReportPresentable,
      ) =>
        `/api/v1/{restaurantGuid}/report/revenue?date_from=${dateFrom}&date_to=${dateTo}&presentable=${presentable}`,
      restaurantCommission: (
        dateFrom: string,
        dateTo: string,
        presentable: ReportPresentable,
      ) =>
        `/api/v1/{restaurantGuid}/report/commission?date_from=${dateFrom}&date_to=${dateTo}&presentable=${presentable}`,
      paidMeals: (
        dateFrom: string,
        dateTo: string,
        institutionUuids: string[],
        reportCollectionStrategy: ReportCollectionStrategy,
        presentable: ReportPresentable,
      ) =>
        `/api/v1/{restaurantGuid}/report/institution-end-of-month-customers-financing/generate?date_from=${dateFrom}&date_to=${dateTo}${institutionUuids
          .map((institutionUuid) => `&institution_uuids[]=${institutionUuid}`)
          .join(
            '',
          )}&report_collection_strategy=${reportCollectionStrategy}&presentable=${presentable}`,
      download: (uuid: string) =>
        `/api/v1/{restaurantGuid}/report/${uuid}/download`,
    },
    settings: {
      get: '/api/v1/{restaurantGuid}/settings',
      update: '/api/v1/{restaurantGuid}/settings',
    },
    order: {
      getAll: '/api/v1/{restaurantGuid}/order',
      get: (uuid: string) => `/api/v1/{restaurantGuid}/order/${uuid}`,
    },
    cancellation: {
      getAll: '/api/v1/{restaurantGuid}/cancellation',
      get: (uuid: string) => `/api/v1/{restaurantGuid}/cancellation/${uuid}`,
    },
    activityLog: {
      getAll: '/api/v1/{restaurantGuid}/activity-log',
      getAllBatch: '/api/v1/{restaurantGuid}/activity-log/batch',
      get: (id: string) => `/api/v1/{restaurantGuid}/activity-log/${id}`,
    },
  },
};
