import { useParentMenu } from 'src/config/parentMenu';
import DashboardLayout from '../dashboard';

type Props = {
  children: React.ReactNode;
};

export default function ParentPanelLayout({ children }: Props) {
  const parentMenu = useParentMenu();
  return <DashboardLayout menuItems={parentMenu}>{children}</DashboardLayout>;
}
