import { useContext } from 'react';
import { CartContext } from './cart-context';

export const useCartContext = () => {
  const context = useContext(CartContext);

  if (!context)
    throw new Error('useCartContext context must be use inside CartProvider');

  return context;
};
