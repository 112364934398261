import React from 'react';
import Router from 'src/routes/router';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import defaultLocale from 'date-fns/locale/pl';
import { SWRConfig } from 'swr';
import setDefaultOptions from 'date-fns/setDefaultOptions';
import Hotjar from '@hotjar/browser';
import { Helmet } from 'react-helmet-async';
import TagManager from 'react-gtm-module';
import * as Sentry from '@sentry/react';
import ThemeProvider from './theme';
import MotionLazy from './assets/animate/motion-lazy';
import { AuthProvider } from './providers/auth';
import { SnackbarProvider } from './components/helpers/snackbar';
import { fetcher } from './utils/axios';
import { APP_ENV, ENV_TYPE, HJ_SITE_ID } from './config-global';
import { denyUrls, ignoreErrors } from './config/sentry';

setDefaultOptions({ locale: defaultLocale });

if (HJ_SITE_ID) Hotjar.init(HJ_SITE_ID, 6);

const tagManagerArgs = { gtmId: 'GTM-W9X8XTTQ' };
if (APP_ENV === 'production') TagManager.initialize(tagManagerArgs);

if ((['staging', 'production'] as ENV_TYPE[]).includes(APP_ENV)) {
  Sentry.init({
    dsn: 'https://d9a234d23072948b509dfab535d1bcde@o4505787813003264.ingest.sentry.io/4506704690282496',
    environment: APP_ENV,
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          'localhost',
          /^https:\/\/yourserver\.io\/api/,
        ],
      }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    ignoreErrors,
    denyUrls,
  });
}

function App() {
  return (
    <AuthProvider>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={defaultLocale}
      >
        <MotionLazy>
          <SnackbarProvider>
            <ThemeProvider>
              <SWRConfig
                value={{
                  provider: () => new Map(),
                  revalidateOnFocus: false,
                  fetcher,
                }}
              >
                <Helmet htmlAttributes={{ translate: 'no' }}>
                  {APP_ENV === 'production' && (
                    <script
                      id="Cookiebot"
                      src="https://consent.cookiebot.com/uc.js"
                      data-cbid="54c299c6-0aac-438a-a1a8-d6fc4f2e8b4f"
                      data-blockingmode="auto"
                      type="text/javascript"
                    />
                  )}
                </Helmet>
                <Router />
              </SWRConfig>
            </ThemeProvider>
          </SnackbarProvider>
        </MotionLazy>
      </LocalizationProvider>
    </AuthProvider>
  );
}

export default App;
